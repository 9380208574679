@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.dark.less';
@import '~react-vis/dist/style';

// ------------------ COLOR VARIABLES ------------------

@primary-color: #d96200;
@primary-gradient-color: #ff8f33;
@secondary-color: #2c2c2c;
@layout-header-background: #1e1e26;
@menu-dark-bg: @layout-header-background;
@body-background: #1e1e26;
@white: #ffffff;
@white-contrast: #a5a5a5;
@white-contrast-2: #787878;
@modal-background: #242424;
@green: #00cc66;
@red: #f84960;

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// -------- GLOBAL -------------
.hidden {
	display: none !important;
}

.mr-1 {
	margin-right: 1rem;
}

.klever-flex {
	display: flex;
}

.collor-green {
	color: @green;
}

.divider {
	margin: 4px 0px;
	border-bottom: 1px solid rgba(66, 70, 101, 0.25);
}

.margin-text-no-content-logo {
	display: flex;
	justify-content: center;
	position: relative;
	bottom: 72px;
}

.ant-table-cell-scrollbar {
	box-shadow: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
	transition: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
	transition: none;
}

.ant-tabs-ink-bar {
	width: 0 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
	margin-bottom: -2px;
}

// ------------------ GENERAL ------------------

html {
	// scrollbar-width: thin;
	overflow-y: scroll;
	scroll-behavior: smooth !important;
}

body {
	font-family: 'Noto Sans', sans-serif;
	font-display: swap;
	color: @white;
	width: 100% !important;
	// overflow-y: scroll;
}

h1 {
	color: #666666;
}

::-moz-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: @white-contrast;
}

::-webkit-scrollbar-thumb:hover {
	background: @white-contrast-2;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab .ant-tabs-tab-btn,
.ant-tabs-tab {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		overflow: visible;
		white-space: normal;
		height: auto;
	}
}

.klever-site-layout h3 {
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 24px;
	color: @white;
}

.ant-input-password-icon,
.ant-input-password-icon:hover {
	color: @primary-color;
}

.ant-form-item-explain {
	font-size: 10px;
}

// ------------------ HEADER ------------------

.klever-logo {
	width: 130px;
	margin-right: 30px;
	margin-top: 1px;
}

.klever-header-container {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	padding: 0;
}

.klever-header-main-menu {
	background: @body-background;
}

.header-relative {
	position: relative;
}

.klever-communication {
	padding: 6px 15px;
	border: none !important;

	.ant-alert-message {
		text-align: center;
		color: inherit;
	}
}

.klever-network-status {
	background-color: @red !important;
	border: none !important;

	.ant-alert-message {
		text-align: center;
		color: inherit;
	}
}

.klever-header {
	display: flex;
	padding: 0 10px 0 30px;
	background: @body-background;
}

.klever-header {
	width: 100%;
	max-width: 1440px;
	display: flex;
	height: 64px;
	line-height: 64px;
	margin: 0 auto;
	padding: 0 10px 0 20px;
}

.klever-header-full {
	width: 100%;
	display: flex;
	height: 64px;
	line-height: 64px;
	margin: 0 auto;
	padding: 0 10px;
}

.klever-header-trade-full {
	height: 44px;
	line-height: 44px;
	background-color: @secondary-color !important;
	border-bottom: 1px solid @body-background;

	.klever-logo {
		width: 110px;
	}
}

.klever-header-trade-full-light {
	height: 48px;
	line-height: 48px;
	background-color: @white !important;
	border-bottom: 1px solid #fafafa;
}

.klever-header-main-menu-fees {
	background: @body-background !important;
	height: 64px;

	.klever-logo {
		margin-top: 14px;
	}
}

.klever-header-menu {
	width: 100%;
	align-items: center;

	a {
		&:hover {
			color: @primary-color;
		}
	}

	@media screen and (max-width: 1391px) {
		flex: 1;
		justify-content: flex-end;
	}
}

.klever-header-menu a {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	transition: none;
	color: @white;
}

.klever-header-menu li.ant-menu-item {
	background: transparent !important;
}

.klever-header-menu li.ant-menu-item:hover {
	background-color: transparent !important;
}

.klever-header-menu li:hover a {
	color: @primary-color !important;
}

.klever-header-menu li:hover path {
	fill: @primary-color !important;
}

.klever-header-menu .ant-menu.ant-menu-dark .ant-menu-item-selected {
	color: @primary-color !important;
}

.klever-new-badge,
.klever-new-badge-mobile,
.klever-hot-badge,
.klever-hot-badge-mobile {
	background: linear-gradient(135deg, #d96200 1.18%, #ff8f33 75.21%);
	margin-left: 8px;
	padding: 3px 5px;
	color: @body-background;
	font-size: 8px;
	border-radius: 5px;
	text-transform: uppercase;
	vertical-align: text-bottom;
	position: relative;
}

.klever-new-badge:before,
.klever-new-badge-mobile:before,
.klever-hot-badge:before,
.klever-hot-badge-mobile:before {
	content: '';
	position: absolute;
	top: 4px;
	left: -6px;
	width: 0;
	height: 0;
	border-top: 4px solid transparent;
	border-right: 8px solid @primary-color;
	border-bottom: 4px solid transparent;
}

.klever-hot-badge,
.klever-hot-badge-mobile {
	background: linear-gradient(90deg, rgba(149, 29, 29, 1) 6%, rgba(255, 46, 0, 1) 75%);
}

.klever-hot-badge:before,
.klever-hot-badge-mobile:before {
	border-right: 8px solid #951d1d;
}

.klever-header-menu-divider {
	padding: 0 25px;

	.ant-divider-horizontal {
		margin: 0;
	}
}

.klever-login-btn-wrapper {
	width: 100%;

	.ant-btn-link {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: @white;
		width: 100%;
		margin-top: 10px;
	}
}

.klever-login-btn-component {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	background: transparent;
	margin-right: 12px;
	margin-top: 12px;
}

.klever-login-btn {
	border-radius: 4px;
	border: 1px solid @white-contrast;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: @white;

	&:hover {
		border: 1px solid @white-contrast;
	}
}

.klever-signup-btn {
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 22px;
	border-radius: 8px;
	width: 95px;
}

.klever-language-btn {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 16px;
	display: flex;
	align-items: center;
}

.klever-language-btn svg {
	margin: 0 5px;
}

.klever-divider-vertical {
	margin: 0 15px 0 12px;
	height: 22px;
	background: @white-contrast;
}

.klever-divider-horizontal {
	background: @white-contrast;
	margin: 0;
}

//  ------------------ CONTENT ------------------

#chartdiv {
	canvas:first-child {
		left: -20px !important;
	}
}

.klever-gradient-text {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	background: -webkit-linear-gradient(#d96200, #ff8f33);
	background-clip: border-box;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.klever .ant-tooltip-inner,
.klever .ant-tooltip-arrow-content {
	background-color: @modal-background;
}

.klever .ant-layout-footer {
	background: @secondary-color;
}

.klever-site-layout {
	padding: 0 5vw;
	margin-top: 64px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
}

.klever-home-layout {
	padding: 0;
	margin-top: 64px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	overflow-x: hidden;
}

.klever-asset-layout {
	padding-top: 50px;
	margin-top: 64px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	overflow-x: hidden;
	min-height: 93vh;
}

.klever-collection-layout {
	padding: 0 30px 30px 30px;
	margin-top: 8vh !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	overflow-x: hidden;
}

.klever-symbol-layout {
	padding: 0 48px 30px 48px;
	margin-top: 60px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	overflow-x: hidden;

	@media screen and (max-width: 1023px) {
		margin-top: 1px !important;
	}

	@media screen and (max-width: 450px) {
		padding: 0 20px 20px 20px;
	}
}

.klever-profile-layout {
	padding: 30px;
	margin-top: 64px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	overflow-x: hidden;
}

.klever-auth-layout {
	height: calc(100vh);
}

.klever-holdings-layout {
	padding: 0 40px;
	margin-top: 60px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
}

.klever-holdings-mobile-layout {
	padding: 0 40px;
	margin-top: 10px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;
}

.klever-holdings-layout-2 {
	padding: 0;
	max-width: 1440px;
	margin: auto;
}

.klever-my-orders-layout-2 {
	max-width: 1440px;
	margin: auto;
}

.site-my-orders-header {
	padding: 10px 0px 10px 0px;
}

.trading-fees-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;

	color: @white;
}

.trading-fees-header {
	padding: 16px 0;
}

.enjoy-more-benefits {
	padding-left: 81px;

	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;

	color: @white-contrast;
}

.trading-fees-box {
	margin-left: 56px;
	margin-right: 80px;
	background: @secondary-color;
	border-radius: 8px;

	ul {
		margin-top: 16px;
		margin-left: 16px;
		margin-bottom: 16px;
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;

		color: @white-contrast;
	}
}

.trading-fees-spot {
	margin-left: 81px;
	margin-top: 48px;
	margin-right: 100px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;

	color: @white;
}

.trading-fees-25 {
	margin-left: 76px;
	margin-top: 11px;
	margin-right: 119px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	color: @white;
}

.trading-futures {
	margin-left: 81px;
	margin-top: 48px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;

	color: @white;
}

.trading-fee-rate {
	padding-top: 28px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: @white;
}

.trading-fees-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 8px;
	font-weight: 300;
	background: @secondary-color;
	transform: rotate(0deg);
	flex: none;
	order: 0;
	flex-grow: 1;
	margin: 0px 0px;
}

.klever-account-layout {
	padding: 0 3vw;
	margin-top: 90px !important;
	margin-bottom: 40px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;

	@media screen and (max-width: 1024px) {
		margin-top: 30px !important;
	}
}

.klever-deposits-layout {
	padding: 0 2.5vw;
	margin-top: 64px !important;
	margin-bottom: 40px !important;
	width: 100%;
	max-width: 1440px;
	margin: auto;

	.ant-page-header-heading-title,
	h3 {
		font-weight: normal;
		font-size: 24px;
		line-height: 28px;
	}

	.ant-page-header-footer {
		margin-top: 0;
		margin-left: 32px;
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 22px;
		color: @white-contrast;
	}
}

.klever-holdings-layout-page {
	margin-top: 64px !important;
}

.klever-carousel img {
	max-height: 420px;
	width: 100%;
	border-radius: 10px;
}

.klever-section {
	padding-top: 40px;
	padding-bottom: 20px;
}

.klever-trending-card {
	background: @secondary-color;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	border: none;
	margin-bottom: 20px;
	width: 100%;
	margin-right: 10px;
}

.klever-trending-card .ant-card-head {
	border-bottom: none;
}

.klever-trending-card .ant-card-head-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: @white;
	padding: 10px 0;
}

.klever-trending-card .ant-card-head-title span {
	font-weight: 400;
	opacity: 0.8;
}

.klever-trending-card .ant-card-extra {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: right;
}

.klever-positive {
	color: @green !important;
}

.klever-negative {
	color: @red !important;
}

.klever-trending-card .ant-card-body {
	padding: 0 24px 0px 24px;
}

.klever-trending-card .klever-pair-price {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: @white-contrast;
	padding-bottom: 0;
}

.klever-trending-card .klever-pair-1-value {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 2px;
	color: @white;
}

.klever-trending-card .klever-pair-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #f5f5f5;

	b {
		font-weight: 700;
		color: @white;
	}
}

.klever-trending-card .klever-pair-2-value {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	color: #f5f5f5;
}

.klever-trending-card .klever-pair-volume {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-trending-card .klever-card-chart {
	height: 70px !important;
}

.klever-tabs .ant-tabs-tab {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 5px;
}

.klever-tabs > .ant-tabs-nav::before {
	border-bottom: 1px solid #424665;
}

.klever-table-search {
	border-color: #858585;
	border-radius: 8px;
	margin-bottom: 10px;
	width: 300px;
}

.pairs-table-search-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.pairs-table-search {
	width: 100%;
}

.klever-table-search-my-orders {
	border-color: #858585;
	border-radius: 8px;
	margin-bottom: 5px;
	width: 300px;
	padding: 6px;
}

.klever-table-search input {
	height: 30px;
	line-height: 30px;
}

.klever-table-search path {
	fill: #858585;
}

.klever-table-search input::placeholder {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 30px;
	color: @white-contrast;
}

.klever-markets-table .ant-table-thead > tr > th,
.klever-markets-table .ant-table-thead > tr > th:hover {
	background: @body-background;
}

.klever-markets-table .ant-table table {
	border-spacing: 0 8px;
}

.klever-markets-table .ant-table {
	background: transparent;
}

.klever-markets-table tbody:before {
	content: '-';
	display: block;
	line-height: 1px;
	color: transparent;
}

.klever-markets-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-markets-table .ant-table-container table > thead > tr > th {
	background: @body-background !important;
	padding: 0 16px 0 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: #f5f5f5;
}

.klever-markets-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-markets-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-markets-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-markets-table .ant-table-column-title {
	flex: none;
}

.klever-markets-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-markets-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-markets-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-markets-table tr {
	border-radius: 8px;
}

.klever-markets-table td {
	background: @body-background !important;
}

.klever-markets-table .ant-table-container table > tbody > tr > td small {
	margin-top: -5px;
	color: @white-contrast;
	white-space: nowrap;
}

.klever-markets-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-markets-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-markets-table .ant-table-tbody > tr > td {
	border-bottom: none;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: @white;
	background: @secondary-color !important;
	padding: 8px 16px 8px 8px;
}

.klever-markets-table .ant-table-tbody > tr > td b {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: @white;
	white-space: nowrap;

	> span {
		color: @white-contrast;
		font-weight: normal;
	}
}

.klever-markets-table .ant-table-expanded-row > td {
	top: -16px;
	background: @body-background !important;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	padding-top: 20px;
}

.klever-markets-table .ant-table-expanded-row > td > div {
	background: @body-background;
	border-radius: 8px;
	padding: 10px 16px;
}

.klever-markets-table--audit .ant-table-container table > thead > tr > th {
	background: @secondary-color !important;
}

.klever-markets-table--audit .ant-table-tbody > tr > td {
	background: @body-background !important;
	padding: 8px 16px 8px 10px;
}

.klever-trade-btn {
	border: none !important;
	background: @primary-color;
	border-radius: 4px;
	height: 27px;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: @white;
}

.klever-markets-table .ant-table-row-expand-icon-cell button {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-right: 5px;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: @white;
	cursor: pointer;
}

.klever-markets-table .ant-table-row-expand-icon-cell button > span + .anticon {
	margin-left: 0;
}

.klever-coin-icon {
	margin-left: 10px;
	margin-right: 10px;
}

.klever-coin-details h4 {
	margin-bottom: 0;
	margin-left: 15px;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 38px;
	color: #7b7db2;
}

.klever-coin-details .klever-coin-description {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: @white-contrast;
	margin-top: 15px;
	margin-bottom: 20px;
	padding-right: 30px;
}

.klever-btn-group {
	gap: 15px;
	padding: 15px 0;
	height: 70px;
}

.klever-secondary-btn {
	background: @white-contrast;
	border-radius: 4px;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: @white;
	border: none;
}

.klever-tertiary-btn {
	background: @secondary-color;
	border-radius: 8px;
	border: none;
	height: 40px;

	span {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		color: @white;
	}
}

.klever-tertiary-btn:hover {
	background: @secondary-color;
}

.klever-action-btn {
	background: @primary-color;
	border-radius: 8px;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: @white;
}

.klever-default-btn {
	border: 1px solid @white-contrast;
	border-radius: 8px;
	padding: 8px 16px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-transparent-btn {
	background-color: transparent !important;
	color: @white-contrast !important;
}

.klever-transparent-btn:hover {
	background-color: @secondary-color !important;
}

.klever-deposits-my-nfts {
	max-width: 1440px;

	.klever-no-nfts {
		margin-top: 130px;
	}
}

.klever-markets-table .ant-table .klever-coin-details table {
	border-spacing: 0;
}

.klever-markets-table .ant-table .klever-coin-details td:last-child {
	text-align: right;
}

.klever-coin-details td {
	background: transparent !important;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
}

.klever-coin-details tr:nth-child(odd) {
	background-color: @body-background;
}

.klever-market-col {
	background: @body-background;
	border-radius: 8px;
	padding: 16px 8px;
	overflow: hidden;
}

.klever-full-search {
	width: 100%;
	margin-bottom: 0;
}

.klever-market-crypto-column {
	margin-left: 4px;
}

.klever-market-crypto-column b {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: @white;
	display: block;
	margin-top: 5px;
	cursor: pointer;
}

.klever-market-crypto-column > div > span,
.klever-market-24h-column span {
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 10px;
	color: @white;
	margin-top: -16px;
}

.klever-markets-trade-table .ant-table table {
	border-spacing: 0;
}

.klever-markets-trade-table .ant-table table td {
	color: @white-contrast;
}

.klever-markets-trade-table .ant-table-tbody > tr > td {
	padding: 2px 5px !important;
	border-bottom: 0 !important;
	background: transparent !important;
}

.klever-markets-trade-table thead > tr > th {
	border-bottom: 0 !important;
	padding-bottom: 0 !important;
}

.klever-markets-trade-table .ant-table-tbody > tr:first-child {
	padding-left: 10px;
}

.klever-markets-trade-table {
	.klever-order-cancel {
		background: @secondary-color !important;
	}
}

.klever-market-price-column,
.klever-market-24h-column b {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	margin-top: 5px;
	margin-bottom: 2px;
	white-space: nowrap;
}

.klever-market-24h-column {
	display: inline-grid;
}

.klever-market-col-hide {
	margin: 0 5px;
}

.klever-market-col-show {
	position: absolute;
	top: 50px;
	left: 0;
	background: @body-background;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-orderbook-container {
	padding: 10px;
	margin-bottom: 10px;
	background-color: @secondary-color;
	border-radius: 8px;
	overflow: hidden;
	height: 100%;
	min-height: 415px;
	padding-bottom: 30px;
}

.klever-tradelist-container {
	height: calc(100vh - 372px);
	overflow-y: auto;
	min-height: 425px;
	background-color: @secondary-color;
	padding: 10px;
	// scrollbar-width: thin;
}

.klever-tradelist-container table,
.klever-orderbook-container table {
	width: 100%;
}

.klever-tradelist-table-row div {
	margin-right: 10px;
}

.klever-tradelist-table-row div:last-child {
	margin-right: 0;
	margin-left: 10px;
}

.klever-tradelist-table-row div span,
.klever-orderbook-table-head th {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	color: @white-contrast;
	white-space: nowrap;
}

.klever-orderbook-table-content td {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	padding: 2px 0px;
	cursor: pointer;
	color: #b7bdc6;
}

.klever-left {
	text-align: left;
}

.klever-center {
	text-align: center;
}

.klever-right {
	text-align: right;
}

.klever-tradelist-details div {
	margin-right: 10px;
}

.klever-tradelist-details div span {
	font-size: 12px;
}

.klever-tradelist-details div:nth-child(2) {
	margin-right: 20px;
	margin-left: 50px;
}

.klever-tradelist-details div:last-child {
	margin-right: 0px;
}

.klever-tradelist-details-buy {
	color: @green !important;
	width: 35%;
}

.klever-tradelist-details-sell {
	color: @red !important;
	width: 35%;
}

.klever-user-order-indicator {
	transform: rotate(180deg);
	margin: -2px 2px 0 0;
}

.klever-user-order-no-indicator {
	margin: -2px 7px 0 0;
}

.klever-placeorder-ordertype-radio-button {
	background: transparent !important;
}

.klever-placeorder-container .ant-radio-button-wrapper:not(:first-child)::before {
	background-color: transparent;
}

.klever-percentage-radio .ant-col {
	padding: 0 2px;
}

.klever-percentage-radio button {
	border-radius: 8px !important;
	background: @secondary-color;
	color: @white-contrast;
	border: none !important;
	margin-bottom: 5px;
	padding: 4px 10px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.klever-percentage-radio .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
	background: @white-contrast;

	span {
		color: @white;
	}
}

.klever-percentage-radio button span {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: @white-contrast;
}

.klever-placeorder-ordertype-radio {
	margin-bottom: 8px;
	background-color: #26274c;
	border-radius: 8px;
	width: 100%;
}

.klever-placeorder-ordertype-radio-button {
	border: none !important;
	width: 50%;
	text-align: center;
}

.klever-placeorder-ordertype-radio-button span {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 9px;
	line-height: 15px;
	color: @white;
}

.klever-placeorder-ordertype-radio-button:hover {
	color: #ffffffaf;
}

.klever-placeorder-ordertype-radio-button .ant-radio-button {
	border-color: #7d3ff1;
	background-color: #7d3ff1;
	border-radius: 10px;
}

.klever-placeorder-buy-button {
	border-color: @green;
	background-color: @green;
	border-radius: 8px;
	margin-top: 5px;
	height: 37px;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
}

.klever-placeorder-buy-button:hover {
	background-color: #00cc66a1;
	border: none;
	color: #ffffffaf;
}

.klever-placeorder-buy-button:disabled {
	border-color: #00cc66a1;
	background-color: #00cc66a1;
	border-radius: 8px;
	margin-top: 5px;
	height: 37px;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
}

.klever-placeorder-buy-button:disabled:hover {
	border: none;
	background-color: #00cc66a1;
	cursor: default;
}

.klever-placeorder-sell-button {
	border-color: @red;
	background-color: @red;
	border-radius: 8px;
	margin-top: 5px;
	height: 37px;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
}

.klever-placeorder-sell-button:hover {
	background-color: #f84960a1;
	border: none;
	color: #ffffffaf;
}

.klever-placeorder-sell-button:disabled {
	border-color: #f84960a1;
	background-color: #f84960a1;
	border-radius: 8px;
	margin-top: 5px;
	height: 37px;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
}

.klever-placeorder-sell-button:disabled:hover {
	border: none;
	background-color: #f84960a1;
	cursor: default;
}

.klever-placeorder-total {
	display: flex;
	padding: 7px 15px 7px 5px;
}

.klever-placeorder-currency {
	display: flex;
	width: 100%;
}

.klever-placeorder-total label {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.02em;
	color: @white-contrast;
}

.klever-placeorder-total small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: #b7bdc6;
}

.menssage-error {
	height: 20px;
}

.klever-placeorder-total span {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
	color: #b7bdc6;
}

.klever-placeorder-container {
	padding: 0 10px 10px 10px;
}

.klever-placeorder-container .ant-input-affix-wrapper {
	margin-bottom: 8px;
	background-color: @secondary-color;
	padding: 7px 15px;
	border-radius: 8px;
	border: none;
	height: 34px;
}

.klever-placeorder-container .ant-input-affix-wrapper:valid {
	border: 1px solid @white;
}

.klever-placeorder-container .ant-input-affix-wrapper-focused {
	border: 1px solid @primary-color;
	border-color: @primary-color !important;
	box-shadow: none !important;
}

.klever-placeorder-container .ant-input-affix-wrapper .ant-input-suffix {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
	color: @white-contrast;
}

.klever-placeorder-container input {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}

.klever-placeorder-container input::placeholder {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: @white-contrast !important;
}

.klever-placeorder-container input::-webkit-outer-spin-button,
.klever-placeorder-container input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.klever-order-tabs > .ant-tabs-nav::before {
	border: none !important;
}

.klever-order-tabs > .ant-tabs-nav {
	padding-left: 5px;
}

.klever-order-tabs .ant-tabs-tab {
	background: @body-background !important;
	border-top-left-radius: 2px !important;
	border-top-right-radius: 2px !important;
	border: none !important;
}

.klever-my-orders-tabs-bottom .ant-tabs-nav::before {
	border-bottom: solid 1px @secondary-color !important;
}

.klever-pairs-tabs .ant-tabs-tab {
	background: @body-background !important;
	border-radius: 4px 4px 0px 0px !important;
	border: none !important;
	height: 49px;

	.ant-tabs-tab-remove svg {
		fill: @white-contrast;
		width: 10px;
	}
}

.klever-pairs-table-keypair {
	color: rgba(255, 255, 255, 0.85);
	white-space: nowrap;
}

.klever-pair-tab {
	display: grid;
	color: @white;
}

.klever-pair-tab-my-orders {
	display: grid;
	color: @white;
	padding: 8px 16px;
}

.klever-tab-my-orders-pane {
	padding-left: 1rem;
}

.klever-td-keypair-favorite {
	width: 200px;
}

.klever-header-menu .klever-pair-tab {
	display: grid;
	color: @white;
	background-color: @body-background;
	padding: 2px 10px 5px 10px;
	border-radius: 4px;
}

.klever-pair-tab-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 22px;
}

.klever-pair-tab-title-my-orders {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: @white;
	flex: none;
	order: 0;
}

.klever-pair-tab-subtitle {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	text-align: left;
}

.klever-trading-tabs-container {
	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
		border-bottom: solid 2px @primary-color !important;
		padding: 4px 10px;
		height: 50px;
	}

	.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
		background: #fff !important;
	}

	.ant-tabs-tab-active .klever-pair-tab-title {
		color: @white;
	}

	.ant-tabs-tab-active .ant-tabs-tab-remove {
		color: rgba(0, 0, 0, 0.45);
	}
}

.klever-pairs-tabs > .ant-tabs-nav::before,
.klever-my-orders-tabs > .ant-tabs-nav::before {
	border-bottom: 1px solid @secondary-color;
}

.klever-pairs-tabs > .ant-tabs-nav {
	background: @body-background;
	margin-bottom: 0;
	border-radius: 0;
	height: 50px;
	padding-left: 6px;
}

.klever-orderbook-select {
	margin-bottom: 8px;
	width: 110px;
}

.klever-orderbook-select .ant-select-selector {
	border-radius: 8px !important;
	border: 1px solid #565666 !important;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	height: 24px !important;
	color: @white;
	text-align: center;
}

.ant-select-item-option-content {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	color: @white;
	display: flex;
	justify-content: space-between;
	margin: 0px 5px;
}

.klever-orderbook-select .ant-select-selector span {
	line-height: 24px !important;
}

.klever-orderbook-container h5,
.klever-tradelist-container h5 {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #b7bdc6;
	margin-bottom: 0;
}

.klever-orderbook-container h5 {
	margin-bottom: 10px;
}

.klever-orderbook-space {
	width: 100%;
	background: @secondary-color;
	border-radius: 4px;
	text-align: center;
	padding: 8px 0;
	display: flex;
	align-items: center;
	padding-left: 10px;
	min-height: 34px;

	.ant-row-space-between {
		width: 90%;
	}
}

.klever-orderbook-space-up path {
	fill: @green;
	margin-right: 5px;
}

.klever-orderbook-space-up .klever-orderbook-value {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	color: @green;
	margin-left: 5px;
	cursor: pointer;
	margin-top: 2px;
}

.klever-orderbook-space-down path {
	fill: @red;
	margin-right: 5px;
}

.klever-orderbook-space-down .klever-orderbook-value {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	color: @red;
	margin-left: 5px;
	cursor: pointer;
	margin-top: 2px;
}

.klever-orderbook-space-equal .klever-orderbook-value {
	font-family: 'Rubik', sans-serif !important;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	color: @white;
	margin-top: 2px;
	margin-left: 5px;
	cursor: pointer;
}

.klever-orderbook-space-equal path {
	fill: @white;
	margin-right: 5px;
}

.klever-orderbook-space-up .klever-orderbook-price,
.klever-orderbook-space-down .klever-orderbook-price,
.klever-orderbook-space-equal .klever-orderbook-price {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	margin-left: 10px;
	margin-top: 2px;
}

.klever-orderbook-spacer {
	height: 5px;
}

.klever-orderbook-circle {
	width: 16px;
	height: 16px;
	border-radius: 16px;
	margin-bottom: 5px;
}

.klever-orderbook-circle-left {
	width: 8px;
	height: 16px;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-bottom: 5px;
}

.klever-orderbook-circle-right {
	width: 8px;
	height: 16px;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	margin-bottom: 5px;
}

.klever-circle-green {
	background: @green;
}

.klever-circle-red {
	background: @red;
}

.klever-circle-red-draggable {
	margin-right: 15px;
}

.klever-orderbook-circle-container {
	margin-bottom: 5px;
	padding: 4px;
	background: transparent !important;
}

.klever-orderbook-circle-both {
	display: flex;
}

.klever-orderbook-row-bg {
	height: 17px;
	position: absolute;
	right: 0;
	margin: 1px 4px 1px 4px;
	border-radius: 4px;
	opacity: 0.1;
}

.klever-trading-infos {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	line-height: 14px;
	margin-left: 20px;

	.klever-space-right {
		margin-right: 15px;
	}

	.ant-row-middle {
		span {
			color: @white-contrast;
			font-family: Rubik;
			font-size: 12px;
			font-weight: 500;
		}
	}

	.klever-trading-info {
		display: flex;
		flex-direction: column;
		margin-right: 20px;

		span {
			font-family: 'Rubik';
		}

		span:first-child {
			font-weight: 500;
			color: @white-contrast;
			font-size: 10px;
		}

		span:last-child {
			color: @white;
			font-size: 12px;
		}
	}
}

.klever-trading-infos-light {
	.klever-trading-info {
		span:first-child {
			color: @secondary-color;
		}

		span:last-child {
			color: @body-background;
		}
	}
}

.klever-trading-table,
.klever-deposits-table {
	width: 100%;
	margin-bottom: 15px;
}

.klever-trading-table-head th {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: @white-contrast;
}

.klever-trading-table-content td {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
}

.klever-orders-tabs {
	background-color: @body-background;
	padding: 0 5px 10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
	position: relative;
	z-index: 1;
}

.klever-orders-tabs ant-tabs-tab-btn {
	background: @white;
}

.klever-orders-tabs .cancel-all {
	color: @primary-color;
	cursor: pointer;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	white-space: nowrap;
}

.klever-order-cancel {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: @white-contrast;
}

.klever-trading-buy {
	color: @green;
}

.klever-trading-sell {
	color: @red;
}

.klever-trading-currency {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	margin-left: 20px;

	span {
		color: @white;
		font-size: 14px;
		font-weight: 700;
	}

	span:nth-child(2) {
		margin-left: 10px;
	}

	span:nth-child(3) {
		margin-right: 5px;
	}

	button {
		padding: 0px 6px 0px 8px;
		height: 42px;
	}

	.klever-space-right {
		margin: 2px 20px 0px 20px;

		.ant-col {
			span {
				font-family: 'Rubik';
				color: @white-contrast;
				font-size: 12px;
			}
		}
	}

	@media screen and (max-width: 1440px) {
		line-height: 11px;
	}
}

.klever-trading-currency-light {
	span {
		color: @secondary-color;
	}

	button {
		background-color: #f0f0f0;
	}

	.klever-space-right {
		.ant-col {
			span {
				color: @secondary-color;
			}
		}
	}
}

.klever-trading-value {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: @green;
	margin: 5px 0;
}

.klever-trading-value-mobile {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: @white;
	margin: 5px 0;
}

.klever-trading-currency small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 16px;
	margin-left: 5px;
}

.klever-trading-currency h5 {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #7b7db2;
	margin-bottom: 0;
	margin-right: 5px;
}

.klever-tradingview-container {
	height: 100%;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}

.klever-order-date {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;
	color: @white-contrast;
	padding-left: 10px;
	white-space: nowrap;
}

.klever-orders-tabs-container {
	padding: 0px;
	margin: 0;

	.ant-tabs-card .ant-tabs-tab {
		background: @secondary-color;
		border-color: transparent !important;
		border-top-left-radius: 5px !important;
		border-top-right-radius: 5px !important;
	}

	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
		border: none !important;
		border-radius: 4px 4px 0 0;
	}

	.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
		background: @modal-background !important;
	}

	.ant-tabs-tab .klever-pair-tab-title {
		font-family: 'Noto Sans';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		color: @white-contrast;
	}

	.ant-tabs-tab-active .klever-pair-tab-title {
		color: @white;
		font-weight: normal;
	}

	.ant-tabs-tab-active .ant-tabs-tab-remove {
		color: rgba(0, 0, 0, 0.45);
	}

	.ant-tabs-card .ant-tabs-content {
		margin-top: -16px;
	}

	.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
		padding: 6px 16px;
	}

	.ant-table-container table > thead > tr > th {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 22px;
		color: @white;
		padding: 4px 8px !important;
	}
}

.klever-holdings-head-row h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 16px;
	color: @white;
	margin-right: 40px;
	margin-bottom: 0;
}

.klever-holdings-head-row > div {
	display: ruby;
}

.klever-holdings-head-row path {
	fill: @white-contrast;
}

.klever-holdings-head-row .ant-btn-text {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: @white-contrast;
}

.klever-primary-btn {
	height: 39px;
	background: @primary-color;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white;
	padding: 0 25px;
}

.klever-primary-btn-validate-modal,
.klever-primary-btn-security {
	height: 39px;
	background: @primary-color;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white;
	padding: 0px 25px;
	width: 100%;
}

.klever-primary-btn-validate-modal {
	margin-top: 10px;
}

.klever-primary-btn-validate-modal.ant-btn-primary[disabled],
.klever-primary-btn-validate-modal.ant-btn-primary[disabled]:hover,
.klever-primary-btn-validate-modal.ant-btn-primary[disabled]:focus,
.klever-primary-btn-validate-modal.ant-btn-primary[disabled]:active,
.klever-primary-btn-security.ant-btn-primary[disabled],
.klever-primary-btn-security.ant-btn-primary[disabled]:hover,
.klever-primary-btn-security.ant-btn-primary[disabled]:focus,
.klever-primary-btn-security.ant-btn-primary[disabled]:active,
.klever-primary-btn-security-create-next.ant-btn-primary[disabled],
.klever-primary-btn-security-create-next.ant-btn-primary[disabled]:hover,
.klever-primary-btn-security-create-next.ant-btn-primary[disabled]:focus,
.klever-primary-btn-security-create-next.ant-btn-primary[disabled]:active,
.klever-primary-btn-security-create-step-four.ant-btn-primary[disabled],
.klever-primary-btn-security-create-step-four.ant-btn-primary[disabled]:hover,
.klever-primary-btn-security-create-step-four.ant-btn-primary[disabled]:focus,
.klever-primary-btn-security-create-step-four.ant-btn-primary[disabled]:active {
	color: @white;
	background: @primary-color;
	opacity: 0.7;
}

.klever-withdraw-enable2fa-btn {
	height: 100%;
	width: 100%;
	background: @white-contrast;
	border-color: @white-contrast;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	color: @white;
	padding: 10px 25px;
	margin-top: 8px;
	white-space: break-spaces;
}

.klever-primary-btn-security-create {
	height: 45px;
	background: @primary-color;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white;
	padding: 0px 25px;
	margin-bottom: 10px;
	width: 90%;
}

.klever-primary-btn-security-create-step-four {
	height: 45px;
	background: @primary-color;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white;
	padding: 0px 25px;
	margin-bottom: 10px;
	width: 100%;
}

.klever-primary-btn-security-create-previous {
	height: 45px;
	background: @white;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white-contrast;
	border: none;
	margin-right: 10px;
	width: 25%;
}

.klever-primary-btn-security-create-next {
	height: 45px;
	background: @primary-color;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: @white;
	padding: 0px 25px;
	width: 72%;
}

.klever-primary-btn-security-create-copyboard {
	height: 45px;
	background: transparent;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	border-color: @white-contrast;
	line-height: 20px;
	color: @white-contrast;
	padding: 0px 25px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
}

.klever-primary-btn-security-create-copyboard:hover {
	background-color: #f5f5f5;
	color: @white-contrast;
}

.klever-security-activated-modal {
	padding: 18px 0px;
}

.klever-security-activated-row {
	height: auto !important;
}

.klever-security-create-modal {
	padding: 18px 0px;
}

.klever-security-create-modal-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: @white-contrast;
	margin-bottom: 8px;
}

.klever-security-create-modal-subtitle {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: @white-contrast;
	margin-bottom: 8px;
}

.klever-security-create-modal-description {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	margin-bottom: 16px;
}

.klever-security-create-modal-success-description {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	margin-bottom: 16px;
	margin-top: 16px;
	padding: 0 30px;
}

.klever-security-create-modal-qr-description {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	margin: 20px 0;
}

.klever-security-create-modal-box-information {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: @white-contrast;
	padding: 8px 20px;
	background-color: #a5a5a53d;
	border-radius: 8px;
	margin-bottom: 24px;
}

.klever-security-withdrawal-modal {
	padding: 18px 0px;
}

.klever-security-withdrawal-modal-input-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: @white-contrast;
	margin-bottom: 5px;
}

.klever-security-withdrawal-modal-description {
	padding: 0;
	margin: 0 0 20px 0;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;

	strong {
		color: @primary-color;
	}
}

.klever-google-auth-activate-modal-input-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: @body-background;
	margin-top: 10px;
}

.klever-google-auth-validate-modal-subtitle {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: @white-contrast;
	margin: 20px 0 5px 0;
}

.klever-google-auth-validate-modal-paragraphs-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	margin: 5px 0;
}

.klever-google-auth-validate-modal-paragraph {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: #fff;
	margin: 5px 0;
}

.klever-google-auth-activate-icon {
	margin-top: 10px;
	margin-bottom: 10px;
}

.klever-primary-btn-outline {
	border: 1px solid #fff;
	background: transparent;
	font-weight: normal;
	color: @primary-color;
}

.klever-primary-btn-outline-my-orders {
	border: none;
	background: @secondary-color;
	font-weight: normal;
	color: @primary-color;
}

.klever-holdings-spot-balance {
	width: 100%;
	background: @secondary-color;
	padding: 20px 0;
	max-width: 1440px;
	margin: auto;
}

.klever-holdings-history-btn {
	color: @primary-color;
}

.klever-spot-balance-mobile,
.klever-yesterdays-pnl {
	margin: 0;
}

.klever-spot-balance-mobile span,
.klever-yesterdays-pnl span {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
	margin-bottom: 10px;

	b {
		color: @primary-color;
	}
}

.klever-spot-balance-mobile h2,
.klever-yesterdays-pnl h2 {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 40px;
	line-height: 47px;
	color: @white;
	margin-bottom: 5px;
}

.klever-spot-balance-mobile small,
.klever-yesterdays-pnl small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: @white-contrast;
	opacity: 0.6;
}

.klever-spot-balance,
.klever-yesterdays-pnl {
	margin: 10px 10px 10px 20px;
}

.klever-holdings-spot-balance .ant-divider {
	height: 80px;
	opacity: 0.6;
	background: @white-contrast;
}

.klever-spot-balance span,
.klever-yesterdays-pnl span {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
	margin-bottom: 10px;

	b {
		color: @primary-color;
	}
}

.klever-spot-balance h2,
.klever-yesterdays-pnl h2 {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 40px;
	line-height: 47px;
	color: @white;
	margin-bottom: 5px;
}

.klever-spot-balance small,
.klever-yesterdays-pnl small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: @white-contrast;
	opacity: 0.6;
}

.klever-yesterdays-pnl button {
	margin-bottom: 0.4em;
	margin-left: 10px;
}

.klever-holdings-container {
	margin: 30px 0;
}

.klever-my-orders {
	margin-top: 10px;
}

.klever-my-orders-line {
	position: absolute;
	height: 1px;
	left: 2.22%;
	right: 2.22%;
	top: 193px;
	background: @secondary-color;
	transform: matrix(1, 0, 0, -1, 0, 0);
}

.klever-holdings-container label {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-holdings-head-actions {
	display: flex;
	align-items: center;
}

.klever-holdings-head-actions .ant-btn-link span {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	text-align: right;
	text-decoration-line: underline;
	color: @white-contrast;
}

.klever-holdings-head-actions .hide {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	position: absolute;
	border-radius: 8px;
}

.klever-header-button-right {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	text-decoration-line: overline;
	color: @primary-color;
	margin-bottom: 10px;
}

.klever-header-button-letf {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	margin: auto;
	border-spacing: 0;

	.ant-checkbox-inner {
		border: 1.5px solid @white-contrast;
	}
}

.klever-header-button-letf-mobile {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	margin: auto;
	border-spacing: 0;
	max-width: 180px;

	.ant-checkbox-inner {
		border: 1.5px solid @white-contrast;
	}
}

.klever-holdings-table .ant-table-thead > tr > th,
.klever-holdings-table .ant-table-thead > tr > th:hover {
	background: transparent;
}

.klever-holdings-table .ant-table table {
	border-spacing: 0 10px;
}

.klever-holdings-table .ant-table {
	background: transparent;
}

.klever-holdings-table tbody:before {
	content: '-';
	display: block;
	line-height: 1px;
	color: transparent;
}

.klever-holdings-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-holdings-table .ant-table-container table > thead > tr > th {
	background: transparent;
	padding: 0px 16px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: @white;
}

.klever-holdings-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-holdings-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-holdings-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-holdings-table .ant-table-column-title {
	flex: none;
}

.klever-holdings-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-holdings-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-holdings-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-holdings-table tr {
	border-radius: 8px;
}

.klever-holdings-table td {
	background: @body-background !important;
	padding: 8px 0 6px 10px !important;

	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: @white;
}

.klever-holdings-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-holdings-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-holdings-table .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
	border-bottom: none;
	background: @secondary-color !important;
}

.klever-holdings-table .ant-table-tbody tr:first-child {
	display: none;
}

.klever-holdings-table-name {
	size: 14px;
}

.klever-holdings-avatar-icon {
	margin-left: 24px;
}

.klever-my-orders-table .ant-table-thead > tr > th,
.klever-my-orders-table .ant-table-thead > tr > th:hover {
	background: transparent;
}

.klever-my-orders-table .ant-table table {
	border-spacing: 0 10px;
}

.klever-my-orders-table .ant-table {
	background: transparent;
}

.klever-my-orders-table tbody:before {
	content: '-';
	display: block;
	line-height: 1px;
	color: transparent;
}

.klever-my-orders-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-my-orders-table .ant-table-container table > thead > tr > th {
	background: transparent;
	padding: 0px 16px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: @white;
}

.klever-my-orders-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-my-orders-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-my-orders-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-my-orders-table .ant-table-column-title {
	flex: none;
}

.klever-my-orders-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-my-orders-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-my-orders-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-my-orders-table tr {
	border-radius: 8px;
}

.klever-my-orders-table td {
	background: @secondary-color !important;
	padding: 8px 0 6px 10px !important;

	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: @white-contrast;
}

.klever-my-orders-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-my-orders-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-my-orders-table .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
	border-bottom: none;
	background: @secondary-color !important;
	margin: 16px 0px;
}

.klever-my-orders-actions span {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: @white-contrast;
	text-decoration-line: none;
	transition: 0.3s;

	&:hover {
		color: @primary-color;
	}
}

.klever-my-orders-cancel {
	color: @white-contrast;
}

.klever-my-orders-cancel:not(:disabled):hover {
	color: @white-contrast;
	filter: brightness(0.9);
}

.klever-my-orders-cancel:disabled {
	opacity: 0.6;
	color: @white-contrast-2;
	cursor: not-allowed;
}

.klever-holdings-token-name {
	font-style: normal;
	font-weight: normal;
	font-size: 14px !important;
	line-height: 16px;
	color: @white;
	white-space: nowrap;
}

.klever-holdings-avatar {
	margin-left: 24px;
	margin-right: 8px;
}

.klever-holdings-table small {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: right;
	color: @white-contrast;
}

.klever-my-orders-select {
	margin-bottom: 8px;
	min-width: 100px;
}

.klever-my-orders-select .ant-select-selector {
	display: flex;
	border-radius: 8px !important;
	border: 1px solid @white-contrast !important;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	height: 36px !important;
	width: 200%;
	color: @white-contrast;
}

.klever-my-orders-select .ant-select-selector span {
	line-height: 35px !important;
}

.klever-all-my-orders-status {
	display: flex;
	flex-direction: column;

	span.klever-my-orders-text {
		margin: 5px;
	}
}

.klever-all-my-orders-invert {
	margin: 6px;
}

.klever-my-orders-top-buttons {
	margin: 40px;
}

.klever-my-orders-text,
.klever-nft-orders-text {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.02em;
	color: @white;
}

.klever-my-orders-title {
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	margin-bottom: 40px;
}

.klever-my-orders-filter {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.02em;
	margin: 24px 0px 5px 0px;
}

.klever-pagination {
	align-items: flex-end;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: stretch;
	justify-content: flex-end;
}

.klever-pagination-button {
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white;
	border-radius: 5px;
	border: none;
}

.klever-pagination-button-2 {
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	border-radius: 8px;
	border: none;
}

.klever-pagination-button-3 {
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	border-radius: 8px;
	border: none;
}

.klever-cancel-order {
	background: @secondary-color;
	border: 1px solid @secondary-color;
	border-radius: 8px;
}

.klever-cancel-order1 {
	background: @secondary-color;
}

.klever-my-orders-margin,
.klever-nft-orders-margin {
	margin-right: 10px;
}

.klever-pagination-button-selected {
	background: @white-contrast;
	color: @white-contrast;
}

.klever-pnl-positive {
	color: @green !important;
	padding-left: 5px;
}

.klever-pnl-negative {
	color: @red !important;
	padding-left: 5px;
}

.klever-verified-icon {
	position: absolute;
	right: -6px;
	top: -3px;
	width: 16px;
	height: 16px;
}

.klever-letter-avatar {
	background: @secondary-color;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: @white-contrast;
}

.klever-user-info {
	padding-top: 15px;
	display: flex;
	align-items: center;
}

.klever-user-info b {
	margin-left: 20px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	color: @white-contrast;
}

.klever-user-info small {
	margin-left: 20px;
	margin-top: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
	opacity: 0.6;
}

.klever-container {
	background: @body-background;
	border-radius: 8px;
	padding: 15px;
	margin-bottom: 10px;
}

.klever-container-white {
	background: @white;
	border-radius: 8px;
	padding: 20px;
	margin: 27px 0 10px 30px;
	height: 93%;
}

.klever-row {
	margin-top: 10px;
}

.klever-space-right {
	padding-right: 5px;
}

.klever-container h5,
.klever-balance-button {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: @white !important;
	margin-bottom: 0;

	span {
		margin-left: 5px;
	}
}

.klever-hide-button {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-account-badge {
	width: 69px;
	height: 39px;
	background: @white-contrast;
	border-radius: 4px;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: @white;
}

.klever-security-list {
	padding: 10px 20px 20px 20px;
	background-color: @secondary-color;
	z-index: 10;
	overflow: hidden;
	border-radius: 8px;

	.bg {
		// background-image: url('https://i.imgur.com/XRkFWQk.png');
		background-position: right top;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: -1;
		opacity: 0.5;
		top: -5px;
	}

	.klever-security-title {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		color: @white;
		margin-bottom: 0;
	}

	h4 {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		color: @white;
		padding-top: 10px;
		margin-bottom: 0 !important;
		cursor: pointer;

		&:hover {
			color: @primary-color;
		}
	}

	h5 {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 21px;
		color: @white-contrast;
	}

	// .ant-list-item {
	// 	cursor: pointer;
	// }
}

.klever-security-list ul {
	margin-top: -20px;
}

.klever-security-list h4 > a {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
}

.klever-security-list .ant-list-item-meta-description {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: @white-contrast;
}

.klever-security-list .anticon {
	color: @white-contrast;
}

.klever-security-list .ant-divider {
	border-top: 1px solid @white-contrast;
	margin: 20px 0 0 0;
}

.klever-container-balance h2 {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 38px;
	color: @white;
	margin-bottom: 0.1em;
	margin-top: 0.1em;
}

.klever-balance-coin-list .ant-list-item {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: @white;

	span {
		font-size: 32px;
		margin-right: 5px;
	}
}

.klever-balance-chart path {
	stroke: @white !important;
	stroke-width: 4px;
	stroke-linejoin: round;
}

.klever-container-balance .klever-spot-balance {
	margin-top: 30px;
	margin-left: 15px;
}

.klever-container .anticon svg {
	margin-bottom: 2px;
}

.klever-blue-link span {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	text-decoration-line: underline;
	color: #36a8e9;
}

.klever-vip-level-badge {
	background: linear-gradient(114.7deg, @primary-color 31.5%, @primary-gradient-color 100%);
	border-radius: 4px;
	width: 45px;
	height: 33px;
}

.klever-container h3 {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 21px;
	color: @white-contrast;
	margin-top: 0.5em !important;
	margin-bottom: 0.5em;
}

.klever-level-description {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-content {
	padding: 0 10px;
}

.klever-switch-container {
	background: @body-background;
	border-radius: 4px;
	padding: 10px;
	margin: 35px 0 10px 0;
}

.klever-switch-container .ant-switch-checked {
	background-color: @green;
}

.klever-activity-list h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-activity-list .ant-list-item-meta-description {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-activity-list small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	text-align: right;
	color: @white-contrast;
}

.ant-tabs-tab-btn {
	outline: none;
	transition: all 0.3s;
}

.klever-activity-tab .ant-tabs-tab-btn {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-activity-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-activity-tab > .ant-tabs-nav::before {
	border-bottom: 1px solid @white-contrast;
}

.klever-mockup-promo img {
	position: absolute;
	left: 0;
	bottom: 0;
	border-bottom-left-radius: 8px;
	width: 35%;
	max-height: 90%;
}

.klever-mockup-promo h3 {
	margin-left: 40%;
	margin-top: 20px;
	margin-bottom: 50px;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 36px;
	color: @white-contrast;
}

.klever-btn-store img {
	width: 90px;
}

.klever-promo-content {
	margin-left: 40%;
}

.klever-promo-content small {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 9;
	line-height: 13px;
	color: @white;
}

.klever-promo-content .klever-logo {
	margin: 10px 0;
}

.klever-buy-crypto {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 22px;
	color: @white-contrast;
	margin-bottom: 3px;
}

.klever-buy-crypto .anticon {
	font-size: 8px;
}

.klever-buy-crypto .anticon svg path {
	fill: @primary-color;
}

.klever-ghost-collapse {
	.ant-collapse-content-box {
		padding: 0 !important;
		padding-top: 10px !important;
	}

	.ant-collapse-header {
		padding: 0 !important;
	}
}

.klever-mobile-menu {
	.ant-drawer-body {
		padding: 0 !important;
		background: @menu-dark-bg;
	}

	.ant-menu-sub {
		background: @menu-dark-bg !important;
	}

	.ant-drawer-header {
		background: @body-background !important;
		border: none !important;
	}
}

.klever-mobile-placeorder {
	.ant-drawer-body {
		background: @body-background;
		padding: 0;
	}

	.ant-drawer-content-wrapper {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
}

.klever-mobile-bottom {
	z-index: 99;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 0 10px 10px 10px;
	background: @body-background;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.klever-mobile-space-left {
	padding-left: 5px;
}

.klever-mobile-space-right {
	padding-right: 5px;
}

.klever-mobile-space-bottom {
	padding-bottom: 5px;
}

.klever-placeorder-type-radio {
	margin-bottom: 8px;
	background-color: @secondary-color;
	width: 100%;
	border-radius: 4px;
}

.klever-placeorder-type-radio .ant-radio-button-wrapper {
	background-color: none !important;
	border: none !important;
}

.klever-placeorder-button,
.klever-placeorder-button {
	border: none;
	width: 50%;
	text-align: center;
	font-size: 12px;
}

.klever-placeorder-button:hover,
.klever-placeorder-button:hover {
	color: @white;
}

.klever-placeorder-type-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	border-radius: 4px;
}

.klever-placeorder-type-radio .klever-placeorder-button-buy {
	border-color: @green !important;
	background-color: @green !important;
}

.klever-placeorder-type-radio .klever-placeorder-button-sell {
	border-color: @red !important;
	background-color: @red !important;
}

.klever-modal-content {
	display: flex;
	justify-content: space-between;
}

.klever-modal-description {
	color: @white;
	margin-bottom: 10px;
}

.klever-modal-description-light {
	color: @body-background;
	margin-bottom: 10px;
}

.klever-modal-options-container {
	display: flex;
	flex-direction: column;
}

.klever-modal-options {
	color: @white-contrast;
	margin-bottom: 10px;
}

.klever-modal-options-active {
	color: @primary-color;
	font-weight: bold;
	margin-bottom: 10px;
}

.klever-modal-options-right {
	color: @white-contrast;
}

.klever-modal-options-right-active {
	color: @primary-color;
	font-weight: bold;
}

.klever-modal-currency-options {
	display: flex;
}

.klever-layout-title-wrapper {
	padding: 10px 16px 10px 0px;
	color: @white-contrast;
	font-size: 12px;
	font-weight: bold;
}

.klever-user-menu-container {
	background: @body-background;
	border-radius: 8px;
	padding: 10px 20px 10px 10px;
	width: 230px;

	ul {
		border: none !important;

		li {
			height: 35px !important;
			margin-bottom: 5px !important;
		}
	}
}

.klever-user-menu-button-language {
	padding: 4px;
	width: 90px;
	height: 36px;
	background: @secondary-color;
	border-radius: 4px;
	color: @white;
	margin-right: 10px;

	cursor: not-allowed;
}

.klever-row-preferences {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	gap: 10px;
}

.klever-user-menu-button-currency-dropdown {
	padding: 4px;
	width: 90px;
	height: 36px;
	background: @secondary-color;
	border-radius: 4px;
	color: @white;

	cursor: pointer;
}

.klever-user-menu-button-currency {
	color: @primary-color;
	font-weight: 700;

	margin-right: 30px;
	border: none;
	background: none;
	cursor: pointer;
	padding: 0;
	font-size: 14px;
	transition: all 0.3s ease-in-out;

	:hover {
		color: #c05600;
	}
}

.klever-user-menu-button-currency-light {
	padding: 4px;
	width: 90px;
	height: 36px;
	background: @white;
	border-radius: 4px;
	color: @white-contrast;
}

.klever-user-menu-button-language-light {
	padding: 4px;
	gap: 10px;
	width: 90px;
	height: 36px;
	background: @white;
	border-radius: 4px;
	color: @white-contrast;
}

.klever-row-classic {
	padding: 24px;
}

.klever-user-menu-container-light {
	background: @white;
}

.klever-holdings-keypairs-menu-container,
.ant-select-dropdown {
	background: @body-background;
	border-radius: 8px;
	padding-top: 10px;
	padding-bottom: 10px;

	.ant-select-item-option-active,
	.ant-select-item-option-selected {
		background: @secondary-color;
	}
}

.klever-holdings-keypairs-menu-item {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.klever-user-menu-language-button {
	background: @body-background;
	padding: 10px 70px 10px 10px;
	border-radius: 8px;
	color: @white;
	font-weight: bold;
	width: 90%;
}

.klever-user-menu-item-top,
.klever-user-menu-item-top-last {
	span {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		position: static;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 0px 8px 0px 16px;
		font-size: 12px;
		font-weight: 500;

		&:hover {
			color: @primary-color;
		}
	}
}

.klever-user-menu-item-top-last {
	border-right: none;
	margin-right: 8px;
}

.klever-user-menu-item-top-active-products-item {
	span {
		color: @primary-color;

		&:hover {
			color: @primary-color;
		}
	}
}

.klever-user-menu-item-top-last-products-item {
	span {
		&:hover {
			color: @primary-color;
		}
	}
}

.klever-icon-header {
	padding: 4px 10px 0px 0px;
}

.klever-user-menu-item span {
	display: flex;
	align-items: center;
}

.klever-user-menu-item span svg {
	height: 16px;
}

.klever-user-menu-language-item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.klever-user-menu-item-text {
	color: @white;
	font-weight: bold;
}

.klever-user-menu-item-text-active {
	color: @primary-color;
	font-weight: bold;
}

.klever-trades-tabs-container {
	padding: 0px;
	margin: 0;

	.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
		padding: 10px;
		background: @body-background;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
	}

	.ant-tabs-card .ant-tabs-tab {
		background: transparent !important;
		border-color: transparent !important;
		border-top-left-radius: 5px !important;
		border-top-right-radius: 5px !important;
	}

	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
		border: none !important;
	}

	.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
	.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
		background: @body-background !important;
	}

	.ant-tabs-tab .klever-pair-tab-title {
		font-size: 12px;
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: 600;
	}

	.ant-tabs-tab-active .klever-pair-tab-title {
		color: @white-contrast;
	}

	.ant-tabs-tab-active .ant-tabs-tab-remove {
		color: rgba(0, 0, 0, 0.45);
	}

	.ant-tabs-card .ant-tabs-content {
		margin-top: -16px;
	}
}

.klever-trade-tabs > .ant-tabs-nav::before {
	border: none !important;
}

.klever-trade-tabs .ant-tabs-tab {
	background: @body-background !important;
	border-top-left-radius: 2px !important;
	border-top-right-radius: 2px !important;
	border: none !important;
}

.klever-orderbook-content {
	height: 42vh;
}

.klever-markets-drawer {
	.ant-drawer-content-wrapper {
		margin-top: 40px;
		height: 100vh;
	}

	.ant-drawer-content {
		border-radius: 8px;
	}

	.ant-drawer-body {
		padding: 0 !important;
		background: @body-background;
		overflow: hidden;
	}
}

.klever-login-layout {
	background-color: @white;
	height: 100vh;
}

.klever-header-keypair {
	flex: 1;
	padding: 0 10px;

	.klever-trading-value {
		font-size: 16px;
	}

	@media screen and (max-width: 525px) {
		display: none;
	}

	.klever-trading-keypair-wrapper {
		span {
			font-weight: 700;
		}

		.ant-avatar-image {
			margin-right: 10px;
		}
	}

	.klever-trading-currency span {
		margin-bottom: 0;
	}

	.klever-trading-table {
		margin-bottom: 0;
	}

	.klever-trading-table td,
	.klever-trading-table th {
		padding: 0 5px;
	}

	.klever-trading-table-content td {
		padding-top: 5px;
		text-align: inherit;
	}
}

.klever-user-menu-item .ant-menu-title-content a {
	color: @white-contrast;
	font-weight: bold;
	margin-left: 10px;
	font-size: 16px;
	transition: 0.3s;
}

.klever-user-menu-item-active .ant-menu-title-content a {
	color: @primary-color !important;
	font-weight: bold;
	margin-left: 10px;
	font-size: 16px;
}

.klever-user-menu-item .ant-menu-title-content a:hover {
	color: @primary-color;
}

.klever-deposits-content {
	border: 1px solid @secondary-color;
	border-radius: 8px;
	margin: 5px 25px 5px 55px;
	padding: 15px;

	label {
		font-family: Noto Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 12px;
		color: @white-contrast;
		margin-left: 10px;
	}
}

.klever-withdraw-limits-card {
	margin: 5px 25px 0 0;
}

.klever-coin-option {
	height: 100%;

	b {
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		color: @white;
		margin-right: 5px;
		margin-left: 5px;
	}

	small {
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: @white-contrast;
	}
}

.input-filter-collection-mobile {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 15px 0;
	height: 42px;

	p {
		text-align: left;
		color: @white-contrast;
		margin-bottom: 0;
	}

	svg {
		fill: @white-contrast;
	}
}

.klever-deposits-table-head {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-deposits-table-content {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: #a9a9a9;
}

.klever-deposits-table td {
	padding-top: 8px;
}

.klever-deposits-appeal-info {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #a9a9a9;
}

.klever-deposit-submit {
	background: #fff;
	border-color: #fff;
	padding-bottom: 28px;
	margin-top: 10px;
}

.klever-deposit-submit span {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: @white-contrast;
}

.klever-deposit-submit .anticon svg {
	margin-bottom: 0 !important;
}

.klever-deposit-info-list {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: #7b7db2;
	padding: 15px 0 0 0;
}

.deposit-address-title {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	color: @white-contrast;
	margin-bottom: 10px;
}

.deposit-address-balance {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #26274a;
}

.deposit-address-balance small {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #7b7db2;
	margin-left: 10px;
}

.deposit-address-info {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
}

.klever-container-white .ant-divider {
	background: rgba(66, 70, 101, 0.25);
}

.klever-deposit-qrcode {
	margin: 10px 0 20px 0;
}

.klever-copy-btn {
	border-radius: 8px;
	margin-right: -7px;
	display: flex;
	align-items: center;
}

.klever-copy-input {
	background: #ececf1;
	border-radius: 8px;
	border: none;

	.ant-input {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: @body-background;
		cursor: default;
	}
}

.ant-input-affix-wrapper.klever-paste-input {
	border-bottom: 1px solid #353945 !important;
}

.klever-paste-input {
	max-width: 480px;
	border: none;

	.ant-input {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: @white-contrast;
		cursor: default;
		background: transparent !important;
	}
}

.klever-copy-btn span {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: @white;
}

.klever-copy-btn svg {
	margin-right: 4px;
}

.klever-memo-container {
	max-width: 480px;
	padding: 25px 12% 0 12%;
}

.klever-memo-content {
	padding: 0 12%;
}

.klever-memo-alert {
	background: rgba(244, 192, 57, 0.25);
	border-radius: 4px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: @white-contrast;
	padding: 8px 12px;
}

.klever-memo-alert p {
	margin-bottom: 0;
}

.klever-memo-alert span {
	margin-right: 5px;
}

.klever-deposit-appeal-container {
	padding: 10px 15px;
}

.klever-deposits-recent {
	padding: 15px 25px 15px 55px;
}

.klever-deposits-recent-menu.ant-menu {
	background-color: transparent;
	border-radius: 4px;
	margin: 20px 0;
	border: none !important;
}

.klever-deposits-recent-menu.ant-menu > li {
	background: @secondary-color;
	margin: 20px 0;
	position: relative;
	cursor: initial;
}

.klever-deposits-recent-menu .klever-deposits-recent-menu-details-wrapper {
	position: absolute;
	top: 5px;
	right: 15px;

	span {
		color: @primary-color;
		font-size: 12px;
		font-weight: 600;

		&:hover {
			color: @primary-color;
			cursor: pointer;
			opacity: 0.8;
		}
	}
}

.klever-deposits-recent-menu .ant-menu-item {
	height: auto !important;
}

.klever-withdraw-info {
	font-size: 12px;
	color: @white-contrast;
}

.klever-deposits-status-badge {
	padding: 3px;
	margin: 0 10px;
	border-radius: 4px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
}

.klever-recent-item-title b {
	margin-left: 10px;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: @white;
}

.klever-recent-item-title {
	margin-bottom: 15px;
	width: 100%;
}

.klever-recent-item-head {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-recent-item-content {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: @white-contrast;
	margin-top: 5px;
	margin-bottom: 5px;
}

.klever-recent-item-content svg {
	margin-top: 2px;
}

.klever-recent-item-content button {
	padding: 0 4px;
	height: 22px;

	&:hover {
		opacity: 0.8;
	}

	&:active,
	&:focus {
		background: inherit;
	}
}

.klever-recent-item-content small {
	max-width: 78%;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
}

.klever-deposits-recent-menu li {
	border-radius: 8px;
	padding: 15px 20px !important;
	margin-bottom: 15px;
}

.klever-deposits-recent-menu li:hover,
.klever-deposits-recent-menu li.ant-menu-item-selected {
	background: @secondary-color !important;
}

.klever-deposit-modal {
	.ant-modal-content {
		padding: 0 15px;
		background-color: @body-background;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @body-background;
	}

	.ant-modal-body {
		background: @body-background;
		padding: 5px 5px 20px 5px;
		max-height: 420px;
		color: @body-background;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		color: @white;
	}

	.klever-close-btn {
		margin-top: 5px;
	}

	.klever-recent-item-head {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
	}

	.klever-recent-item-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: @white-contrast;
		max-width: 93%;
	}

	.klever-recent-item-content .ant-avatar {
		margin-right: 10px;
	}

	.klever-recent-item-content small {
		max-width: 80%;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		word-wrap: normal;
	}

	.klever-deposits-status-badge {
		margin-right: 0;
		padding-right: 0;
	}

	.ant-row-space-between {
		height: 30px;
	}

	.klever-icon-404 {
		font-size: 48px;
	}

	p {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
		padding-top: 15px;
	}

	.ant-empty-description {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
	}
}

.klever-deposit-modal-title {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.klever-risks-modal {
	.klever-risks-modal-cb {
		margin: -12px 0 12px 0;
	}

	.ant-modal-content {
		padding: 0 15px;
		background-color: @white;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @white;
	}

	.ant-modal-body {
		background: @white;
		padding: 5px 5px 20px 5px;
		color: @body-background;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		color: @secondary-color;
	}

	.klever-close-btn {
		margin-top: 5px;
		width: 100%;
	}
}

.modal-withdraw-nft-input {
	background: transparent;
	border-radius: 8px;
	border-bottom: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 15px 0;
	height: 42px;
}

.ant-modal.klever-withdraw-nft-modal {
	width: 450px !important;
	height: auto;
}

.klever-select-coin-and-network {
	color: @secondary-color;
	border: 1px solid @white-contrast;
	border-radius: 8px !important;
}

::placeholder {
	color: @white-contrast !important;
}

.klever-modal-withdraw-nft-card {
	position: static;
	width: '200px';
	height: '200px';
	background: @secondary-color;
	border-radius: 8px !important;
	margin: 18px 0px;

	img {
		margin: 15px;
		width: 100px;
		height: auto;
		border-radius: 8px;
		box-shadow: 3px 3px 15px #11122478;
	}

	.card-text {
		margin-left: 8px;
		justify-content: center;
		flex-direction: column;
		display: flex;

		span {
			margin-bottom: 4px;
		}
	}
}

.klever-withdraw-nft-modal {
	.klever-buy-buttons {
		.klever-btn-buy {
			background: @primary-color;
			border-radius: 8px;
		}

		.klever-btn-cancel {
			background: @secondary-color;
			border-radius: 8px;
		}

		button {
			width: 100%;
			margin-top: 15px;
			height: 54px;

			span:not(.ant-btn-loading-icon) {
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				color: @white;
			}
		}
	}

	.ant-modal-content {
		padding: 15px 15px;
		background-color: @body-background;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @body-background;
		font-size: 32px;
		line-height: 40px;
		color: @white;
	}

	.klever-modal-withdraw-nft-text {
		color: @white;
		font-size: 16px;
	}

	.klever-modal-withdraw-nft-text-input {
		color: @white-contrast;
		font-size: 18px;
	}

	.klever-modal-withdraw-nft-text-fee {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: @white-contrast;
		size: 16px;
		margin: 15px 0px;
	}

	.ant-modal-body {
		background: @body-background;
		padding: 5px 5px 20px 5px;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 32px;
		line-height: 14px;
		background: @body-background;
		color: @white;
	}

	.klever-close-btn {
		margin-top: 5px;
	}

	.klever-recent-item-head {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
	}

	.klever-recent-item-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: @white-contrast;
		max-width: 93%;
	}

	.klever-recent-item-content .ant-avatar {
		margin-right: 10px;
	}

	.klever-recent-item-content small {
		max-width: 80%;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		word-wrap: normal;
	}

	.klever-deposits-status-badge {
		margin-right: 0;
		padding-right: 0;
	}

	.ant-row-space-between {
		height: 30px;
	}

	.klever-icon-404 {
		font-size: 48px;
	}

	p {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
		padding-top: 15px;
	}

	.ant-empty-description {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white;
	}
}

.klever-recent-deposits li :hover {
	background: @secondary-color !important;
}

.klever-modal-list-menu {
	border: none;
	background: transparent;

	.klever-coin-item-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white;

		b {
			font-weight: 500;
		}

		small {
			color: @white-contrast;
		}
	}

	.klever-coin-item-disable-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: #93939b;

		b {
			font-weight: 500;
		}
	}

	.klever-coin-item-left {
		b {
			font-family: 'Noto Sans';
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			color: @white;
			margin-left: 10px;
		}

		small {
			font-family: 'Noto Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			color: @white-contrast;
			margin-left: 10px;
		}
	}

	li {
		padding: 10px 8px !important;
		height: auto !important;
		border-radius: 4px;
	}

	li:hover,
	li.ant-menu-item-selected {
		background: rgba(149, 151, 177, 0.2) !important;
	}

	li.ant-menu-item-disabled {
		opacity: 0.6;
	}

	.ant-collapse {
		border: none;
		background: transparent;
		color: @white;
		font-size: 16px;
		opacity: 0.6;

		.ant-collapse-content {
			.ant-collapse-content-box {
				background-color: @body-background;
				border-radius: 8px;
			}
		}

		.ant-collapse-item {
			border: none;

			.ant-collapse-header {
				padding: 12px;

				.ant-collapse-arrow {
					right: 7px;

					svg {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.klever-withdraw-input,
.klever-withdraw-input-modal {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0;
	height: 42px;
	color: @white;
}

.klever-withdraw-input-modal {
	color: @white;
}

.klever-withdraw-input::placeholder,
.klever-withdraw-input-modal::placeholder {
	color: @white-contrast;
}

.klever-withdraw-msg-wrapper {
	margin: -10px 5px 0 5px;
}

.klever-withdraw-last-address-wrapper {
	padding: 5px;

	p,
	span {
		font-weight: bold;
		font-size: 12px;
		color: @primary-color;

		@media screen and (max-width: 355px) {
			font-size: 10px;
		}
	}

	span {
		&:hover {
			opacity: 0.8;
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.klever-withdraw-last-address-skeleton-wrapper {
	margin-top: -8px;
}

.klever-withdraw-deposit-container {
	padding: 5px;
}

.klever-withdraw-container-right {
	border-radius: 8px;
	padding: 20px;
	margin: 27px 0 10px 30px;
}

.klever-deposit-modal .ant-divider {
	background: rgba(66, 70, 101, 0.25);
	margin: 12px 0;
}

.klever-withdraw-history {
	margin-bottom: 5px;

	b {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
	}

	small {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #7b7db2;
	}

	svg {
		margin-right: 10px;
		margin-top: 5px;
	}
}

.klever-withdraw-history:first-child {
	margin-top: 5px;
}

.klever-withdraw-input-security {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 5px 0;
	height: 42px;
	color: @white-contrast !important;

	.ant-input {
		color: @white-contrast !important;
	}
}

.klever-withdraw-input-nft {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 5px 0;
	height: 42px;
	color: @white-contrast;
}

.klever-withdraw-input-security::placeholder {
	color: @white-contrast;
}

.klever-withdraw-input-paste {
	background: @body-background;
	border: none;
	margin-top: 5px;
	padding-right: 0px !important;

	svg {
		cursor: pointer;
	}
}

.klever-withdraw-input-paste-white {
	background: transparent;
	border: none;
	margin-top: 5px;
	padding-right: 0px !important;
	color: @white-contrast !important;
}

.klever-withdraw-input-addon {
	position: absolute;
	right: 15px;
	margin-top: 10px;

	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: @white-contrast;

	button {
		padding-right: 3px;
		padding-left: 5px;
	}

	.ant-btn-icon-only > span > svg {
		font-size: 12px;
	}

	.ant-btn-icon-only > span > svg > path {
		fill: @white-contrast;
	}
}

.klever-withdraw-modal-internal {
	background-color: #f5f5f5;
	color: #1e1e26;
	padding: 5px;
	font-size: 8px;
	border-radius: 8px;
}

.klever-withdraw-modal,
.klever-modal-2fa {
	.ant-modal-content {
		padding: 0 15px;
		background-color: @body-background;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @body-background;
	}

	.ant-modal-footer {
		padding-left: 5px;
		padding-right: 5px;
		background: @body-background;
	}

	.ant-modal-body {
		background: @body-background;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-footer {
		border-top: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		color: @white;
	}

	.klever-close-btn {
		margin-top: 5px;
	}

	.klever-recent-item-head {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white;
	}

	.klever-recent-item-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: @white-contrast;
		max-width: 100%;
	}

	.klever-recent-item-content .ant-avatar {
		margin-right: 10px;
	}

	.ant-row-space-between {
		height: 30px;
	}

	.klever-icon-404 {
		font-size: 48px;
	}
}

.klever-modal-2fa {
	.ant-modal-content {
		background-color: @white;
	}

	.ant-modal-header {
		background: @white;
	}

	.ant-modal-footer {
		background: @white;
	}

	.ant-modal-body {
		background: @white;
	}

	.ant-modal-title {
		color: @body-background;
	}

	.klever-recent-item-head {
		color: @body-background;
	}

	.klever-recent-item-content {
		color: @body-background;
	}
}

.klever-input-number-control {
	padding: 0;
	height: 14px;
	font-size: 5px;
}

.klever-logged-language {
	padding: 10px;
	cursor: pointer;
}

.klever-wallet-name {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 0em;
	margin-right: 5px;
}

.klever-language-menu-container {
	background: @modal-background;
	margin-top: 20px;
	padding: 20px 30px;
	font-weight: 500;
}

.klever-language-menu-container-light {
	background: @white;
	margin-top: 20px;
	padding: 20px 30px;
}

.klever-trade-dropdown {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 10px;

	background: @secondary-color;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.24);
	border-radius: 8px;

	a {
		color: @white !important;
		align-items: center;
		padding: 0;
		position: static;
		flex: none;
		flex-grow: 0;
		font-size: 12px;
		font-weight: 500;
	}
}

.klever-trade-dropdown-light {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 10px;

	background: @white;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.24);
	border-radius: 8px;
}

.klever-trade-overview,
.klever-trade-overview-kyc {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: @white-contrast;
}

.klever-trade-overview-light,
.klever-trade-overview-kyc-light {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: @body-background;
}

.klever-trade-overview-kyc,
.klever-trade-overview-kyc-light {
	width: 200px;
	background-color: @white-contrast;
	border-radius: 4px;
	padding: 5px;
	color: @white;

	> svg {
		padding-right: 5px;
	}
}

.klever-trade-price {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;

	color: @white;
}

.klever-trade-price-light {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;

	color: @white-contrast;
}

.klever-logged-wrapper-style {
	padding-left: 10px;
}

.klever-name-style {
	font-family: 'Noto Sans';
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	padding: 5px;
	color: @white;
	background-color: @secondary-color;
	border-radius: 4px;
	margin-left: 10px;

	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		margin: 0 1px 0 10px;
	}

	svg {
		margin-left: 10px;
		margin-right: 7px;
	}

	@media screen and (max-width: 1392px) {
		margin-left: 0;
	}
}

.klever-name-style-trade {
	padding: 0;
	cursor: pointer;
	line-height: 12px;
}

.klever-name-style-light {
	background-color: @white;
}

#klever-name-style-id {
	margin-bottom: 0;
}

.klever-modal-confirm-text {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	color: @white-contrast;
	padding: 15px 0px;
}

.klever-modal-confirm-text-danger {
	font-family: 'Rubik';
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	color: #f55c5c;
	padding: 5px 0px;
}

.klever-confirm-btn {
	margin-top: 5px;
	padding-left: 25px !important;
	padding-right: 25px !important;
	background: @primary-color;
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 10px;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: @white !important;
}

.klever-confirm-btn[disabled] {
	background: #d96200a1 !important;
}

.klever-confirm-btn[disabled]:hover {
	background: #d96200a1 !important;
}

.klever-cancel-btn[disabled] {
	color: #d9620094;
}

.klever-cancel-btn[disabled]:hover {
	color: #d9620094;
}

.klever-cancel-btn {
	margin-top: 5px;
	padding-left: 25px !important;
	padding-right: 25px !important;
	background: transparent;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: @primary-color;
}

.klever-markets-trade-table .ant-table-body {
	background: @secondary-color;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	height: 220px;
}

.klever-markets-trade-table-light {
	background: @white;
}

.klever-markets-trade-table td {
	background: transparent !important;
}

.klever-full-table .ant-table-body {
	background: transparent !important;
	width: 100%;
	height: calc(100vh - 200px);
}

.klever-icon-rotate {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.klever-holdings-table-available-col {
	padding-left: 5px;
}

.klever-holdings-table-inorder-col {
	padding-left: 10px;
}

.klever-holdings-trade-arrow-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid @white;
	margin-left: 5px;
	transition: 0.3s;
}

.klever-holdings-trade-button {
	display: flex;
	align-items: center;

	background: @primary-color;
	border-radius: 4px;

	span {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: @white !important;
	}

	span:hover {
		color: @white !important;
	}

	i {
	}
}

.klever-holdings-trade-button:hover {
	background: @primary-color;
}

.klever-home-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold !important;
	font-size: 48px !important;
	line-height: 59px;
	color: @white !important;
}

.klever-home-title-container {
	padding: 50px 0 0 0px;

	button {
		margin-top: 30px;
	}
}

.klever-home-subtitle {
	font-family: 'Noto Sans';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: @white !important;
}

.klever-home-app-ad-title {
	font-family: 'Noto Sans';
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 48px !important;
	line-height: 59px !important;
	color: @white !important;
	margin-bottom: 0.3em !important;
}

.klever-home-app-ad-content {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #f5f5f5;
	margin-bottom: 20px;
	max-width: 240px;
}

.klever-home-twitter-title {
	font-family: 'Noto Sans';
	font-style: normal !important;
	font-weight: 800 !important;
	font-size: 160px !important;
	line-height: 195px !important;
	color: @secondary-color !important;
	margin-bottom: 0 !important;
}

.klever-home-img {
	width: 50vw;
	height: auto;
	max-height: 50vh;
}

.klever-home-img-gradient {
	position: absolute;
	top: -120px;
	max-height: 50vh;
}

.klever-gradient-bg {
	position: absolute;
	width: 100%;
	height: 50%;
	right: 0;
	bottom: -15px;
	background: linear-gradient(180deg, rgba(11, 11, 30, 0) 21.58%, @body-background 84.89%);
}

.klever-home-space {
	padding-left: 40px;
	padding-right: 40px;
}

.klever-home-head-space {
	padding-left: 40px;
	padding-right: 40px;
}

.klever-carousel-img-card {
	object-fit: cover;
	width: 20vw;
	max-height: 168px;
	border-radius: 8px;
	animation: fadeIn 0.5s;
	-webkit-animation: fadeIn 0.5s;
	-moz-animation: fadeIn 0.5s;
	-o-animation: fadeIn 0.5s;
	-ms-animation: fadeIn 0.5s;
}

.klever-carousel-title-card {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px !important;
	line-height: 17px !important;
	color: @white;
	padding: 20px 10px 0 10px;
}

.klever-cards-carousel {
	margin-bottom: 60px;
}

.klever-app-section {
	padding: 60px 50px;
}

.klever-app-img {
	width: 30vw;
	max-width: 380px;
	margin-left: 100px;
}

.klever-app-promo-content {
	padding-left: 60px;
	padding-top: 30px;
}

.klever-logo-app {
	margin-top: 30px;
	margin-bottom: 15px;
	width: 130px;
}

.klever-btn-app-store img {
	width: 120px;
}

.klever-twitter-section {
	padding: 140px 5vw;
}

.klever-twitter-account {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	color: #c7e7ff;
}

.klever-twitter-margin {
	margin-bottom: 40px;
	margin-top: -60px;
}

.klever-faq-margin {
	margin-bottom: 40px;
	margin-top: -70px;
}

.klever-verified-blue {
	width: 12px;
	margin-left: 5px;
	margin-top: 5px;
}

.klever-tweet-card {
	width: 270px;
	height: 156px;
	background: @body-background;
	border: 0.8px solid @secondary-color;
	box-sizing: border-box;
	border-radius: 18px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: inline-block;
	vertical-align: middle;

	.ant-card {
		background: transparent;

		.ant-card-body {
			padding: 20px 15px;
		}
	}
}

.klever-twitter-scroll {
	display: block;
}

.klever-tweet-bottom {
	margin-top: 50px;
}

.klever-tweet-user {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 13;
	line-height: 15px;
	color: #1890ff;
	margin-top: 2px;
	margin-bottom: 5px;
}

.klever-tweet-ref {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 10px;
	color: #c7e7ff;
}

.klever-tweet-content {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: @white;
	margin-top: 30px;
	white-space: break-spaces;
	margin-left: 5px;
}

.klever-tweet-quote {
	position: absolute;
	top: 80px;
}

.klever-faq-section {
	padding: 10px 30px 120px 30px;

	.ant-collapse {
		background: transparent !important;
	}

	@media screen and (max-width: 1024px) {
		padding: 0 2vw;
	}
}

.klever-features-section {
	padding: 60px 30px 120px 30px;
}

.klever-faq-subtitle {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: @white;
	margin-bottom: 20px;
}

.klever-faq-title {
	font-family: 'Noto Sans' !important;
	font-weight: bold !important;
	color: @white !important;
	font-size: 56px !important;
	margin-bottom: 30px;
	text-align: center;

	@media screen and (max-width: 1024px) {
		font-size: 30px !important;
		margin-top: -20px;
	}
}

.klever-faq-expand-icon {
	width: 40px;
	height: 40px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	font-size: 12px !important;
	border-radius: 8px !important;
	color: @white;
	transition: all 200ms linear;
}

.klever-faq-item {
	border: none !important;
	padding: 0 15px;
	border-radius: 8px !important;
	margin-top: 10px;
	background: @secondary-color;

	.ant-collapse-header {
		color: @white;
		font-family: 'Noto Sans' !important;
		font-weight: 700 !important;
		font-size: 24px;

		@media screen and (max-width: 1024px) {
			font-size: 14px !important;
		}
	}

	p {
		font-size: 12px !important;
		color: #f5f5f5;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.6;
	}

	@media screen and (max-width: 1024px) {
		padding: 0 0 0 5px;
	}
}

.klever-footer-mobile-section {
	p {
		font-weight: 700;
		color: @white;
		font-size: 14px;
		line-height: 24px;
		font-family: 'Noto Sans';
	}

	h4 {
		font-size: 14px;
		display: block;
		font-weight: 700;
		font-family: 'Noto Sans';
		color: @white-contrast !important;
	}

	.socialMenuTitle {
		font-size: 14px;
		display: block;
		font-weight: 700;
		font-family: 'Noto Sans';
		color: @white !important;
	}

	.version-label {
		font-size: 14px;
		display: block;
		font-weight: 500;
		font-family: 'Noto Sans';
		color: @white-contrast !important;
	}

	.ant-menu {
		background: transparent;
		border: none !important;
	}

	.ant-menu-item,
	.ant-menu-item a {
		font-size: 12px;
		font-weight: 500;
		font-family: 'Noto Sans';
		line-height: 24px;
		height: 24px;
		color: @white !important;
	}

	.ant-menu-item a:hover {
		color: @primary-color !important;
		transition: ease 0.3s;
	}

	.klever-social-links {
		list-style: none;
		display: flex;
		margin-top: 20px;
		padding: 0px;
		gap: 5px;
		flex-wrap: wrap;

		li {
			float: left;
			margin-right: 0.5rem;
		}

		li a {
			width: 46px;
			height: 46px;
			line-height: 46px;
			color: @white;
			text-align: center;
			border: 2px solid @white-contrast;
			border-radius: 50%;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li a:hover {
			background: @primary-color;
			border-color: @primary-color;
			transition: ease 0.3s;
		}

		a > svg {
			width: 20px;

			path {
				fill: @white;
			}
		}

		ul {
			list-style-type: none;
			padding-left: 0 !important;
			margin-bottom: 1.5rem !important;
		}
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent !important;
	}

	.ant-menu-item:active,
	.ant-menu-submenu-title:active {
		background: transparent !important;
	}

	.klever-new-badge::before {
		top: 16px;
		right: 10px;
	}

	.klever-new-badge {
		vertical-align: bottom;
	}
}

.klever-footer-section {
	p {
		font-weight: 400;
		color: @white;
		font-size: 16px;
		line-height: 1.6;
		font-family: 'Rubik';
	}

	h4 {
		font-size: 16px;
		display: block;
		font-weight: 700;
		font-family: 'Noto Sans';
		color: @white !important;
	}

	.ant-menu {
		background: transparent;
		border: none !important;
	}

	.ant-menu-item,
	.ant-menu-item a {
		height: 30px;
		color: @white-contrast !important;
	}

	.ant-menu-item a:hover {
		color: @primary-color !important;
		transition: ease 0.3s;
	}

	.klever-social-links {
		list-style: none;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-top: 20px;
		padding: 0px;
		gap: 20px;

		li {
			float: left;
			margin-right: 0.5rem;
		}

		li a {
			width: 46px;
			height: 46px;
			line-height: 46px;
			color: @white;
			text-align: center;
			border: 2px solid @white-contrast;
			border-radius: 50%;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li a:hover {
			background: @primary-color;
			border-color: @primary-color;
			transition: ease 0.3s;
		}

		a > svg {
			width: 20px;

			path {
				fill: @white;
			}
		}

		ul {
			list-style-type: none;
			padding-left: 0 !important;
			margin-bottom: 1.5rem !important;
		}
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent !important;
	}

	.ant-menu-item:active,
	.ant-menu-submenu-title:active {
		background: transparent !important;
	}

	.klever-new-badge::before {
		top: 16px;
		right: 10px;
	}

	.klever-new-badge {
		vertical-align: bottom;
	}
}

.klever-footer-menu-main-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	grid-template-rows: 1fr;
	width: 100%;
	gap: 30px;
}

.klever-dustklv-container {
	margin: 30px 0;
}

.klever-dustklv-table-inorder-col {
	padding-left: 10px;
}

.klever-dustklv-table .ant-table-thead > tr > th,
.klever-dustklv-table .ant-table-thead > tr > th:hover {
	background: transparent;
}

.klever-dustklv-table .ant-table {
	background: transparent;
}

.klever-dustklv-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-dustklv-table .ant-table-container table > thead > tr > th {
	background: transparent;
	padding: 10px 14px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	color: @white;

	&:nth-child(2) {
		padding: 0px 25px;
	}

	&:last-child {
		padding: 8px;
	}
}

.klever-dustklv-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-dustklv-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-dustklv-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-dustklv-table .ant-table-column-title {
	flex: none;
}

.klever-dustklv-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-dustklv-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-dustklv-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-dustklv-table tr {
	border-radius: 8px;
}

.klever-dustklv-table td {
	background: @body-background !important;
	padding: 12px !important;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: @white;
}

.klever-dustklv-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 12px;
}

.klever-dustklv-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 12px;
}

.klever-dustklv-table .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
	border-bottom: 6px solid @body-background;
	background: @secondary-color !important;
}

.klever-dustklv-table .ant-table-tbody tr:first-child {
	display: none;
}

.klever-dustklv-table-name {
	size: 14px;
	width: 100%;
}

.klever-dustklv-table-container {
	width: 100%;
}

.klever-dustklv-spot-balance {
	width: 100%;
	background: @secondary-color;
	padding: 20px 15px;
	max-width: 1440px;
	margin: auto;

	.klever-spot-balance {
		flex: 1;
	}

	.klever-spot-balance-timer {
		background: @body-background;
		padding: 10px 25px;
		border-radius: 8px;
		margin-right: 10px;
		display: flex;
		align-items: center;

		span:first-child {
			color: @white-contrast;
			font-size: 12px;
			margin-right: 5px;
		}

		span:last-child {
			font-size: 16px;
			margin-top: -2px;
			color: @white;
		}
	}
}

.klever-dustklv-token-name {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: @white;
	white-space: nowrap;
}

.klever-dustklv-layout {
	margin-top: 60px !important;
	width: 100%;
	margin: auto;
	justify-content: center;
}

.dustklv-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 54px;
	line-height: 32px;

	&.ant-page-header {
		padding: 16px 0;
	}
}

.dustklv-subtitle {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.5rem;

	color: @white-contrast;
}

.klever-convert-balance {
	padding: 0 3vw;
	width: 100%;
	max-width: 1440px;
	margin: auto;
}

.klever-dustklv-head-row {
	padding: 0 3vw;
	margin: auto;
	max-width: 1440px;
	display: flex;
	align-items: center;

	.klever-dustklv-head-conversion-wrapper {
		display: flex;
		align-items: center;
		background-color: @secondary-color;
		border-radius: 4px;

		> span {
			color: @white-contrast;
			font-size: 14px;
			margin-left: 10px;
		}
	}
}

.klever-dustklv-head-row h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 16px;
	color: @white;
	margin-right: 40px;
	margin-bottom: 0;
}

.dustklv-button-convert {
	border: none;
	border-radius: 8px;

	&:hover {
		background: @primary-color;
		color: @white;
	}
}

.klever-dustklv-row {
	font-size: 14px;
	white-space: nowrap;
}

.klever-convert-small-balance {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	display: flex;
	align-items: center;

	svg {
		margin-right: 5px;
	}

	color: @primary-color;
	border: none;

	&:hover {
		color: @primary-color;
	}

	&:focus {
		background: @body-background !important;
	}
}

.dustklv-button-layout-2 {
	padding: 0 3vw;
	max-width: 1440px;
	margin: auto;
}

.klever-brand-logo-footer {
	width: 120px;
	height: auto;
	margin-bottom: 1.5rem !important;
}

.klever-coin-img-1 {
	position: absolute;
	width: 80px;
	top: 10px;
	left: 4vw;
}

.klever-klv-fees-accept-container {
	background: @body-background;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	height: 48px;

	p {
		margin-bottom: 0;
		margin-right: 20px;
		color: @white-contrast;
		font-size: 12px;
	}

	.ant-switch {
		width: 36px;
	}

	.ant-switch-checked {
		background: linear-gradient(135deg, #d96200 1.18%, #ff8f33 75.21%);
	}
}

.klever-coin-img-2 {
	position: absolute;
	width: 40px;
	top: -80px;
	right: 24vw;
}

.klever-coin-img-3 {
	position: absolute;
	width: 90px;
	top: 80px;
	right: 5vw;
}

.klever-coin-img-4 {
	position: absolute;
	width: 75px;
	bottom: -60px;
	left: 22vw;
}

.klever-holdings-row {
	font-size: 14px;
	white-space: nowrap;
}

.klever-holdings-actions {
	white-space: nowrap;
}

.klever-holdings-actions a span {
	color: @white-contrast;
}

.klever-holdings-actions a :hover {
	color: @white;
}

.klever-holdings-row small {
	font-size: 11px;
}

.klever-holdings-values {
	font-size: 14px !important;
}

button.rec-dot {
	background-color: @white-contrast;
	box-shadow: none !important;
}

button.rec-dot_active,
button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
	background: @white;
	box-shadow: none !important;
}

.klever {
	.rec-arrow {
		position: absolute;
		z-index: 1;
		background: @white-contrast;
		color: @body-background;
		width: 40px;
		height: 40px;
		min-width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5em;
	}

	.rec-arrow-right {
		right: 0;
	}

	.rec-arrow-left {
		left: 0;
	}

	.rec-arrow:hover {
		color: @white !important;
		background: @primary-color !important;
	}

	.rec-arrow:focus {
		background: @white-contrast !important;
	}
}

.disabled {
	cursor: not-allowed !important;
	opacity: 0.6;
}

.klever-error-icon {
	color: @red;
	margin-right: 5px;
}

.klever-keypair-details-table {
	margin-bottom: 5px;
	margin-top: 5px;
}

.klever-btn-hide-balance {
	display: flex;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
	margin-top: 10px !important;
	opacity: 0.5;
}

.klever-balance-container,
.klever-withdraw-limits-container {
	background: @secondary-color;
	border-radius: 8px;
	padding: 15px;
	margin-bottom: 10px;
}

.klever-withdraw-limits-container {
	background: @secondary-color;
	padding: 10px 20px;
}

.klever-withdraw-limits-container h4 {
	color: @white;
	font-size: 16px;
	font-weight: 700;
}

.klever-withdraw-limits-progress {
	margin: 5px 0 0 0;
}

.klever-withdraw-limits-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: Rubik;
	font-size: 12px;

	p:first-child {
		color: @white-contrast;
		font-size: 12px;
	}
}

.klever-withdraw-limits-kyc-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 1px 5px 10px;
	background: @body-background;
	border-radius: 4px;
	color: @white-contrast;
	font-size: 12px;
	font-family: Rubik;
}

.klever-user-menu-item-top-active-dropdown,
.klever-user-menu-item-top-active-dropdown-last {
	a {
		color: @primary-color !important;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		position: static;
		flex: none;
		order: 1;
		flex-grow: 0;
		font-size: 12px;
		font-weight: 500;
	}
}

.klever-user-menu-item-top-active-last {
	border-right: none;
}

.klever-user-menu-item-top-active,
.klever-user-menu-item-top-active-last {
	a {
		color: @primary-color !important;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		position: static;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 0px 8px 0px 16px;
		font-size: 12px;
		font-weight: 500;
	}
}

.klever-user-menu-item-top-active-last {
	border-right: none;
	margin-right: 8px;
}

.ant-select-item-option-content {
	font-size: 12px;
}

.klever-drawer-tabs {
	width: 100%;
}

.klever-pairs-tabs-price {
	white-space: nowrap;
}

.klever-privacy-policy-title-row {
	font-size: 60px;
	line-height: 68px;
	z-index: 1;
}

.klever-privacy-policy-title {
	margin-top: 20xp;
	font-size: 60px;
	line-height: 68px;
	z-index: 1;
}

.klever-privacy-policy-title h1 {
	font-size: 60px;
}

.klever-privacy-policy-title p {
	font-weight: 400;
	font-size: 20px;
	line-height: 5px;
	margin-bottom: 30px;
}

.klever-privacy-policy-table {
	margin-bottom: 18px;
}

.klever-privacy-policy-table li {
	margin-bottom: 18px;
}

.klever-privacy-policy-table-li li {
	margin-bottom: 18px;
	list-style-type: upper-roman;
}

.klever-terms-of-use-subtitle h1 {
	font-size: 24px;
}

.klever-terms-of-use-subtitle p {
	margin-bottom: 1rem;
}

.klever-terms-of-use-layout {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	margin-top: 80px !important;
	margin-bottom: 80px !important;
	padding: 0 3vw !important;
}

.klever-trading-competition-layout {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	margin-top: 100px !important;
	margin-bottom: 100px !important;
	max-width: 1350px;
}

.klever-trading-competition-title {
	color: @white;
}

.klever-trading-competition-link {
	height: 39px;
	background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
	border: none;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	padding: 0 20px;

	&:hover {
		background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
		color: #ffffff;
		opacity: 0.8;
	}

	@media (max-width: 572px) {
		margin: 30px auto;
	}
}

.klever-trading-competition-text {
	color: #f0f0f0;
	margin-top: 20px;
	text-align: justify;

	&:first-child {
		margin-top: 0;
	}

	.ant-typography {
		color: @white;
		font-weight: bold;
	}
}

.klever-trading-competition-subtitle-1 {
	margin: 30px 0 20px 0;
}

.with-message-full {
	.klever-header-container {
		position: relative;
	}
}

.with-message {
	.klever-site-layout,
	.klever-home-layout,
	.klever-holdings-layout,
	.klever-account-layout,
	.klever-deposits-layout,
	.klever-api-layout,
	.klever-collection-layout,
	.klever-holdings-layout-page,
	.bfs-banner-container,
	.klever-dustklv-layout,
	.pools-history-container {
		margin-top: 100px !important;
	}

	.klever-pools-hero-info {
		margin-top: 20px !important;
	}

	.bfs-banner-container,
	.klever-trading-competition-layout,
	.klever-dustklv-layout,
	.klever-terms-of-use-layout {
		@media screen and (max-width: 1024px) {
			margin-top: 20px !important;
		}
	}
}

.klever-section-subtitle {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: bold !important;
	font-size: 24px !important;
	line-height: 24px !important;
	color: @white !important;
}

.klever-feature-card {
	padding: 10px 15px;
	margin-top: 20px;
}

.klever-feature-card-title {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 24px;
	color: @white;
	text-align: center;
	margin-bottom: 1em;
	margin-top: 20px;
}

.klever-feature-card-description {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: @white;
	text-align: center;
}

.klever-header-download {
	span {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: @white-contrast;
		margin-right: 10px;
	}

	svg {
		margin-right: 5px;
	}

	a:hover {
		svg {
			path {
				fill: @white;
			}
		}
	}

	@media screen and (max-width: 1405px) {
		span {
			display: none;
		}
	}
}

.klever-qr-app {
	width: 100%;
	max-width: 200px;
}

.klever-qr-app-security {
	width: 150px;
	height: 150px;
	background-color: @white;
}

.ant-popover-inner-content {
	background: @body-background;
}

.klever-kyc-status-pending,
.klever-kyc-status-no_id_uploaded {
	.ant-list-item-meta-description {
		color: @white-contrast !important;
	}
}

.klever-kyc-status-done {
	.ant-list-item-meta-title {
		a {
			cursor: default;

			&:hover {
				color: rgba(255, 255, 255, 0.85);
			}
		}
	}

	.ant-list-item-meta-description {
		color: @green !important;
	}
}

.klever-kyc-status-failed {
	.ant-list-item-meta-description {
		color: @red !important;
	}
}

.klever-mfa-status-disabled {
	cursor: pointer;
	font-size: 16px;

	.ant-list-item-meta-description {
		color: @white-contrast !important;
	}
}

.klever-mfa-status-enabled {
	.ant-list-item-meta-title {
		cursor: initial !important;

		&:hover {
			color: rgba(255, 255, 255, 0.85);
		}
	}

	.ant-list-item-meta-description {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: #37dd72;
	}
}

.klever-token-listing-box {
	border: 1px solid #424665;
	padding: 20px 15px 0px 15px;
	border-radius: 8px;

	li {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: @white-contrast;
		padding-bottom: 10px;
	}
}

.klever-token-list-iframe {
	margin-top: 20px;

	iframe {
		border-radius: 8px;
	}
}

.klever-apikeys-required-fields {
	font-size: 0.8rem;
	font-style: italic;
	margin: -10px 0 10px 0;
	color: @white-contrast;
}

.klever-apikey-modal {
	margin-top: -35px;

	.ant-modal-content {
		padding: 0 15px;
		background-color: @secondary-color;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @secondary-color;
		border-bottom: none !important;
	}

	.ant-modal-body {
		background: @secondary-color;
		padding: 10px 5px 25px 5px;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		color: @white;
		padding-top: 10px;
	}

	.klever-close-btn {
		margin-top: 15px;
	}

	label {
		font-weight: bold;
		font-size: 12px;
		line-height: 12px;
		color: @white;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.ant-empty-small {
		display: none;
	}

	.ant-switch {
		margin-bottom: 10px;
	}
}

.klever-apikey-select {
	.ant-select-selector {
		background: transparent;
		border-radius: 8px !important;
		border: 1px solid @white-contrast !important;
		width: 100%;
		margin: 5px 0 15px 0;
		padding: 5px;
	}

	.ant-select-selection-placeholder {
		color: @white-contrast;
	}
}

.klever-api-item-title {
	> .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: @secondary-color !important;
	}
}

.klever-api-item-title b {
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: @white;
	margin-bottom: 10px;
}

.klever-api-edit-btn {
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: @white-contrast;
}

.klever-api-delete-btn {
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #f43942;

	&:hover {
		background: transparent;
	}
}

.klever-apiKey-ip-input {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 15px 0;
	height: 42px;

	input {
		color: @white-contrast !important;
	}
}

.klever-nft-logo-container {
	max-width: 138px;
	display: flex;
	align-items: center;
	margin-right: 12px;
}

.klever-nft-logo {
	width: 138px;
	margin: 0 12px;
}

.klever-nft-img-container {
	padding: 24px 4vw;
	width: 100%;
	height: 100%;
	border-radius: 16px;

	.ant-col {
		overflow: hidden;
	}

	.ant-row {
		height: 100%;
		border-radius: 16px;
	}

	iframe {
		box-shadow: 10px 10px 15px #111224ab;
		overflow: auto;
		border-radius: 16px;
		width: 100%;
		height: 100%;
	}

	.viewer-img {
		box-shadow: 10px 10px 15px #111224ab;
		overflow: auto;
		border-radius: 16px;
		max-height: 60vh;
	}
}

.klever-asset-header {
	h3 {
		font-weight: bold;
		font-size: 32px;
		line-height: 40px;
		color: @white;
		margin-bottom: 0;
	}

	span {
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: @white-contrast;
	}

	.klever-asset-share-menu {
		cursor: pointer;
		margin-top: 15px;
	}
}

.klever-share-list-menu-container {
	background: @secondary-color;
	padding: 16px 16px 5px 16px;
	margin-right: 10px;
	color: @white-contrast;
	font-weight: bold;
	width: 140px;

	.klever-share-list-menu-item {
		cursor: pointer;
		display: flex;
		align-items: flex-start;
		margin: 5px 0;

		p {
			margin-left: 10px;
			margin-top: -3px;
		}
	}

	.klever-share-list-menu-item:hover {
		opacity: 0.7;
	}
}

.klever-asset-container {
	padding: 0 20px;
	height: 85vh;
	overflow: auto;
	padding-bottom: 200px;
}

.klever-asset-content {
	margin-top: 20px;

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: @white-contrast;
		margin-right: 10px;

		span {
			background: -webkit-linear-gradient(@primary-color, @primary-gradient-color);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.klever-asset-user {
		margin-top: 10px;

		h5 {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: @white;
			margin-left: 10px;
			margin-bottom: 0;
		}

		.klever-verified-icon {
			position: absolute;
			left: 28px;
			top: 32px;
			z-index: 10;
		}
	}

	.klever-asset-tabs-container {
		margin-top: 20px;

		.klever-asset-tabs {
			width: 100%;

			.ant-tabs-nav::before {
				border: solid 2px @body-background !important;
			}

			.ant-tabs-nav {
				border-bottom: 1px solid #343855;
				margin: 0 0 10px 0;
			}

			.ant-tabs-tab {
				background: transparent !important;
				border: none !important;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: @white-contrast;
				border-bottom: solid 2px transparent !important;
			}

			.ant-tabs-tab-active {
				background: @body-background !important;
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
				border-bottom: solid 2px @primary-color !important;
			}

			.ant-tabs-tab-active .ant-tabs-tab-btn {
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: @white !important;
			}
		}
	}

	.klever-asset-metadata-tab {
		padding: 0 5px;

		.klever-asset-metadata-tab-rows {
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				margin-right: 0px;
			}

			p:first-child {
				flex: 1;
				margin-right: 10px;
			}

			.klever-asset-metadata-tab-buttons {
				padding: 0;
				margin: -12px 0 0 14px;
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
			}

			.klever-asset-metadata-tab-buttons:hover {
				background: transparent;
			}

			.klever-asset-metadata-tab-dividers {
				margin: -12px 0 0 14px;
			}
		}

		.klever-asset-metadata-tab-refresh-button {
			display: flex;
			align-items: center;

			span {
				margin-left: 10px;
				color: @white-contrast;
				font-weight: 500;
			}
		}
	}

	.klever-asset-details-tab-container {
		display: flex;
		flex-wrap: wrap;

		.klever-asset-details-tab-card {
			padding: 5px 10px;
			background-color: @secondary-color;
			width: fit-content;
			border-radius: 4px;
			margin: 5px 10px 5px 0;

			p {
				margin: 3px;
				font-size: 14px;
			}

			p:first-child {
				font-size: 12px;
				color: @white-contrast;
			}
		}
	}

	.klever-asset-tab-content {
		overflow: auto;

		.ant-menu-vertical {
			background: transparent;
			border: none !important;
		}

		.ant-menu-item {
			height: 60px !important;
			background-color: transparent !important;
			cursor: default;
		}

		small {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: @white-contrast;
			margin-left: 10px;
			margin-top: 5px;
		}

		.klever-buy-btn {
			height: 40px;
			background: @primary-color;
			border-radius: 8px;

			span {
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: @white;
			}
		}

		.klever-owner-row {
			flex: 1;
		}
	}

	.klever-asset-place-bid {
		background: @secondary-color;
		backdrop-filter: blur(24px);
		position: fixed;
		bottom: 0;
		padding: 5px 0 15px 20px;
		width: 100%;
		z-index: 11;
		border-radius: 8px;

		h6 {
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: @white-contrast;
			margin-left: 10px;
		}

		span {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: @white;
			white-space: nowrap;
		}

		small {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			color: @white-contrast;
		}

		strong {
			margin-left: 10px;
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: @white;
		}

		.klever-asset-user {
			width: 100%;
		}

		.klever-bid-row {
			padding-right: 20px;
		}

		.klever-btn-place-bid {
			height: 54px;
			background: @body-background;
			border-radius: 8px;
			margin-right: 20px;

			span {
				padding-left: 20px;
				padding-right: 20px;
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: @white;
			}
		}

		.klever-btn-buy {
			height: 54px;
			background: @primary-color;
			border-radius: 8px;

			span {
				padding-left: 59px;
				padding-right: 59px;
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: @white;
			}
		}

		.klever-bid-btns {
			padding: 20px 0;
		}
	}
}

.klever-collection-grid {
	display: grid !important;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.klever-collection-banner {
	border-radius: 24px;
	width: 100%;
	height: 375px;
	background-repeat: no-repeat;
	background-size: cover;
}

.klever-collection-owner-box {
	height: min-content;
	padding: 20px;
	margin: -200px 40px 15px 40px;
	background: @secondary-color;
	border-radius: 16px;
	top: 43%;
	position: relative;
	width: fit-content;
	max-width: 400px;

	.klever-verified-icon {
		top: 100px;
		left: 105px;

		img {
			width: 25px;
			height: 25px;
		}
	}

	b {
		font-weight: bold;
		font-size: 18px;
		line-height: 16px;
		color: @white;
		margin-left: 25px;
		margin-bottom: 5px;
	}

	.klever-collection-address-badge {
		background: @secondary-color !important;
		border-radius: 24px;
		padding: 0 10px;
		margin-left: 5px;
		height: 26px;
		overflow-x: hidden;
		margin-top: 10px;

		small {
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: @white-contrast;
		}
	}

	p {
		padding: 25px 0 5px 0;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: @white-contrast;
	}

	.klever-collection-twitter {
		margin-bottom: 30px;

		a {
			margin-left: 8px;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			color: @white-contrast !important;
		}
	}
}

.klever-no-nfts {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	p {
		margin-top: 5px;
		text-align: center;
	}
}

.by-trading-view {
	position: absolute;
	right: 0;
	bottom: -28px;
	z-index: 10;

	p {
		font-size: 10px;
		color: @white-contrast;
		background: @secondary-color;
		padding: 2px 5px;
		border-radius: 2px;

		a {
			color: @white-contrast !important;
			font-weight: bold;
		}
	}
}

.klever-trending-assets {
	padding: 20px 20px 15px 0;

	h3 {
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		color: @white;
		margin-bottom: 15px;
	}
}

.selected {
	border: 1px solid @primary-color !important;
}

.klever-asset-card {
	cursor: default;
	background: @secondary-color;
	border-radius: 16px;
	max-width: 250px;
	padding: 10px;
	margin: 0 10px 20px 10px;
	border: 1px solid @secondary-color;

	.ant-card-cover {
		width: 100%;
		height: 250px;
		background: @secondary-color;
		border-radius: 12px;

		a {
			width: 100%;
			height: 100%;
		}

		div {
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 75%;
			background-position-x: center;
			border-radius: 12px;
			width: 100%;
			height: 100%;
		}
	}

	.ant-card-body {
		padding: 24px 5px 15px 5px;

		.ant-card-meta-title a {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: @white-contrast;
		}

		.ant-card-meta-description {
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				color: @white-contrast;
			}

			small {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				color: @white-contrast;
			}

			strong {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				background: -webkit-linear-gradient(#d96200, #ff8f33);
				background-clip: border-box;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.ant-row-space-between {
				margin-bottom: 5px;
			}
		}
	}

	.klever-btn-buy {
		height: 40px;
		background: @primary-color;
		border-radius: 8px;
		width: 100%;
		margin: 15px 0 0 0;

		span {
			font-weight: bold;
			font-size: 14px;
			line-height: 22px;
			color: @white;
		}
	}

	.klever-btn-buy:hover {
		background: #d96200a1;
	}

	.klever-btn-buy:disabled {
		opacity: 0.6;
	}

	.klever-transparent-btn {
		background-color: transparent;

		span {
			color: @white-contrast;
		}
	}

	.klever-transparent-btn:hover {
		background-color: #2c2c2c45 !important;
	}

	.klever-asset-media-box {
		background: @secondary-color;
		border-radius: 12px;
	}
}

.klever-claim-nft-btn,
.klever-claim-nft-btn-page {
	width: 100%;
	border-radius: 6px;
}

.klever-claim-nft-btn-page {
	height: 54px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-card-bordered.klever-asset-card:hover {
	border: 1px solid @primary-color;
	transition: border 0.3s ease;
}

.btn-bordered {
	border: 1px solid @white-contrast;
	color: @white-contrast;
	transition: border 0.3s ease;
}

.btn-bordered:focus,
.btn-bordered:focus-visible,
.btn-bordered:active,
.btn-bordered:visited {
	background: @secondary-color !important;
	border: 1px solid @white-contrast;
	color: @white-contrast !important;
}

.klever-properties-select-options-1 {
	flex: 1;
	text-align: right !important;
}

.klever-properties-select-options-2 {
	flex: 2;
	text-align: left !important;
}

.klever-properties-painel-header {
	width: 95%;
}

.klever-properties-select {
	min-width: 200px;
	height: 100%;
	display: flex;

	.ant-select-selector {
		display: flex;
		justify-content: space-between;
		height: 100% !important;
		font-size: 14px;
		line-height: 22px;
		background: @secondary-color;
		border-radius: 8px;
		border: none !important;
		margin: 7px 0px;

		.ant-select-selection-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 2px;
		}

		.ant-select-selection-item:first-child {
			flex: 2;
		}

		.ant-select-selection-item:last-child {
			flex: 1;
		}
	}

	.ant-select-selector::selection {
		border: none !important;
	}

	.ant-select-selection-item {
		font-weight: bold;
		font-size: 14px;
		line-height: 40px;
		color: @white-contrast;
	}

	.ant-select-arrow {
		color: @white-contrast !important;
	}
}

.klever-properties-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;

	button {
		height: 40px;
		border-radius: 8px;
		width: 100%;
	}

	.klever-btn-properties-clear {
		background: @secondary-color;
	}

	.klever-btn-properties-apply {
		background: @primary-color;
	}
}

.klever-collection-content {
	.klever-collection-tabs {
		.ant-tabs-content-holder {
			padding-top: 0;
		}

		.ant-tabs-nav::before {
			border: solid 2px @body-background !important;
		}

		.ant-tabs-nav {
			// margin: 0 30px 10px 30px;
			max-width: 1440px;
			margin: auto;
			width: 100%;
			padding-left: 40px;
		}

		.ant-tabs-tab {
			background: transparent !important;
			border: none !important;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: @white-contrast;
		}

		.ant-tabs-tab-active {
			background: @body-background !important;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			border-bottom: solid 2px @primary-color !important;
		}

		.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: @white !important;
		}

		.ant-tabs-content {
			padding: 0 40px;
			max-width: 1440px;
			margin: auto;
		}

		.ant-tabs-content .ant-tabs-content-top {
			padding: 0 40px;
			max-width: 1440px;
			margin: auto;
		}

		.klever-table-search {
			width: 270px;
			height: 42px;
			margin-bottom: 0;
		}
	}

	.klever-filter-select {
		min-width: 200px;
		margin: 5px;

		.ant-select-selector {
			height: 40px;
			background: @secondary-color;
			border-radius: 8px;
			border: none;
		}

		.ant-select-selection-item {
			font-weight: bold;
			font-size: 14px;
			line-height: 40px;
			color: @white-contrast;
		}

		.ant-select-arrow {
			color: @white-contrast !important;
		}
	}

	.klever-btn-filter {
		background: @secondary-color;
		border-radius: 8px;
		margin: 5px;
		height: 40px;

		.filter-selected {
			border-radius: 50%;
			background: @primary-color;
			padding: 0px 7px;
			margin-left: 10px;
			color: @white;
		}

		span {
			font-weight: bold;
			font-size: 14px;
			line-height: 22px;
			color: @white-contrast;
		}

		img {
			margin-right: 10px;
		}
	}
}

.klever-collection-filters {
	padding: 0px 35px;
}

.klever-profile-layout {
	.klever-collection-owner-box {
		margin-top: 0 !important;

		.ant-avatar {
			background-color: @secondary-color;
		}
	}
}

.klever-modal-properties-modal {
	.ant-modal-content {
		padding: 10px 20px;
		background-color: @secondary-color;
		border-radius: 8px;
		max-width: 100%;
		margin: -40px auto;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @secondary-color;
		border-bottom: none !important;
	}

	.coming-soon {
		color: #7b7db2 !important;
		margin: 10px;
	}

	.ant-modal-body {
		background: @secondary-color;
		max-height: 400px;
		overflow-x: auto;
		padding: 5px;

		p {
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			color: @white-contrast;
			margin-bottom: 30px;
		}

		b {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: right;
			color: @white-contrast;
		}

		span {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: right;
			color: @white-contrast;
		}

		strong {
			font-family: 'Noto Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #777e91;
		}

		.klever-buy-prices {
			margin-bottom: 20px;

			.ant-row-space-between {
				margin-bottom: 12px;
			}
		}

		.ant-divider {
			margin: 15px 0;
			border-top: 1px solid #353945;
		}
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 32px;
		line-height: 40px;
		color: @white;
	}

	label {
		font-weight: bold;
		font-size: 12px;
		line-height: 12px;
		color: #7b7db2;
		margin-bottom: 10px;
		margin-right: 10px;
	}
}

.klever-modal-properties-modal-light {
	.ant-modal-content,
	.ant-modal-header,
	.ant-modal-body {
		background-color: @white;

		.ant-modal-title,
		.ant-modal-close,
		p {
			color: @white-contrast;
		}

		p {
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 30px;
		}
	}
}

.klever-buy-nft-modal,
.klever-sale-nft-modal {
	.ant-modal-content {
		padding: 10px 20px;
		background-color: @secondary-color;
		border-radius: 8px;
		max-width: 448px;
		margin: -40px auto;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @secondary-color;
		border-bottom: none !important;
	}

	.ant-modal-body {
		background: @secondary-color;
		padding: 10px 5px 25px 5px;

		p {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #fcfcfd;
			margin-bottom: 30px;
		}

		b {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: right;
			color: #fcfcfd;
		}

		span {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: right;
			color: #fcfcfd;
		}

		strong {
			font-family: 'Noto Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #777e91;
		}

		.klever-buy-prices {
			margin-bottom: 20px;

			.ant-row-space-between {
				margin-bottom: 12px;
			}
		}

		.ant-divider {
			margin: 15px 0;
			border-top: 1px solid #353945;
		}
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 32px;
		line-height: 40px;
		color: @white;
	}

	.klever-close-btn {
		margin-top: 20px;
	}

	label {
		font-weight: bold;
		font-size: 12px;
		line-height: 12px;
		color: #7b7db2;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.ant-empty-small {
		display: none;
	}

	.ant-switch {
		margin-bottom: 10px;
	}

	.klever-buy-buttons {
		.klever-btn-buy {
			background: @primary-color;
			border-radius: 8px;
		}

		.klever-btn-cancel {
			background: @secondary-color;
			border-radius: 8px;
		}

		button {
			width: 100%;
			margin-top: 15px;
			height: 54px;

			span {
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				color: @white;
			}
		}
	}
}

.klever-alert {
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 15px;

	.klever-alert-content {
		max-width: calc(100% - 50px);
	}

	img {
		margin-right: 15px;
	}

	b {
		font-weight: bold !important;
		font-size: 16px !important;
		line-height: 24px !important;
		text-align: left !important;
	}

	span {
		font-weight: normal !important;
		font-size: 12px !important;
		line-height: 20px !important;
		text-align: left !important;
	}
}

.klever-alert-error {
	background: rgba(239, 70, 111, 0.08);

	b,
	span {
		color: #ef466f !important;
	}
}

.klever-alert-warning {
	background: rgba(255, 227, 128, 0.08);

	b,
	span {
		color: #ffe380 !important;
	}
}

.klever-sale-nft-modal {
	.ant-modal-body {
		strong {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: @white-contrast;
		}

		input {
			border: none !important;
			-webkit-appearance: none;
			-moz-appearance: textfield;
			padding-left: 0px;
			box-shadow: none;
		}

		input::placeholder {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: @white-contrast;
		}

		.klever-info-content {
			strong {
				padding-left: 10px;
				width: 80%;
			}

			margin-bottom: 20px;
		}
	}
}

// >>>>>>>>> MEDIA QUERIES <<<<<<<<<
// please, keep @media on the end of this file

@media only screen and (max-width: 576px) {
	#chartdiv {
		canvas:first-child {
			left: 7px !important;
		}
	}
}

@media only screen and (min-width: 1480px) {
	.klever-orderbook-table-container-both {
		height: 46vh;
		min-height: 155px;
		overflow-y: auto;
	}
}

@media only screen and (min-width: 1440px) {
	.klever-orderbook-table-container-both {
		height: 46vh;
		min-height: 155px;
		overflow-y: auto;
	}

	.klever-carousel-img-card,
	.klever-carousel-title-card {
		width: 300px;
	}
}

@media only screen and (min-width: 1242px) {
	.klever-home-img {
		max-width: 900px;
	}
}

@media only screen and (min-width: 1200px) {
	.klever-orderbook-table-container-both {
		height: 37vh;
		min-height: 155px;
		overflow-y: auto;
	}
}

@media only screen and (min-width: 992px) {
	.klever-footer-section {
		padding: 40px 3vw 0 3vw !important;
	}
	.klever-footer-menu-main-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
		grid-template-rows: 1fr;
		width: 100%;
		gap: 30px;
	}
	.klever-margin-right {
		margin-right: 15px;
	}

	.klever-top-row {
		height: calc(100vh - 365px);
		min-height: 430px;
		margin-top: 5px;
	}

	.klever-bottom-row {
		height: 290px;
		min-height: 290px;
		max-height: 290px;
	}

	.klever-col-top-left {
		width: calc(100vw - 570px);
	}

	.klever-col-top-right {
		height: 100%;
		width: 555px;
	}

	.klever-col-bottom-left {
		background: @body-background;
		border-radius: 0;
		height: 100%;
		width: calc(100vw - 570px);
	}

	.klever-col-bottom-right {
		background: @body-background;
		border-radius: 0;
		width: 555px;
	}

	.klever-orderbook-table-container {
		height: 23vh;
		min-height: 150px;
		overflow-y: auto;
		// scrollbar-width: thin;
	}

	.klever-orderbook-table-container-both {
		height: 46vh;
		min-height: 150px;
		overflow-y: auto;
		// scrollbar-width: thin;
	}
}

.klever-connect-content {
	align-items: center;
	display: flex;
	justify-content: center;
	overflow-x: hidden;

	.klever-disconnect-btn {
		background: @secondary-color;
		justify-content: flex-start;
		height: 40px;
		align-items: center;
		display: flex;
		margin: 5px;
		width: 100%;
		height: 60px;
		padding: 5px;
		size: 16;

		span {
			color: @white-contrast;
		}

		svg {
			margin: 0px 15px;
		}
	}

	.klever-connect-btn {
		left: 0px;
		top: 0px;
		background: @secondary-color;
		border-radius: 8px;
		align-items: center;
		display: flex;
		margin: 5px;
		color: @white;
		width: 100%;
		height: 65px;
		padding: 5px;
		overflow-x: hidden;

		.coming-soon {
			color: @white-contrast;
			font-size: 14px;
		}

		span {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			color: @white;
			margin-left: 5px;
		}

		svg {
			border-radius: 40px;
			margin: 10px 5px 5px 5px;
		}

		&:hover {
			background: @body-background;
		}
	}

	.klever-connect-btn[disabled]:hover {
		background: @secondary-color;
		opacity: 0.6;
	}

	.klever-connect-btn[disabled] {
		opacity: 0.6;

		.coming-soon {
			color: @white-contrast;
			font-size: 14px;
		}

		span {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			color: @white;
			margin-left: 5px;
		}
	}
}

.klever-header-container {
	.klever-table-search {
		width: 220px;
	}

	.klever-connect-btn {
		height: 40px;
		left: 0px;
		top: 0px;
		background: @secondary-color;
		border-radius: 8px;
		align-items: center;
		display: flex;
		margin-right: 15px;
		border: none;

		span {
			font-weight: bold;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			color: @white-contrast;
			margin-left: 5px;
		}
	}
}

.klever-kyc-status-kyc_pending,
.klever-kyc-status-kyc_need_id_upload,
.klever-kyc-status-kyc_unknown {
	.ant-list-item-meta-description {
		color: @white-contrast !important;
	}
}

.klever-kyc-status-kyc_approved {
	.ant-list-item-meta-title {
		a {
			cursor: default;

			&:hover {
				color: rgba(255, 255, 255, 0.85);
			}
		}
	}

	.ant-list-item-meta-description {
		color: @green !important;
	}
}

.klever-kyc-status-kyc_error {
	.ant-list-item-meta-description {
		color: @red !important;
	}
}

@media only screen and (min-width: 1392px) {
	.klever-hide-desktop {
		display: none;
	}

	.klever-header {
		padding: 0 40px 0 40px;
	}
}

@media only screen and (min-width: 1090px) {
	.klever-hide-desktop-trade {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.klever-orderbook-table-container-both {
		height: 47vh;
		min-height: 150px;
		overflow-y: auto;
	}
}

@media only screen and (min-width: 600px) {
	.klever-orderbook-table-container-both {
		min-height: 150px;
		overflow-y: auto;
	}
}

@media only screen and (max-width: 1440px) {
	.klever-site-layout,
	.klever-home-layout,
	.klever-holdings-layout,
	.klever-account-layout,
	.klever-deposits-layout,
	.klever-api-layout,
	.klever-asset-layout,
	.klever-collection-layout,
	.klever-profile-layout,
	.klever-holdings-layout-2 {
		margin: inherit;
	}
}

@media only screen and (max-width: 1392px) {
	.hidden-1300 {
		display: none;
	}
}

@media only screen and (max-width: 1400px) {
	.klever-hide-tablet {
		display: none;
	}
}

@media only screen and (max-width: 1242px) {
	.klever-home-img {
		width: 50vw;
	}
}

@media only screen and (max-width: 1028px) {
	.klever-orderbook-circle {
		width: 14px;
		height: 14px;
	}

	.klever-orderbook-circle-left,
	.klever-orderbook-circle-right {
		width: 7px;
		height: 14px;
	}
}

@media only screen and (max-width: 1024px) {
	.klever-home-img-gradient {
		margin-top: 50px;
	}

	.klever-home-title-container {
		padding: 0;
	}

	.klever-carousel-img-card {
		max-height: 330px;
	}

	.klever-carousel-img-card,
	.klever-carousel-title-card {
		width: 80vw !important;
	}

	.klever-home-twitter-title {
		font-size: 100px !important;
	}

	.klever-twitter-section {
		padding: 0px 5vw 100px 5vw !important;
	}

	.klever-twitter-account {
		font-size: 16px !important;
	}

	.klever-verified-blue {
		width: 10px !important;
	}

	.klever-twitter-logo {
		width: 30px;
	}

	.klever-twitter-margin {
		margin-bottom: 50px !important;
		margin-left: -5px !important;
		margin-top: -90px;
	}

	.klever-faq-margin {
		margin-top: -90px;
	}

	.klever-coin-img-1,
	.klever-coin-img-2,
	.klever-coin-img-3,
	.klever-coin-img-4 {
		display: none;
	}
}

@media only screen and (max-width: 1204px) {
	.klever-new-badge:before {
		content: '';
		position: absolute;
		top: 16px;
		left: 10px;
		width: 0;
		height: 0;
		border-top: 4px solid transparent;
		border-right: 8px solid #e8469c;
		border-bottom: 4px solid transparent;
	}
}

@media only screen and (max-width: 1080px) {
	.klever-collection-layout {
		#klever-collection-devikin {
			.klever-collection-list-owner-box {
				.klever-collection-top-container-list {
					.ant-row {
						.ant-avatar {
							top: 25px;
						}

						.anticon {
							left: 65px;
						}
					}
				}
			}
		}
	}

	.marketplace-collection-list-carousel {
		.rec-carousel {
			.rec-slider-container {
				.rec-slider {
					.rec-swipable {
						.rec {
							.rec-item-wrapper {
								height: 200px !important;
							}
						}
					}
				}
			}
		}

		.rec-pagination {
			margin-top: 0px !important;
		}
	}

	#marketplace-collection-list-trending-collections-title {
		margin-top: 60px !important;
	}
}

@media only screen and (max-width: 992px) {
	.vip-klv-volume-card {
		width: 100% !important;
		margin: 10px 0 0 0 !important;
	}

	.vip-status-col-2 {
		align-self: self-end !important;
		display: flex;
		align-items: center;
	}

	.vip-status {
		font-size: 30px !important;
	}

	.vip-status {
		margin-bottom: 0 !important;
		margin-top: 28px !important;
	}

	.vip-status-card {
		width: 100% !important;
		background-size: cover;
	}

	.klever-tradingview-container {
		margin-bottom: 10px;
	}

	.klever-row-mobile {
		margin-top: 15px;
	}

	.klever-bottom-row {
		margin-top: 5px;
		padding-bottom: 50px;
	}

	.klever-col-top-right {
		margin-top: 5px;
		width: 100%;
	}

	.klever-col-top-left {
		width: 100%;
	}

	.klever-col-bottom-left {
		width: auto;
	}

	.klever-col-bottom-right {
		width: auto;
	}

	.klever-orderbook-table-container {
		height: 225px;
		overflow: auto;
	}

	.klever-orderbook-table-container-both {
		height: 46vh;
		overflow: auto;
	}

	.klever-pairs-tabs {
		margin-top: 5px;
		background: @body-background;
		border-radius: 0;
	}

	.klever-trading-currency {
		padding-left: 10px;
		padding-right: 10px;
	}

	.klever-app-img {
		width: 70vw !important;
		margin-left: 0;
	}

	.by-trading-view {
		bottom: -10px;
	}
}

@media only screen and (max-width: 1392px) {
	.holdings-filter-nft-in-marketplace-mobile {
		width: 270px !important;
		margin: 10px auto !important;
	}

	.holdings-button-filter-collection-mobile {
		max-width: fit-content;
		margin: 15px auto 15px;
		border-radius: 8px;
	}

	.klever-hide-mobile {
		display: none;
	}
}

@media only screen and (max-width: 1090px) {
	.klever-hide-mobile-trade {
		display: none;
	}
}

@media only screen and (min-width: 1392px) {
	.klever-hide-mobile {
		display: inline;
	}
}

@media only screen and (min-width: 1090px) {
	.klever-hide-mobile-trade {
		display: inline;
	}
}

@media only screen and (max-width: 768px) {
	.klever-app-promo-content {
		padding-left: 10px !important;
	}

	.klever-deposits-recent {
		padding: 15px 5px;
	}

	.klever-deposits-content {
		margin: 5px;
	}

	.klever-container-white,
	.klever-withdraw-container-right {
		margin: 27px 0 10px 0px;
	}

	.klever-home-space {
		padding-left: 4vw;
		padding-right: 4vw;
	}

	.klever-app-section {
		padding-left: 4vw !important;
		padding-right: 4vw !important;
	}

	.klever-collection-tabs {
		.ant-tabs-nav {
			display: block;

			.ant-tabs-nav-operations {
				display: none;
			}

			.ant-tabs-extra-content {
				.ant-row-end {
					justify-content: start;
					padding-top: 15px;

					.ant-btn,
					.klever-table-search {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.marketplace-collection-list-offercard {
		width: 165px !important;
		margin: 5px !important;

		.ant-card-body {
			padding: 0 4px 4px 4px !important;

			.ant-btn-primary {
				font-size: 12px;
				font-weight: 600;
			}

			.ant-card-meta {
				.ant-card-meta-detail {
					.ant-card-meta-description {
						.marketplace-collection-list-offercard-first-description {
							small {
								font-size: 10px !important;
							}

							strong {
								font-size: 11px !important;
							}
						}

						.marketplace-collection-list-offercard-second-description {
							flex-wrap: nowrap;
							overflow-x: hidden;

							small {
								font-size: 10px !important;
							}

							strong {
								font-size: 11px !important;
							}
						}
					}
				}
			}
		}

		.ant-card-cover {
			height: 175px !important;

			.ant-card-meta-detail {
				.ant-card-meta-description {
					.marketplace-collection-list-offercard-first-description {
						small {
							font-size: 10px;
						}
					}
				}
			}
		}
	}

	.klever-orderbook-table-container-both {
		height: 36vh;
		min-height: 150px;
		overflow-y: auto;
	}
	.klever-tweet-card {
		width: 300px !important;
	}

	.klever-tweet-content {
		font-size: 16px !important;
	}

	.klever-footer-section .klever-social-links li a {
		width: 36px !important;
		height: 36px !important;
	}

	.klever-coin-icon {
		margin-left: 5px !important;
	}

	.klever-faq-title {
		font-size: 36px !important;
	}

	.klever-faq-subtitle {
		font-size: 20px;
	}
}

.spin-loader {
	padding-top: 50%;
}

@media only screen and (max-width: 575px) {
	#marketplace-collectionlist-layout {
		padding: 0 20px;
	}

	.klever-collection-list-banner-skeleton {
		width: 343px !important;
		height: 205px !important;
		margin: auto !important;

		.ant-skeleton {
			top: 116px !important;
			left: 15px !important;
		}
	}

	.marketplace-collection-list-recent-offers-title {
		margin-top: 60px;
	}

	.klever-collection-layout {
		#klever-collection-devikin {
			.klever-collection-list-owner-box {
				right: 10%;

				.klever-collection-top-container-list {
					.ant-row {
						.ant-avatar {
							top: 42px;
						}

						.anticon {
							top: 95px;
						}

						#owner-box-texts {
							font-size: 13px;
							top: 44px;
						}
					}
				}
			}
		}
	}

	#klever-collection-devikin {
		width: 343px;
		height: 205px;
		margin: auto;
	}

	.spin-loader {
		padding-top: 40%;
	}

	.button-holdings-deposit-nft-mobile {
		margin: 0 auto 10px;
	}

	.holdings-button-in-wallet-mobile {
		width: 150px;
		margin-left: 5px;
	}

	.klever-holdings-spot-balance {
		justify-content: center;
	}

	.ant-layout-footer {
		padding: 10px 15px;
	}

	.skeleton-layout {
		justify-content: center;
		width: 100%;
		margin: 10px;
	}

	.klever-collection-grid {
		grid-template-columns: repeat(auto-fill, minmax(45vw, 1fr));
	}

	.klever-space-right {
		padding-right: 0 !important;
	}

	.klever-trades-tabs-container {
		padding-top: 10px !important;
	}

	.klever-tradelist-container {
		margin-top: 5px;
	}

	.klever-table-search {
		width: 150px;
	}

	.pairs-table-search {
		width: 100%;
	}

	.klever-orderbook-table-container {
		height: 150px;
		overflow: auto;
	}

	.klever-orderbook-table-container-both {
		height: 300px;
		overflow: auto;
	}

	.klever-collection-owner-box {
		top: 1vh;
		max-height: 65vh;
		max-width: 92vw;
	}

	.klever-collection-banner {
		display: none;
	}

	.klever-owner-item {
		justify-content: center;
	}

	.klever-owner-item-user {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		flex-direction: column;
		margin-left: 10px;
	}

	.klever-collection-address-badge,
	.klever-collection-owner-box {
		margin: 1vh auto;

		b {
			margin-left: 0 !important;
		}
	}

	.klever-collection-layout {
		padding-left: 5px;
		padding-right: 5px;
	}

	.klever-trending-assets {
		justify-content: center;
	}

	.klever-trending-assets > .ant-row-middle {
		justify-content: center;
	}

	.infinite-scroll {
		justify-content: center;

		.klever-asset-card {
			margin-left: 5px;
		}
	}

	.my-nfts-container-mobile {
		.klever-asset-card {
			margin-left: 5px;
		}
	}

	.klever-asset-layout {
		padding-top: 0;
	}

	.klever-asset-card {
		max-width: 45vw;
		padding: 5px;
		margin: 0 0 10px 0;

		.ant-card-body {
			padding: 10px 5px;

			.ant-card-meta-description {
				span {
					display: none;
				}
			}
		}

		.klever-btn-buy {
			display: none;
		}

		.ant-card-cover {
			width: 100%;
			height: 50vw;
		}

		.ant-card-meta-title {
			font-size: 14px;
		}
	}

	.klever-collection-content {
		padding-top: 20px;

		.klever-collection-tabs {
			.ant-tabs-nav {
				.ant-tabs-extra-content {
					.ant-row-end {
						justify-content: center;
					}
				}
			}

			.ant-tabs-content {
				padding: 0 10px;
				max-width: 1440px;
				margin: auto;
			}
		}
	}

	.klever-asset-place-bid {
		left: 0;

		.klever-bid-btns {
			padding: 10px 0 !important;

			.klever-transparent-btn {
				margin-left: 15px;
			}

			button {
				span {
					padding-left: 10px !important;
					padding-right: 10px !important;
				}
			}
		}

		.klever-asset-user {
			width: 45vw !important;
		}
	}
}

@media only screen and (max-height: 768px) {
	.klever-orderbook-table-container {
		height: 20vh;
	}

	.klever-orderbook-table-container-both {
		height: 38vh;
	}
}

.klever-mfa-modal-input-span-error {
	padding: 8px 16px;
	background: #ffc7c7;
	border-radius: 8px;
	margin: 8px 0px;
	width: 100%;
	display: flex;
	justify-content: center;

	span {
		color: #9e1313;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
	}
}

.klever-withdraw-input-security-error {
	background: #fff1f1;
	border-radius: 8px;
	border: 1px solid #9e1313;
	width: 100%;
	margin: 5px 0 5px 0;
	height: 42px;
	color: #9e1313;

	> .ant-input {
		color: #9e1313;
	}
}

.klever-withdraw-input-security-error::placeholder {
	color: #9e1313;
}

.klever-withdraw-enable-mfa {
	padding: 12px 16px;
	background: @secondary-color;
	border: 1px solid @secondary-color;
	border-radius: 8px;
	margin: 16px 0px;
	justify-content: center;

	div {
		p {
			padding: 0;
			margin: 0;
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			color: @white-contrast;
		}
	}

	.klever-withdraw-enable-mfa-message {
		display: flex;
		justify-content: center;
		flex-direction: row;
	}
}

@media only screen and (max-width: 1200px) {
	.klever-withdraw-enable-mfa div p {
		font-size: 11px;
	}

	.klever-withdraw-enable2fa-btn {
		font-size: 13px;
	}
}

.klever-2fa-list .ant-spin-nested-loading .ant-spin-container .ant-list-item {
	padding: 4px 0;

	h4 {
		padding-top: 0;
	}
}

.klever-2fa-restore,
.klever-2fa-backup-key {
	.ant-list-item-meta-title {
		font-family: Noto Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 24px;
		color: @white-contrast !important;
	}
}

.klever-2fa-remove {
	.ant-list-item-meta-title {
		font-family: Noto Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 24px;
		color: #f43942 !important;
	}
}

.col-white-space {
	white-space: nowrap;
}

@media only screen and (max-width: 991px) and (min-width: 769px) {
	.klever-withdraw-limits-card {
		margin: 5px 0 0 55px;
	}
}

@media only screen and (max-width: 991px) {
	.klever-login-layout {
		.klever-header-container {
			background: @body-background !important;
		}
	}
}

@media only screen and (max-width: 768px) {
	.klever-withdraw-limits-card {
		margin: 10px 5px 5px 5px;
	}
}

.klever-fixed-price-success-title {
	strong {
		font-size: 24px !important;
		color: #f4f5f6 !important;
		font-weight: bold !important;
	}
}

.klever-fixed-price-success-description,
.klever-fixed-price-success-footer {
	margin-top: 24px;
}

.klever-nft-twitter-link {
	color: @white-contrast;
	margin-left: 8px;
}

.klever-nft-description-container {
	margin-top: 16px;

	span {
		color: @white-contrast !important;
	}
}

.klever-nft-twitter-container {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}

.klever-collection-top-container {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.klever-collection-top-informations-container {
	margin-left: 10px;
	display: flex;
	align-items: left;
	flex-direction: column;
}

.klever-nft-message-remove-from-sale {
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #fcfcfd;
}

.klever-btn-remove-now {
	height: 40px;
	background: #f43942;
	border-radius: 8px;
	width: 100%;
	margin: 15px 0 0 0;

	span {
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		color: @white;
	}
}

.klever-asset-history-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 28px;
}

.klever-modal-buy-nft-subtitle {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	color: #f4f5f6;
}

.klever-modal-buy-nft-success {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	color: @green;
}

.klever-modal-buy-nft-time {
	margin-top: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #f4f5f6;
}

.klever-share-list-container {
	padding: 16px 16px 5px 16px;
	margin-right: 10px;
	font-weight: bold;
	display: flex;
	justify-content: space-around;
	cursor: pointer;

	.klever-share-list-item:hover {
		opacity: 0.3;
	}
}

.klever-asset-history-details-left {
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}

.klever-asset-history-details-right {
	display: flex;
	flex-direction: column;
}

.klever-asset-history-details-title {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #e5e5e5;
	margin-bottom: 4px;

	strong {
		color: @white;
		font-style: normal;
		font-weight: 500;
	}

	span {
		color: @white-contrast;
	}
}

.klever-asset-history-details-description {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
}

.klever-mynfts-collections-menu li.ant-menu-item {
	color: @white-contrast;
	padding: 10px 0 0 5px;
}

.klever-mynfts-collections-menu li.ant-menu-item-selected {
	color: @primary-color;
	font-weight: bold;
	background-color: transparent !important;
}

.klever-mynfts-collections-menu .ant-menu-item-group-title {
	margin: 0;
	padding: 10px 0 0 5px;
}

.klever-mynfts-main-container {
	border-right: 1px solid @white-contrast;
	flex: 0 0 max-content;
	max-width: max-content;
	min-width: min-content;
	width: max-content;
}

.klever-mobile-menu-nft-holding-collections {
	.ant-drawer-body {
		padding: 0 !important;
		background: @menu-dark-bg;
		height: 100%;
		overflow-y: scroll;
	}

	.ant-menu-sub {
		background: @menu-dark-bg !important;
	}

	.ant-drawer-header {
		background: @body-background !important;
		border: none !important;
	}

	.ant-input {
		margin: 10px 10px 0 10px;
		max-width: fit-content;
	}
}

.klever-btn-buy-disabled {
	height: 40px;
	background: @primary-color !important;
	border-radius: 8px;
	width: 100%;
	margin: 15px 0 0 0;
	opacity: 0.7;

	span {
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		color: @white;
	}
}

#components-layout-demo-top .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
	float: right;
	margin: 16px 0 16px 24px;
}

.infinite-scroll {
	display: flex;
	flex-wrap: wrap;
}

.mynft-layout {
	margin-top: 10px;
}

.klever-asset-collection {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;

	h5 {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: @white;
		margin-left: 10px;
		margin-bottom: 0;
	}

	.klever-verified-icon {
		position: absolute;
		left: 28px;
		top: 32px;
		z-index: 10;
	}
}

.skeleton-layout {
	justify-content: center;
	width: 100%;
	margin-top: 10px;
}

.loading-icon {
	font-size: 24;
}

.nfts-loaded-layout {
	width: 100%;
	align-items: center;
	padding-bottom: 17px;

	p {
		margin: 0;
	}
}

.modal-put-on-sale input::placeholder {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: @white-contrast !important;
}

.modal-put-on-sale input::-webkit-outer-spin-button,
.modal-put-on-sale input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}

.klever-pay-swap-btn-container {
	width: 100%;
	display: flex;
	padding: 28px 24px 24px 24px;
	border-radius: 8px;
	background: @secondary-color;
	align-items: center;
	justify-content: center;
	border: none;

	img {
		width: 19px;
		height: 19px;
		margin: -18px 10px 0 0;
	}

	.klever-pay-swap-btn {
		display: flex;
		flex-direction: column;
		align-items: center;

		span {
			line-height: 16px;
		}

		span:first-child {
			font-size: 14px;
		}

		span:last-child {
			font-size: 10px;
			color: #999;
			font-style: italic;
			margin-left: -10px;
		}
	}
}

.klever-asset-card-banner {
	cursor: default;
	background: @secondary-color;
	border-radius: 16px;
	max-width: 1440px;
	padding: 10px;
	margin: 0 10px 20px 10px;
	border: 1px solid @secondary-color;

	.ant-card-cover {
		width: 100%;
		height: 250px;
		background: @secondary-color;
		border-radius: 12px;

		div {
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 75%;
			background-position-x: center;
			border-radius: 12px;
			width: 100%;
			height: 100%;
		}
	}

	.ant-card-body {
		padding: 24px 5px 15px 5px;

		.ant-card-meta-description {
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				color: @white-contrast;
			}

			small {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				color: @white-contrast;
			}

			.ant-row-space-between {
				margin-bottom: 5px;
			}
		}
	}
}

.klever-connect-btn-mobile {
	left: 0px;
	top: 0px;
	background: @secondary-color;
	border-radius: 8px;
	align-items: center;
	display: flex;
	border: none;
	padding: 2px 6px;

	span {
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		color: @white-contrast;
		margin-left: 5px;
	}
}

.klever-table-search-mobile {
	border-color: @secondary-color;
	border-radius: 8px;
	width: 35px;
}

.row-mobile-holdings {
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-holdings-in-marketplace {
	margin-right: 10px;
}

.button-holdings-deposit-layout {
	z-index: 1;
	position: fixed;
	top: 91%;
	left: 60%;
	border-radius: 16px;
}

.text-span-checkbox-small-balances {
	span {
		font-size: 0.75em;
	}
}

.klever-collections-stats-show-text {
	color: @white-contrast;
	font-weight: bold;
	margin-bottom: 10px;
}

.klever-collections-stats-select {
	width: 200px;

	.ant-select-selector {
		color: @white-contrast !important;
		border: 1px solid @white-contrast !important;
		border-radius: 4px !important;
	}
}

.klever-collections-stats-name {
	padding: 10px 0;

	span {
		margin-left: 10px;
		font-family: Rubik;
		font-size: 14px;
	}
}

.klever-collections-stats-section {
	padding: 20px 0 50px 0;
}

.klever-collections-stats-load-more {
	padding: 20px 0;
	margin-left: -10px;
}

.klever-collections-stats-load-more-btn {
	background: @primary-color;
	border: none;
	border-radius: 4px;
}

.klever-collections-stats-load-more-btn:hover {
	background: @body-background;
}

.input-modal-put-on-sale {
	margin-bottom: 8px;
	background-color: @secondary-color;
	padding: 7px 15px;
	border-radius: 8px;
	height: 40px;
	border: 1px solid @white-contrast;
}

.tab-pane-collection {
	margin-top: 20px;
}

.klever-asset-properties-divider {
	margin: 0 0 10px 0;
}

.ant-pagination {
	li {
		border-radius: 8px;
	}

	.ant-pagination-item,
	.ant-pagination-item-link {
		border-color: @white-contrast;

		a {
			color: @white-contrast;
		}
	}

	.ant-pagination-prev button,
	.ant-pagination-next button {
		color: @white-contrast;
		border-radius: 8px;
	}

	.ant-pagination-item-active {
		border-color: @primary-color;

		a {
			color: @primary-color;
		}
	}

	.ant-pagination-disabled {
		.ant-pagination-item-link {
			border-color: @secondary-color;
			color: @white-contrast;
		}
	}

	.ant-select-selector {
		border-color: @white-contrast !important;
		border-radius: 8px !important;
	}

	.ant-select {
		color: @white-contrast;
	}
}

.asset-card-in-withdraw small {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
	padding-top: 28px;
	padding-bottom: 10px;
}

.klever-nft-order-history-section {
	padding: 10px 0 50px 0;

	.klever-nft-order-detail-image {
		width: 40px;
		background-size: cover;
		border-radius: 8px;
		margin-right: 10px;
	}

	.klever-nft-order-detail-image:hover {
		transform: scale(2) translateX(10px) translateY(-10px);
		transition: all 0.2s;
	}
}

.klever-nft-orders-select {
	margin-top: 5px;
	margin-bottom: 8px;
	min-width: 100px;
}

.klever-nft-orders-select .ant-select-selector {
	display: flex;
	border-radius: 8px !important;
	border: 1px solid @white-contrast !important;
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	height: 36px !important;
	width: 200%;
	color: @white-contrast;
}

.klever-nft-orders-select .ant-select-selector span {
	line-height: 35px !important;
}

.klever-nft-orders-filter-and-limit {
	margin-top: 0;
}

.margin-top-at-nft-offers {
	margin-top: 34px;
}

.klever-table-search-nft-orders {
	margin-top: 5px;
}

.klever-table-search-nft-orders .ant-input-group-addon {
	border-color: @secondary-color;
	color: @white;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	padding: 6px;
	width: 20px;
}

.klever-table-search-nft-orders .ant-input-affix-wrapper {
	border-color: @secondary-color;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 6px;
	width: 280px;
}

.klever-nft-order-pagination,
.klever-deposits-pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

.klever-nft-order-pagination .ant-pagination-item,
.klever-deposits-pagination .ant-pagination-item {
	width: 40px;
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	border-radius: 5px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.klever-nft-order-pagination .ant-pagination-prev,
.klever-nft-order-pagination .ant-pagination-next,
.klever-deposits-pagination .ant-pagination-prev,
.klever-deposits-pagination .ant-pagination-next {
	width: 40px;
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: @white-contrast;
	border-radius: 5px;
	border: none;
	padding: 5px;
}

.klever-nft-order-pagination.mini .ant-pagination-item,
.klever-nft-order-pagination.mini .ant-pagination-prev,
.klever-nft-order-pagination.mini .ant-pagination-next,
.klever-deposits-pagination.mini .ant-pagination-item,
.klever-deposits-pagination.mini .ant-pagination-prev,
.klever-deposits-pagination.mini .ant-pagination-next {
	line-height: 0;
	margin: 5px;
	height: 30px;
}

.klever-nft-order-pagination .ant-pagination-prev .ant-pagination-item-link,
.klever-nft-order-pagination .ant-pagination-next .ant-pagination-item-link,
.klever-deposits-pagination .ant-pagination-prev .ant-pagination-item-link,
.klever-deposits-pagination .ant-pagination-next .ant-pagination-item-link {
	border: none !important;
}

.klever-nft-order-pagination .ant-pagination-prev,
.klever-nft-deposits-pagination .ant-pagination-prev {
	order: 1;
}

.klever-nft-order-pagination .ant-pagination-next,
.klever-nft-deposits-pagination .ant-pagination-next {
	order: 2;
}

.klever-nft-order-pagination .ant-pagination-options,
.klever-deposits-pagination .ant-pagination-options {
	order: 3;
	margin: 5px;
	background: @secondary-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #f5f8fa;
	border-radius: 5px;
	border: none;
}

.klever-nft-order-pagination .ant-pagination-options .ant-select-selector,
.klever-deposits-pagination .ant-pagination-options .ant-select-selector {
	border: none;
}

@media only screen and (max-width: 576px) {
	.klever-nft-order-pagination,
	.klever-deposits-pagination {
		justify-content: flex-start;
		margin-top: 5px;
	}
}

.klever-order-details-modal {
	.ant-modal-content {
		padding: 0 15px;
		background-color: @body-background;
		border-radius: 8px;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @body-background;
	}

	.ant-modal-body {
		background: @body-background;
		padding: 5px 5px 20px 5px;
		color: @body-background;
	}

	.ant-modal-header {
		border-bottom: 1px solid rgba(66, 70, 101, 0.25);
	}

	.ant-modal-title {
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		color: @white;
	}

	.klever-close-btn {
		margin-top: 5px;
	}

	.klever-recent-item-head {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		color: @white;
	}

	.klever-recent-item-content {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: @white-contrast;
		max-width: 93%;
	}

	.klever-recent-item-content .ant-avatar {
		margin-right: 10px;
	}

	.klever-recent-item-content small {
		max-width: 80%;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		word-wrap: normal;
	}

	.klever-deposits-status-badge {
		margin-right: 0;
		padding-right: 0;
	}

	.ant-row-space-between {
		height: 30px;
	}

	.klever-icon-404 {
		font-size: 48px;
	}

	p {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
		padding-top: 15px;
	}

	.ant-empty-description {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: @white-contrast;
	}
}

.klever-collection-content {
	padding: 10px 0 50px 0;

	.klever-nft-order-detail-image {
		width: 40px;
		background-size: cover;
		border-radius: 8px;
		margin-right: 10px;
		border: solid 0.5px @primary-color;
	}

	.klever-nft-order-detail-image:hover {
		transform: scale(2) translateX(10px) translateY(-10px);
		transition: all 0.2s;
	}
}

.klever-collection-info-box {
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 50px;

	.ant-btn-ghost {
		border: none;
		margin-right: 10px;
		margin-bottom: 0.5em;
	}

	small {
		font-family: 'Rubik';
		font-weight: normal;
		font-size: 14px;
		color: @white-contrast;
		margin-bottom: 15px;
	}
}

.message-reset-password {
	background: @white-contrast;
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 15px;
	margin-top: 15px;
}

.klever-password-info-box {
	margin: 15px 0;

	img {
		margin-left: 10px;
		margin-right: 10px;
		height: 24px;
		width: 18px;
	}

	.input-url {
		display: flex;
		align-items: center;
	}
}

.subtitle-reset-password {
	color: @white-contrast;
	padding: 0;
}

.box-message-reset-password {
	margin-bottom: 15px;
}

.holdings-buttons-in-marketplace-in-wallet {
	flex: 1;
}

.holdings-div-search-nft-in-wallet-tab {
	flex-wrap: nowrap;
	align-items: center;
}

.klever-table-search-nft-in-wallet .ant-input-group-addon,
.klever-table-search-nft-in-wallet-mobile .ant-input-group-addon {
	border-color: @secondary-color;
	color: @secondary-color;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	padding: 6px;
	width: 20px;
}

.klever-table-search-nft-in-wallet .ant-input-affix-wrapper,
.klever-table-search-nft-in-wallet-mobile .ant-input-affix-wrapper {
	border-color: @secondary-color;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 6px;
	width: 280px;
}

.klever-table-search-nft-in-wallet-mobile > span {
	display: flex;
	justify-content: center;
}

.button-holdings-deposit-nft {
	margin: auto 10px;
}

.holdings-title-collections-nft {
	color: white;
	font-weight: bold;
}

.divider-holdings-mynft {
	margin: 0 0 10px;
}

.holdings-header-nft {
	padding: 0;
}

.transparent {
	background: transparent !important;
}

.klever-auth-layout {
	.klever-side-dark {
		padding: 0 15px 20px 15px;
		background: @body-background;
		height: 100vh;
	}

	.klever-side-light {
		padding: 15px 15px 0 15px;
		height: 100vh;
		color: @secondary-color;

		h3 {
			font-family: 'Noto Sans';
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 32px;
			color: @secondary-color;
			margin-bottom: 1em;
		}

		.klever-login-form {
			height: 100%;
			max-width: 1024px;

			.login-form-two-fields {
				margin-bottom: 24px;
			}

			.login-form,
			.login-form-two-fields {
				width: 100%;

				.ant-input {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: 0.02em;
					color: @body-background;
				}

				.ant-input-prefix {
					color: @white-contrast;
				}

				.ant-form-item-control {
					margin-bottom: 10px;
				}

				.ant-input-affix-wrapper {
					height: 48px;
					border-radius: 8px;
					border-color: @white-contrast;
				}

				.ant-checkbox-inner {
					border-color: @white-contrast;
				}

				.ant-input-affix-wrapper-focused {
					border: 1px solid @primary-color;
					border-color: @primary-color !important;
					box-shadow: none !important;
				}

				.login-form-button {
					height: 56px;
					background: @primary-color;
					border-radius: 8px;

					span {
						font-family: 'Noto Sans';
						font-style: normal;
						font-weight: bold;
						font-size: 18px;
						line-height: 24px;
						color: @white;
					}
				}

				.login-form-forgot {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: @body-background;
				}

				.ant-checkbox-wrapper,
				a {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 21px;
					color: @body-background;
				}

				p {
					font-family: 'Noto Sans';
					font-size: 16px;
					color: @secondary-color;
				}
			}

			.login-form-two-fields {
				.ant-form-item {
					width: 50%;
					margin-right: 0;
					margin-bottom: 0;

					&:first-child {
						padding-right: 5px;
					}

					&:last-child {
						padding-left: 5px;
					}
				}
			}
		}

		.klever-security-box {
			padding: 30px 20px;

			p {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 16px;
				text-align: center;
				color: @white;
				margin-bottom: 20px;
			}

			.input-url {
				display: flex;
				padding: 5px 25px;
				background: rgba(198, 199, 235, 0.4);
				border-radius: 24px;

				img {
					margin-right: 10px;
				}

				span {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					text-align: center;
					color: @white-contrast;
				}
			}
		}
	}

	.klever-gradient-bg {
		position: absolute;
		width: 100%;
		height: 50vh;
		right: 0;
		top: 8vh;
		background: linear-gradient(180deg, rgba(11, 11, 30, 0) 21.58%, @body-background 84.89%);
	}

	.klever-app-section {
		padding: 0;

		img {
			height: 35vh;
			margin: 15vh 0;
			width: 40vh;
		}
	}

	.klever-home-app-ad-content {
		text-align: center;
		margin-top: 10px;
	}
}

.button-nft-load-more {
	width: 100%;
	margin-left: 10px;
	display: flex;
	justify-content: center;
}

.nft-loading-card-size {
	font-size: 60px;
	color: @secondary-color;
}

@media only screen and (max-width: 450px) {
	.klever-auth-layout {
		.klever-side-dark {
			height: 100%;
		}

		.klever-side-light {
			padding-top: 40px;

			.klever-login-form {
				.ant-form-item {
					width: 100%;
					margin-right: 0;
					margin-bottom: 0;
				}

				.login-form-two-fields {
					margin-bottom: 0;

					.ant-form-item {
						width: 100%;
						margin-right: 0;
						margin-bottom: 0;

						&:first-child {
							padding-right: 0;
						}

						&:last-child {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

.marketplace-collections-page-pagination-top {
	margin-bottom: 20px;
}

@media only screen and (max-width: 830px) {
	.klever-dustklv-layout,
	.klever-convert-balance,
	.dustklv-button-layout-2 {
		padding: 0 5vw;
	}

	.dustklv-title {
		padding: 16px 1vw;
	}

	.klever-dustklv-head-conversion-wrapper {
		padding: 5px;
		margin-bottom: 10px;
	}

	.klever-convert-balance {
		.klever-dustklv-spot-balance {
			display: flex;
			flex-direction: column;
		}

		.klever-spot-balance-timer,
		.dustklv-button-convert {
			margin: 10px 0 10px 16px;
		}
	}
}

.vip-status-card {
	background-image: url('https://res.cloudinary.com/bitcoin-me/image/upload/v1690816430/Vip_ylzquh.svg');
	height: 124px;
	background-repeat: no-repeat;
	background-size: cover;
	width: 49.5%;
	left: 32px;
	top: 141px;
	border-radius: 8px;
	padding: 10px 20px;
	justify-content: space-between;
}

.vip-status-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: @white;
}

.vip-status {
	font-family: Rubik;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: 47px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 15px;
}

.vip-status-card-col-2-p {
	font-family: Rubik;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: right;
	margin-top: 3px;
}

.vip-status-col-1 {
	align-content: space-between;
}

.vip-status-col-2 {
	display: flex;
	align-items: center;

	a {
		p {
			color: @white !important;
			margin: 0;
			padding: 0;
			animation: all 0.3s ease-in;

			&:hover {
				opacity: 0.8;
				animation: all 0.3s ease-in;
			}
		}
	}
}

.vip-trading-volume-card {
	background-image: url('https://res.cloudinary.com/bitcoin-me/image/upload/v1690815073/letterT_wfxrtw.svg');
	background-position-x: 10px;
	background-position-y: 20px;
	background-repeat: no-repeat;
	background-blend-mode: soft-light;
	background-color: @secondary-color;
	padding: 10px 20px;
	width: 405px;
	height: 124px;
	left: 501px;
	top: 141px;
	border-radius: 8px;
}

.vip-klv-volume-card {
	background-color: @secondary-color;
	padding: 10px 20px;
	width: 49.5%;
	height: 124px;
	border-radius: 8px;
}

.vips-banners {
	justify-content: space-between;
	width: fit-content;
	align-items: flex-end;
	width: 100%;
}

.vip-explained-card {
	padding: 10px 20px;
	width: 100%;
	margin: 0;
	background-color: @secondary-color;
	border-radius: 8px;
}

.vip-klv-holding-layout-1 {
	width: 100%;
	align-items: baseline;
}

.vip-klv-holding-layout-2 {
	width: 100%;
	justify-content: space-between;
}

.vip-klv-holding-card-limit {
	background: -webkit-linear-gradient(45deg, @primary-color, @primary-gradient-color);
	background-clip: border-box;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	align-content: center;
}

.vip-klv-holding-progress {
	.ant-progress-bg {
		background: linear-gradient(114.7deg, @primary-color 31.5%, @primary-gradient-color 100%);
	}
}

.text-holding-total-klv {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: @white-contrast;
}

.vip-progress-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 18px;
	color: @white-contrast;
}

h5.ant-typography,
.ant-typography h5 {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 21px;
	color: @white;
}

.vip-explained-li-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: @white-contrast;
}

.klever-fee-sub-card {
	background-image: url('https://res.cloudinary.com/bitcoin-me/image/upload/v1690816430/Vip_ylzquh.svg');
	background-blend-mode: soft-light;
	background-size: cover;
	height: 48px;
	left: 32px;
	top: 141px;
	border-radius: 8px;
	padding: 10px 20px;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top: 10px;
}

.klever-fee-card {
	height: 100%;
	border-radius: 8px;
	padding: 20px;
	justify-content: space-between;
	background: @secondary-color;

	.klever-fee-card-content {
		padding: 10px 20px;
		align-items: center;
		justify-content: space-between;
		background: @body-background;
		margin-top: 20px;
		cursor: pointer;
		border-radius: 4px;
	}

	.klever-fee-card-viplevel {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: @white;
	}

	.klever-fee-card-banner-title {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: @white;
	}

	.klever-fee-card-content-percent h3.ant-typography,
	.ant-typography h3 {
		margin-bottom: 0;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 32px;
		color: @white-contrast;
		padding: 8px 0;
	}

	.klever-fee-card-content-title {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		color: @white;
	}

	.klever-fee-card-content-undertext {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 0.8em;
		line-height: 16px;
		color: @white-contrast;
	}
}

.klever-dust-history-content {
	margin-top: 30px;

	.klever-dust-history-tabs {
		width: 100%;
		position: relative;

		.ant-tabs-content-holder {
			padding-top: 0;
		}

		.ant-tabs-nav::before {
			border-bottom: solid 1px @secondary-color !important;
		}

		.ant-tabs-nav {
			max-width: 1440px;
			width: 100%;
		}

		.ant-tabs-tab {
			background: transparent !important;
			border: none !important;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: @white-contrast;
		}

		.ant-tabs-tab-active {
			background: @body-background !important;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			border-bottom: solid 2px @primary-color !important;
		}

		.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: @white !important;
		}

		.ant-tabs-content,
		.ant-tabs-content .ant-tabs-content-top {
			max-width: 1440px;
			margin: auto;
		}
	}

	.klever-dust-history-panes {
		display: flex;
		width: 100%;

		.klever-dust-history-export-btn {
			position: absolute;
			right: 0;
			border: none;
			color: @white-contrast;
			display: flex;
			align-items: center;
			margin-top: 5px;
			padding: 0;
		}

		.klever-dust-history-export-btn:focus,
		.klever-dust-history-export-btn:hover,
		.klever-dust-history-export-btn:active {
			background: @body-background !important;
			color: @white-contrast !important;
		}
	}
}

@media only screen and (max-width: 413px) {
	.klever-convert-balance {
		.klever-spot-balance h2 {
			font-size: 30px;
		}
	}
}

@media only screen and (max-width: 395px) {
	.dustklv-button-layout-2 {
		padding: 0 3vw;
	}

	.klever-dustklv-layout,
	.klever-convert-balance {
		padding: 0 3vw;
	}
}

.klever-dust-history-layout {
	padding: 0;
	max-width: 1440px;
	margin: auto;
}

.klever-dust-history-container {
	margin: 10px 0;
}

.klever-dust-history-container label {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: @white-contrast;
}

.klever-dust-history-table .ant-table-thead > tr > th,
.klever-dust-history-table .ant-table-thead > tr > th:hover {
	background: @secondary-color;
}

.klever-dust-history-table .ant-table table {
	border-spacing: 0 8px;
}

.klever-dust-history-table .ant-table {
	background: transparent;
}

.klever-dust-history-table tbody:before {
	content: '-';
	display: block;
	line-height: 1px;
	color: transparent;
}

.klever-dust-history-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-dust-history-table .ant-table-container table > thead > tr > th {
	background: @body-background !important;
	padding: 0 16px 0 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: @white;
}

.klever-dust-history-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-dust-history-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-dust-history-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-dust-history-table .ant-table-column-title {
	flex: none;
}

.klever-dust-history-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-dust-history-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-dust-history-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-dust-history-table tr {
	border-radius: 8px;
}

.klever-dust-history-table td {
	background: @body-background !important;
}

.klever-dust-history-table .ant-table-container table > tbody > tr > td small {
	margin-top: -5px;
	color: @white;
	white-space: nowrap;
}

.klever-dust-history-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-dust-history-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-dust-history-table .ant-table-tbody > tr > td {
	border-bottom: none;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 10px;
	color: @white-contrast;
	background: @secondary-color !important;
	padding-left: 10px;
}

.klever-dust-history-table .ant-table-tbody > tr > td b {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: @white;
	white-space: nowrap;
}

.klever-dust-history-table .ant-table-expanded-row > td {
	top: -16px;
	background: @body-background !important;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	padding-top: 20px;
}

.klever-dust-history-table .ant-table-expanded-row > td > div {
	background: @body-background;
	border-radius: 8px;
	padding: 10px 16px;
}

.klever-export-history-modal {
	.ant-modal-content {
		padding: 10px 20px;
		background-color: @secondary-color;
		border-radius: 8px;
		max-width: 448px;
		margin: -20px auto;
	}

	.ant-modal-header {
		padding-left: 5px;
		padding-right: 5px;
		background: @secondary-color;
		border-bottom: none !important;
	}

	.ant-modal-body {
		background: @secondary-color;
		padding: 10px 5px;
	}

	.klever-export-history-close-btn {
		margin-top: 12px;
	}

	.klever-export-history-transaction-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		> small {
			color: @white-contrast;
		}
	}

	.klever-export-history-transaction-select {
		width: 100%;
		border: 1px solid @white-contrast;
		border-radius: 8px;

		.ant-select-selector {
			border: none;
		}
	}

	.klever-export-history-radio-group {
		width: 100%;
		margin-top: 15px;
		padding: 0 20px;

		.klever-export-history-radio-group-rows {
			padding: 5px 0;

			.ant-radio-wrapper {
				flex: 1;
			}
		}
	}

	.klever-export-history-time-frame {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		> small:first-child {
			color: @white-contrast;
		}

		> small:last-child {
			font-size: 8px;
		}
	}

	.klever-export-history-date-picker {
		width: 100%;
		border: 1px solid @white-contrast;
		border-radius: 8px;
	}

	.klever-export-history-export-btns-container {
		width: 100%;
		margin-top: 20px;
		display: flex;
		justify-content: space-between;

		> button {
			width: calc(50% - 5px);
			background-color: @white-contrast;
			border-radius: 8px;
			padding: 20px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
		}
	}
}

.klever-auth-mfa-input-title {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: @body-background;
	margin-bottom: 2px;
}

.klever-auth-mfa-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	color: @body-background;
}

.klever-auth-mfa-subtitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: @body-background;
	margin-bottom: 16px;
}

.klever-trading-fees-table .ant-table-thead > tr > th,
.klever-trading-fees-table .ant-table-thead > tr > th:hover {
	background: transparent;
}

.klever-trading-fees-table .ant-table table {
	border-spacing: 0 10px;
}

.klever-trading-fees-table .ant-table {
	background: transparent;
}

.klever-trading-fees-table tbody:before {
	content: '-';
	display: block;
	line-height: 1px;
	color: transparent;
}

.klever-trading-fees-table .ant-table-thead > tr > th {
	border-bottom: none;
}

.klever-trading-fees-table .ant-table-container table > thead > tr > th {
	background: transparent;
	padding: 0px 16px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: @white;
}

.klever-trading-fees-table .ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-trading-fees-table .ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-trading-fees-table
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}

.klever-trading-fees-table .ant-table-column-title {
	flex: none;
}

.klever-trading-fees-table .ant-table-column-sorters {
	justify-content: flex-start;
	gap: 0 5px;
}

.klever-trading-fees-table .ant-table-column-sorter {
	color: @white-contrast;
}

.klever-trading-fees-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 8px;
	margin: 1.5px 0;
}

.klever-trading-fees-table tr {
	border-radius: 8px;
}

.klever-trading-fees-table td {
	background: @body-background !important;
	padding: 10px 0 4px 10px !important;

	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: @white;
}

.klever-trading-fees-table td:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.klever-trading-fees-table td:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.klever-trading-fees-table .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
	border-bottom: none;
	background: @secondary-color !important;
}

.klever-trading-fees-table .ant-table-tbody tr:first-child {
	display: none;
}

.klever-trading-fees-table small {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: right;
	color: @white-contrast;
}

.trading-fees-curr-user-vip-level-table {
	color: transparent;
	background-color: transparent;
	background: -webkit-linear-gradient(45deg, @primary-color, @primary-gradient-color);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
}

.div-buttons-nft-create-order {
	margin-top: 20px;

	button {
		height: 48px;
	}
}

.marketplace-asset-offers-layout {
	display: flex;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	align-items: flex-end;
	width: 100%;
}

#marketplace-asset-offers-price {
	color: @primary-color;
	font-weight: 600;
	margin-bottom: 0;
}

.button-asset-offers {
	width: 100%;
	height: 40px;
	border-radius: 8px;
}

.button-asset-offers-cancel {
	width: 100%;
	height: 40px;
	border-radius: 8px;
	margin-top: 10px;
}

.klever-asset-place-bid-col-token {
	padding: 10px 0 0 20px;
}

.klever-asset-place-bid-col-currency {
	padding-left: 20px;
}

.button-radius {
	border-radius: 6px;
}

.modal-create-offer-layout-avaible-token {
	padding: 0 3px 8px;
}

.klever-asset-content #marketplace-asset-offers-user-id {
	font-weight: 500;
	line-height: 8px;
	font-size: 16px;
	margin-bottom: 4px;
	color: @white;
}

.klever-asset-content #marketplace-asset-offers-date-time {
	color: #545791;
	font-size: 12px;
}

#klever-collection-devikin {
	margin-top: 8px;
}

.klever-collection-list-owner-box {
	height: min-content;
	padding: 10px;
	margin: -140px 40px 15px 40px;

	top: 100%;
	right: 1%;
	position: relative;
	width: fit-content;
	max-width: 400px;

	.klever-verified-icon {
		top: 85px;
		left: 95px;

		img {
			width: 25px;
			height: 25px;
		}
	}

	b {
		font-weight: bold;
		font-size: 18px;
		line-height: 16px;
		color: @white;
		margin-left: 25px;
		margin-bottom: 5px;
	}

	.klever-collection-address-badge {
		background: @secondary-color !important;
		border-radius: 16px;
		padding: 0 10px;
		margin-left: 5px;
		height: 26px;
		overflow-x: hidden;
		margin-top: 10px;

		small {
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: @white-contrast;
		}
	}

	p {
		padding: 25px 0 5px 0;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: @white-contrast;
	}

	.klever-collection-twitter {
		margin-bottom: 30px;

		a {
			margin-left: 8px;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			color: @white-contrast !important;
		}
	}
}

#marketplace-collectionlist-layout {
	margin-bottom: 40px;

	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	grid-gap: 20px;

	.collections-list-banner {
		margin: 10px auto;
	}
}

.klever-collection-list-banner {
	height: 364px;
	width: 1380px;
	left: 80px;
	border-radius: 0px;
	background-size: cover;
	margin: 0;
	padding: 0;
}

.mfa-error-box {
	background: #ffc7c7;
	border-radius: 8px;
	height: 32px;
	padding-top: 8px;
	margin: -15px 0 15px 0;

	span {
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		color: #9e1313;
	}
}

.klever-loading-layout {
	height: 100vh;

	.loading-center {
		margin: auto;
		display: flex;
		flex-direction: column;

		img {
			width: 150px;
		}

		span {
			font-size: 40px;
			color: @secondary-color;
			margin-top: 15px;
		}
	}
}

.klever-collection-top-container-list {
	display: flex;
	align-items: self-end;
	flex-direction: row;
}

.collections-list-banner {
	background: @secondary-color;
	height: 172px;
	width: 290px;
	top: 16px;
	border-radius: 16px;
	background-size: cover;
	transition: all 0.2s;
	margin: 10px auto;

	.marketplace-nft-avatar-list {
		top: 61%;
		left: 6%;
	}

	div {
		width: fit-content;
		position: absolute;
		top: 115px;
		left: 71px;
	}

	.klever-verified-icon {
		top: 132px;
		left: 51px;
	}
}

.collections-list-banner:hover {
	outline: 1px solid #d96200;
	cursor: pointer;
}

#owner-box-texts {
	position: relative;
	height: fit-content;
	top: 25px;
	left: 10px;
	margin-left: 5px;
	font-size: 14px;
}

#trending-collections-layout {
	height: 200px;
	margin-bottom: 40px;
}

.collections-trending-grid {
	height: fit-content;
	width: 100%;
	margin-top: 20px;

	.collections-trending-grid-div {
		display: flex;
		position: relative;
		flex-direction: row;
		justify-content: space-between;

		.collections-trending-grid-div-avatar {
			.klever-verified-icon {
				left: 45px;
				top: 30px;
			}
		}

		.collections-trending-grid-div-collection-name {
			margin-left: 10px;
		}

		.collections-trending-grid-div-collection-volume {
			text-align: end;
		}
	}
}

.marketplace-collection-list-recent-offers-title {
	align-items: center;

	h2 {
		margin-bottom: 0;
	}

	.marketplace-collection-list-recent-offers-pagination {
		line-height: 0;

		:hover {
			background-clip: text;
			cursor: pointer;
			fill: @primary-color;
		}
	}

	.marketplace-collection-list-buttons-pagination-layout {
		margin-left: 15px;
		display: flex;
		align-items: center;
	}
}

.klever-collection-list-banner-skeleton {
	height: 364px;
	width: 1380px;
	background: @secondary-color;
	padding: 0px;
	margin: 0px;
	row-gap: 0px;

	.ant-skeleton {
		position: relative;
		top: 230px;
		left: 32px;
	}
}

.klever-collection-list-banner-skeleton-others-collections {
	margin-top: 20px;
	background: @secondary-color;
	height: 172px;
	width: 1380px;
	border-radius: 16px;
}

.klever-collection-list-banner-skeleton-ranking-collections {
	margin-top: 20px;
	background: @secondary-color;
	height: 60px;
	width: 1380px;
	border-radius: 12px;
}

.marketplace-collection-list-offercard {
	width: 210px;
	background: @secondary-color;
	margin: 10px;
	border-radius: 12px;
	transition: all 0.2s;

	.ant-btn-primary {
		border-radius: 8px;
		margin-top: 18px;
		width: 100%;
	}

	.ant-card-cover {
		padding: 10px;
		height: 250px;

		div {
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 75%;
			background-position-x: center;
			border-radius: 12px;
			width: 100%;
			height: 100%;
		}
	}

	.ant-card-body {
		padding: 0 16px 16px 16px;

		.ant-card-meta {
			.ant-card-meta-detail {
				.ant-card-meta-title {
					padding-bottom: 8px;
					font-family: Noto Sans;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: left;
					color: @white;
				}

				.ant-card-meta-description {
					.marketplace-collection-list-offercard-first-description {
						small {
							font-family: Noto Sans;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0em;
							text-align: left;
							color: @white-contrast;
						}

						strong {
							font-family: Noto Sans;
							font-size: 12px;
							font-style: normal;
							font-weight: 700;
							line-height: 16px;
							letter-spacing: 0em;
							text-align: right;
							color: @white;
						}
					}

					.marketplace-collection-list-offercard-second-description {
						margin-top: 10px;
						flex-wrap: nowrap;
						overflow-x: hidden;

						small {
							font-family: Noto Sans;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0em;
							text-align: left;
							color: @white-contrast;
						}

						strong {
							font-family: Noto Sans;
							font-size: 13px;
							font-style: normal;
							font-weight: 500;
							line-height: 16px;
							letter-spacing: 0em;
							text-align: right;

							background: -webkit-linear-gradient(45deg, @primary-color, @primary-gradient-color);
							background-clip: border-box;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}
				}
			}
		}
	}
}

.marketplace-collection-list-offercard:hover {
	border: 1px solid @primary-color;
	transition: border 0.3s ease;
}

.klever-link-using-klv-discount {
	font-family: Rubik;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: @white;
}

.klever-google-auth-validate-paragraphs-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: @body-background;
	margin-bottom: 16px;
}

.klever-google-auth-validate-mfa-title {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: @body-background;
	margin-bottom: 10px;
}

.klever-pools-layout {
	background: @body-background;
}

.klever-pools-hero {
	max-width: 1440px;
	width: 100%;
	margin: 90px auto 100px auto;
	padding: 0 0 0 30px;
	overflow-x: hidden;
	height: 100%;
	position: relative;
	background-color: @body-background;

	.klever-pools-hero-info {
		max-width: 1440px;
		display: flex;
		justify-content: space-between;
		width: 100%;

		> p {
			font-size: 16px;
			color: @white-contrast;

			span {
				color: @primary-color;
				font-weight: bold;
			}
		}

		h2 {
			font-size: 48px;
			font-weight: bold;
			line-height: 60px;
			margin-bottom: 0;
			margin-top: 20px;
		}

		.klever-pools-hero-button {
			background-color: @primary-color;
			border: none;
			padding: 25px 40px;
			font-weight: bold;
			border-radius: 8px;
			display: flex;
			align-items: center;
			margin-top: 20px;
		}

		.klever-pools-hero-totals {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: 100%;

			.klever-pools-hero-totals-value-and-filters {
				display: flex;
				align-items: center;

				.klever-pools-hero-totals-filters-wrapper {
					display: flex;
					gap: 10px;
					margin: 8px 0 0 10px;

					.klever-pools-hero-select {
						margin-bottom: 8px;
						min-width: 100px;
					}

					.klever-pools-hero-select .ant-select-selector {
						display: flex;
						border-radius: 8px !important;
						border: 1px solid @white-contrast !important;
						font-family: 'Rubik', sans-serif;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						height: 36px !important;
						width: 140px;
						color: @white-contrast;
					}

					.klever-pools-hero-select .ant-select-selector span {
						line-height: 35px !important;
					}

					@media screen and (max-width: 1024px) {
						display: none;
					}
				}

				@media screen and (max-width: 1045px) {
					flex-direction: column;
					gap: 10px;
					width: 100%;
				}

				@media screen and (max-width: 475px) {
					justify-content: center;
				}
			}

			.klever-pools-hero-totals-modes-and-my-pools {
				display: flex;
				margin-bottom: 10px;

				.klever-pools-hero-modes-wrapper {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-right: 20px;

					@media screen and (max-width: 1024px) {
						display: none;
					}
				}
			}

			.klever-pools-hero-my-pools-btn {
				border: none;
				border-radius: 8px;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				color: #ffffff;

				&:hover {
					color: #ffffff;
					opacity: 0.8;
				}
			}

			.klever-pools-hero-totals-item {
				display: flex;
				gap: 10px;
				padding: 10px;
				align-items: center;
				border-radius: 8px;
				background-color: @secondary-color;
				height: fit-content;

				.klever-pools-hero-totals-item-title {
					color: @white-contrast;
					font-weight: bold;
					margin: 0;
				}

				.klever-pools-hero-totals-item-subtitle {
					color: @white;
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 0;
					font-family: Rubik;
				}

				@media screen and (max-width: 420px) {
					flex-direction: column;
				}
			}

			@media screen and (max-width: 1045px) {
				flex-direction: column;
				align-items: center;
				gap: 10px;
			}

			@media screen and (max-width: 475px) {
				flex-direction: column;
				align-items: center;
				gap: 20px;
			}
		}

		@media screen and (max-width: 745px) {
			flex-direction: column;
		}
	}

	.klever-pools-hero-info-subscribe-title-container {
		margin-top: 20px;

		span {
			font-weight: bold;
			font-size: 12px;
		}
	}

	.klever-pools-hero-info-subscribe-input-container {
		display: flex;
		align-items: center;

		.ant-form {
			.ant-form-item {
				width: 100%;
				margin: 0;
			}
		}

		.ant-input-affix-wrapper {
			background: @body-background;
			border: 1px solid #333;
			border-radius: 4px;
		}

		.ant-btn {
			border: none;
			font-weight: bold;
			font-size: 12px;
			background: @primary-color;
			margin-left: 10px;
			border-radius: 4px;
		}

		.klever-pools-hero-info-subscribe-feedback,
		.klever-pools-hero-info-subscribe-feedback-success,
		.klever-pools-hero-info-subscribe-feedback-error {
			font-size: 12px;
			font-weight: bold;
			margin-top: 10px;
		}

		.klever-pools-hero-info-subscribe-feedback-success {
			color: @green;
		}

		.klever-pools-hero-info-subscribe-feedback-error {
			color: @red;
		}
	}

	@media screen and (max-width: 1024px) {
		padding: 0;
		margin: 20px auto 40px auto;
	}
}

.klever-pools-section {
	max-width: 1440px;
	margin: -80px auto;
	padding: 0 2vw;
	background-color: @body-background;

	@media screen and (max-width: 1024px) {
		margin: 0 auto 40px auto;
	}

	@media screen and (max-width: 492px) {
		padding: 0 23px 0;
	}

	.klever-pools-featured-and-expandable {
		h3 {
			color: @white;
			font-size: 32px;
			font-weight: bold;
		}

		.klever-pools-featured-and-expandable-titles {
			border-bottom: 1px solid #4c4c4c;
		}

		.klever-pools-featured-and-expandable-cards {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
			grid-gap: 40px;

			.klever-pools-featured-and-expandable-card {
				display: flex;
				flex-direction: column;
				background-color: @secondary-color;
				border-radius: 16px;
				z-index: 9;
				width: 310px;
				justify-self: center;

				.klever-pools-featured-and-expandable-card-faq-status {
					display: flex;
					gap: 20px;
					align-items: center;
					padding: 20px 20px 0 20px;

					.klever-pools-featured-and-expandable-card-faq-status-text,
					.klever-pools-featured-and-expandable-card-faq-status-text-diff {
						color: @green;
						font-size: 12px;
						font-weight: bold;
					}

					.klever-pools-featured-and-expandable-card-faq-status-text-diff {
						color: @primary-color;
					}
				}

				.klever-pools-featured-and-expandable-card-question {
					text-align: end;
					cursor: pointer;
					margin-right: -10px;
				}

				.klever-pools-featured-and-expandable-card-header {
					display: flex;
					align-items: center;

					p {
						background: linear-gradient(135deg, @primary-color 1.18%, #ff8f33 75.21%);
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						margin-left: 10px;
						margin-bottom: 0;
						font-weight: bold;
						font-size: 14px;
						line-height: 16px;
						width: 70%;
					}

					.klever-pools-featured-and-expandable-card-header-quote {
						margin-left: -7px;
					}
				}

				.klever-pools-featured-and-expandable-info {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;

					.klever-pools-featured-and-expandable-info-items {
						margin-bottom: 8px;

						.klever-pools-featured-and-expandable-info-items-rewards {
							font-size: 12px;
							display: flex;
							flex-direction: column;
							gap: 5px;
							padding: 0 20px;

							.klever-pools-featured-and-expandable-info-items-rewards-item {
								color: @white-contrast;
							}
						}

						p {
							margin-bottom: 0;
							line-height: 32px;
						}

						.klever-pools-featured-and-expandable-title {
							color: @white-contrast;
							font-size: 14px;
							font-weight: bold;
						}

						.klever-pools-featured-and-expandable-paragraph {
							color: @white;
							font-size: 18px;
							font-family: 'Noto Sans';
						}
					}

					.klever-pools-featured-and-expandable-info-reward {
						margin-bottom: 20px;

						.klever-pools-featured-and-expandable-reward-title {
							color: @white-contrast;
							font-size: 14px;
							font-weight: bold;
							margin-bottom: 5px;
						}

						.klever-pools-featured-and-expandable-reward-coin {
							display: flex;
							align-items: center;

							.klever-pools-featured-and-expandable-reward-coin-wrapper {
								display: flex;
								flex-direction: column;

								.klever-pools-featured-and-expandable-reward-coin-value {
									font-weight: bold;
									margin-left: 7px;
									margin-top: 1px;
								}

								p {
									color: @white;
									font-size: 14px;
									margin: 1px 0 0 6px;
									font-weight: bold;
								}
							}
						}
					}

					.klever-pools-featured-and-expandable-info-ends {
						.klever-pools-featured-and-expandable-ends-title {
							color: @white-contrast;
							font-size: 12px;
							font-weight: bold;
							margin: 0;
						}

						.klever-pools-featured-and-expandable-ends-paragraph {
							color: @white;
							font-size: 14px;
							margin-top: 1px;
							font-weight: bold;
						}
					}

					.klever-pools-featured-and-expandable-btn {
						width: 100%;
						margin-top: 20px;

						button {
							width: 100%;
							border-radius: 4px;
							background-color: @primary-color;
							font-size: 14px;
							font-weight: bold;
							padding: 20px 0;
							display: flex;
							align-items: center;
							justify-content: center;
							border: none;
						}
					}
				}
			}

			@media screen and (max-width: 332px) {
				grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
			}
		}
	}

	.klever-pools-featured-and-expandable-title {
		color: @white-contrast;
		font-size: 14px;
		font-weight: bold;
	}

	.klever-pools-featured-and-expandable-paragraph {
		color: @white;
		font-weight: bold;
		font-size: 24px;
		font-family: Rubik;
	}

	.klever-pools-featured-and-expandable-btn {
		width: 100%;
		margin-top: 20px;

		button {
			width: 100%;
			border-radius: 4px;
			background-color: @primary-color;
			font-size: 14px;
			font-weight: bold;
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
		}
	}

	.klever-pools-user-table,
	.klever-pools-table {
		.ant-table-container table > thead > tr > th {
			background: @body-background !important;
		}
	}

	.klever-pools-user-table {
		margin-top: 15px;
	}

	.klever-pools-table-container {
		margin-top: 90px;

		.klever-pools-table-header {
			display: flex;
			align-items: center;
		}
	}
}

.klever-pools-table-pooled-values {
	span {
		color: @white;
	}
}

.klever-pools-table-pool {
	display: flex;
	align-items: center;

	.klever-pools-table-pool-quote {
		margin-left: -7px;
	}

	p {
		margin-bottom: 0;
		margin-left: 10px;
		font-size: 14px;
		color: @white;
		font-weight: bold;
		font-family: 'Noto Sans';
	}
}

.klever-claim-all {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;
	width: 100%;
	margin: 0 10px 20px 0;
}

.klever-pools-user-table-wrapper,
.klever-pools-table-wrapper {
	margin-top: 30px;
	width: 100%;

	.ant-tabs-nav {
		padding: 0 !important;
		display: none;
	}

	.ant-tabs-content {
		padding: 0 !important;
		margin-top: -10px;
	}

	.klever-tabs {
		.ant-tabs-tab {
			color: @white;
			font-size: 14px;
		}

		.ant-tabs-nav {
			&::before {
				display: none;
			}
		}

		.ant-tabs-extra-content {
			display: flex;
		}
	}

	.klever-pools-table-item {
		color: @white;
		font-size: 14px;
	}

	.klever-pools-table-reward-coin {
		display: flex;
		align-items: center;

		span {
			color: @white;
			font-size: 12px;
			font-family: 'Noto Sans';
		}
	}

	.klever-pools-table-expired-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 5px 0;
	}

	.klever-pools-table-actions-expired {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			font-style: italic;
			color: rgba(123, 125, 178, 0.5);
		}
	}

	.klever-pools-table-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.klever-pools-table-btn-add {
		border-radius: 4px;
		background-color: @primary-color;
		font-size: 12px;
		font-weight: bold;
		padding: 10px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		font-family: 'Noto Sans';
		width: 80px;
	}

	.klever-pools-table-btn-claim {
		border-radius: 4px;
		background-color: transparent;
		border: 1px solid @primary-color;
		font-size: 12px;
		font-weight: bold;
		padding: 10px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Noto Sans';
		margin: 0 10px;
	}

	.klever-pools-table-btn-remove,
	.klever-pools-table-btn-my-pool {
		border-radius: 4px;
		background-color: transparent;
		border: 1px solid @primary-color;
		font-size: 12px;
		font-weight: bold;
		padding: 10px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Noto Sans';
	}

	.klever-pools-table-btn-my-pool {
		margin: 0;
	}

	.klever-pools-expandable-item {
		border: none !important;
		padding: 10px 15px;
		border-radius: 8px !important;
		margin-top: 10px;
		background: @secondary-color;

		.ant-collapse-header {
			color: @white;
			font-family: 'Noto Sans' !important;
			font-weight: 700 !important;
			font-size: 24px;
		}

		.klever-pools-expandable-content-wrapper {
			display: flex;
			flex-direction: column;

			.klever-pools-expandable-content-info {
				display: flex;
				flex-direction: column;
				margin: 7px 0;

				span {
					&:first-child {
						color: @white-contrast;
						font-weight: bold;
					}

					&:last-child {
						color: @white;
						font-weight: bold;
					}
				}
			}

			.klever-pools-expandable-content-operation-title {
				margin-top: 5px;
				color: @white-contrast;
				font-weight: bold;
			}

			.klever-pools-expandable-content-btns {
				display: flex;
				flex-direction: column;
				margin-top: 3px;

				.klever-pools-expandable-content-btn-add {
					border-radius: 4px;
					background-color: @primary-color;
					color: @white;
					font-size: 14px;
					font-weight: bold;
					padding: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					font-family: 'Noto Sans';
				}

				.klever-pools-expandable-content-btn-remove,
				.klever-pools-expandable-content-btn-my-pool {
					border-radius: 4px;
					background-color: transparent;
					border: 1px solid @primary-color;
					font-size: 14px;
					font-weight: bold;
					padding: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'Noto Sans';
				}

				.klever-pools-expandable-content-btn-remove {
					margin: 10px 0;
				}
			}
		}
	}

	.klever-pools-expandable-no-content {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.klever-pools-user-table-wrapper {
	margin-top: -10px;
}

@media screen and (max-width: 560px) {
	.klever-pools-hero-info-subscribe-input-container {
		.ant-input-affix-wrapper {
			padding: 10px;
		}

		.ant-btn {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 0 !important;
			margin-top: 10px;
		}

		.ant-form {
			flex-direction: column;
			padding-right: 20px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.klever-pools {
		.klever-pools-hero {
			.klever-pools-hero-info {
				left: 0;
				width: 100%;
				padding: 0 40px;

				h2 {
					font-size: 32px;
					line-height: 40px;
				}

				.klever-pools-hero-button {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.klever-pools-hero-totals {
					flex-direction: column;
					width: 100%;
					margin-top: 20px;

					.klever-pools-hero-totals-item {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}

		.klever-pools-section {
			margin: 0 auto;

			.klever-pools-featured-and-expandable {
				.klever-pools-featured-and-expandable-cards {
					grid-gap: 20px;

					.klever-pools-no-featured {
						font-size: 22px;
						color: @white;
					}
				}
			}

			.klever-pools-table-container {
				margin-top: 50px;

				.klever-pools-table-header {
					flex-direction: column-reverse;
					width: 100%;

					.klever-table-search {
						width: 100%;
					}
				}
			}
		}

		.klever-pools-featured-and-expandable-info {
			.klever-pools-featured-and-expandable-info-items {
				display: flex;
				justify-content: space-between;

				.klever-pools-featured-and-expandable-title {
					color: @white-contrast;
					font-size: 14px;
					font-weight: bold;
				}

				.klever-pools-featured-and-expandable-paragraph {
					color: @white;
					font-weight: bold;
					font-size: 16px;
					font-family: Rubik;
				}
			}

			.klever-pools-featured-and-expandable-info-reward {
				display: flex;
				justify-content: space-between;

				.klever-pools-featured-and-expandable-reward-title {
					color: @white-contrast;
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 5px;
				}

				.klever-pools-featured-and-expandable-reward-coin {
					display: flex;
					align-items: center;

					p {
						color: @white;
						font-size: 14px;
						margin: 1px 0 0 10px;
						font-weight: bold;
					}
				}
			}

			.klever-pools-featured-and-expandable-btn {
				width: 100%;
				margin-top: 20px;

				button {
					width: 100%;
					border-radius: 4px;
					background-color: @primary-color;
					font-size: 14px;
					font-weight: bold;
					padding: 20px 0;
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
				}
			}

			.klever-pools-featured-and-expandable-btn-login {
				background: @white-contrast;
				color: @white;
			}
		}

		.klever-footer-section {
			padding: 0;
		}
	}
}

.klever-liquidity {
	background: @body-background;

	.klever-liquidity-wrapper {
		max-width: 980px;
		margin: 110px auto 50px;
		padding: 0 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.klever-liquidity-title {
			font-size: 32px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.klever-liquidity-cards {
			display: flex;
			justify-content: space-between;

			.klever-liquidity-card {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				background: @secondary-color;
				border-radius: 8px;
				margin-right: 10px;
				padding: 20px;
				flex: 2;
				border: 2px solid @white-contrast;
				min-height: 400px;

				.klever-liquidity-card-input {
					width: 100%;
					height: fit-content;
					padding: 20px;
					background: @body-background;
					border-radius: 8px;

					.klever-liquidity-card-input-header {
						display: flex;
						justify-content: space-between;

						@media screen and (max-width: 1024px) {
							span {
								&:first-child {
									display: none;
								}
							}
							.ant-avatar {
								display: block !important;
							}
						}
					}

					.klever-liquidity-card-input-footer {
						display: flex;
						align-items: flex-end;
						justify-content: space-between;

						.ant-input-number {
							margin-right: 20px;
							margin-top: 10px;
							width: 100%;
							border: none;
							font-family: 'Rubik';
							font-size: 24px;
							background-color: @body-background;
							color: @white;

							.ant-input-number-input {
								padding: 0;
							}

							&::placeholder {
								font-family: 'Rubik';
								font-size: 24px;
							}

							@media screen and (max-width: 1024px) {
								width: 50%;
								align-self: flex-end;
							}
						}

						.ant-select {
							margin-right: -10px;
							margin-top: 15px;

							.ant-select-selector {
								border: none;
								display: flex;
								align-items: center;

								.ant-select-selection-placeholder {
									color: @white;
									font-size: 14px;
									font-weight: bold;
								}

								.ant-select-item-option {
									display: flex;
									align-items: center;
								}
							}
						}

						@media screen and (max-width: 1024px) {
							flex-direction: column-reverse;

							.ant-input-number {
								width: 100%;
								margin-top: 0;
								margin-right: 0;
								font-size: 16px;
							}
						}
					}

					.klever-liquidity-card-available-footer {
						display: flex;
						margin: 8px 0;

						span:first-child {
							color: @white-contrast;
							font-weight: 700;
							margin-right: 10px;
						}

						span:last-child {
							font-size: 14px;
							color: @white-contrast;

							@media screen and (max-width: 1024px) {
								font-size: 12px;
							}
						}

						@media screen and (max-width: 1024px) {
							width: 100%;
							font-size: 12px;
							display: flex;
							align-items: center;
						}
					}

					.klever-liquidity-select-coins-wrapper {
						display: flex;
						align-items: center;

						span {
							&:last-child {
								margin-left: 5px;
								margin-top: 1px;
								font-weight: bold;
							}
						}
					}
				}

				.klever-liquidity-card-middle {
					display: flex;
					justify-content: space-between;
					align-items: center;

					> span {
						cursor: pointer;
						background: @body-background;
						border-radius: 40px;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;

						@media screen and (max-width: 500px) {
							width: 32px;
							height: 32px;
						}

						span {
							svg {
								@media screen and (max-width: 500px) {
									width: 14px;
								}
							}
						}
					}

					.klever-liquidity-card-middle-btns {
						display: flex;
						align-items: center;
						justify-content: center;

						.klever-liquidity-card-middle-btn {
							background: @body-background;
							-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
							-moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
							box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
							border-radius: 4px;
							border: none;
							padding: 20px;
							margin-right: 10px;
							display: flex;
							align-items: center;
							justify-content: center;

							&:last-child {
								margin-right: 0;
							}

							@media screen and (max-width: 500px) {
								padding: 14px;
								font-size: 12px;
							}

							@media screen and (max-width: 430px) {
								padding: 12px;
								font-size: 10px;
							}
						}
					}
				}

				.klever-liquidity-btn {
					background-color: @primary-color;
					border: none;
					padding: 20px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;

					@media screen and (max-width: 762px) {
						margin-top: 20px;
					}
				}
			}

			.klever-liquidity-summary-card {
				border-radius: 8px;
				background-color: @secondary-color;
				margin-left: 10px;
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 20px;

				.klever-liquidity-summary-card-header {
					padding-bottom: 10px;
					border-bottom: 1px solid @white-contrast;

					> span {
						font-size: 14px;
						font-weight: bold;
						color: @white;
					}
				}

				.klever-liquidity-summary-card-content,
				.klever-liquidity-summary-card-content-breakdown {
					margin-top: 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: @white-contrast;
					font-size: 12px;
				}

				.klever-liquidity-summary-card-content {
					.klever-liquidity-summary-card-content-value {
						font-size: 14px;
						color: @white;
						font-family: 'Rubik';
					}
				}

				.klever-liquidity-summary-card-content-breakdown {
					color: @white;
					font-weight: bold;
				}

				.klever-liquidity-summary-card-content-rewards {
					display: flex;
					flex-direction: column;
					margin-top: 8px;
					color: @white-contrast;
					font-size: 12px;
					justify-content: flex-start;

					.klever-liquidity-summary-card-content-rewards-values-wrapper {
						display: flex;
						flex-direction: column;

						.klever-liquidity-summary-card-content-rewards-value {
							display: flex;
							align-items: center;
							justify-content: space-between;

							span {
								font-size: 12px;
								color: @white;

								&:last-child {
									font-size: 14px;
								}
							}
						}
					}
				}

				.klever-liquidity-summary-card-content-risk {
					display: flex;
					flex-direction: column;
					margin-top: 10px;
					color: @white-contrast;
					font-size: 12px;
					justify-content: flex-start;

					span {
						svg {
							margin: 2px 0 0 2px;
							cursor: pointer;
						}
					}
				}
			}
		}

		.klever-liquidity-info {
			margin-top: 20px;
			padding: 20px;
			width: 100%;
			background: @secondary-color;
			border-radius: 8px;
			display: flex;
			align-items: center;

			> span {
				margin-left: 10px;
				margin-top: 1px;
			}

			.klever-liqudity-info-link {
				margin-left: 10px;
			}

			@media screen and (max-width: 1024px) {
				flex-direction: column;
			}
		}

		@media screen and (max-width: 1024px) {
			padding: 0 20px;
		}
	}
}

.klever-holdings-my-pools-container {
	.klever-holdings-my-pools-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 30px;
		margin-bottom: 13px;
	}

	.klever-pools-table-view-as-wrapper {
		display: flex;
		align-items: center;

		.klever-pools-table-view-as-wrapper-title {
			margin-right: 10px;
		}
	}
}

.klever-holdings-my-pools-grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 256px));
	grid-gap: 20px;
	margin-top: 30px;
	width: 100%;

	.klever-holdings-my-pools-grid-card {
		background: @secondary-color;
		border-radius: 4px;
		padding: 20px 25px;
		display: flex;
		flex-direction: column;

		.klever-holdings-my-pools-grid-card-header {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			span {
				font-weight: bold;
				font-size: 14px;

				&:first-child {
					margin-right: 3px;
				}

				&:last-child {
					margin-left: 10px;
					width: 75%;
				}
			}
		}

		.klever-holdings-my-pools-grid-infos-wrapper {
			display: flex;
			flex-direction: column;
			margin: 10px 0;
			width: 100%;

			.klever-holdings-my-pools-grid-info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin: 5px 0;

				span {
					&:first-child {
						font-size: 12px;
						color: @white-contrast;
						font-weight: bold;
					}

					&:last-child {
						font-size: 12px;
						color: @white;
					}
				}
			}

			.klever-holdings-my-pools-grid-info-pending {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin: 5px 0;

				span {
					&:first-child {
						font-size: 12px;
						color: @white-contrast;
						font-weight: bold;
					}

					&:last-child {
						font-size: 14px;
						color: @white-contrast;
					}
				}
			}
		}

		.klever-holdings-my-pools-grid-actions-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;

			.klever-holdings-my-pools-actions-btn-add {
				width: 100%;
				background-color: @primary-color;
				border: none;
				padding: 20px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				margin: 5px 0;
			}

			.klever-holdings-my-pools-actions-btn {
				border-radius: 8px;
				background-color: transparent;
				border: 1px solid @primary-color;
				font-size: 14px;
				font-weight: bold;
				padding: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'Noto Sans';
				margin: 3px 0 6px 0;
				width: 100%;
			}
		}
	}
}

.auto-height-modal {
	.ant-modal-body {
		max-height: fit-content;
	}
}

.input-filter-collection {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: fit-content;
	height: 42px;
}

@media screen and (max-width: 762px) {
	.klever-liquidity {
		.klever-liquidity-wrapper {
			margin: 100px auto;
			height: 100%;

			.klever-liquidity-title {
				font-size: 24px;
			}

			.klever-liquidity-cards {
				flex-direction: column;

				.klever-liquidity-card {
					margin-bottom: 40px;
					margin-right: 0;

					.klever-liquidity-card-middle {
						padding: 20px 0;
					}
				}

				.klever-liquidity-summary-card {
					margin-left: 0;
				}
			}
		}

		.klever-footer-section {
			padding: 0;
		}
	}
}

@media screen and (max-width: 360px) {
	.klever-liquidity {
		.klever-liquidity-wrapper {
			.klever-liquidity-cards {
				.klever-liquidity-card {
					.klever-liquidity-card-middle {
						flex-direction: column;

						.klever-liquidity-card-middle-btns {
							margin-top: 20px;
							display: flex;
							gap: 5px;

							.klever-liquidity-card-middle-btn {
								width: 100%;
								margin: 5px 0;
							}
						}
					}
				}
			}
		}
	}
}

.klever-select-modal-btn {
	background: transparent;
	border-radius: 8px;
	border: 1px solid @white-contrast;
	width: 100%;
	margin: 5px 0 15px 0;
	height: 42px;

	p {
		text-align: left;
		color: @white-contrast;
		margin-bottom: 0;
	}

	svg {
		fill: @white-contrast;
	}

	&:active,
	&:visited,
	&:focus {
		background: @body-background !important;
		border: 1px solid @body-background;
	}

	&:hover {
		border: 1px solid @primary-color;
	}
}

.klever-subtitle-modal-select-currency {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: @white-contrast;
}

.klever-convert {
	background: @body-background;

	.klever-convert-wrapper {
		max-width: 560px;
		margin: 95px auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 20px;

		.klever-convert-title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h4 {
				font-size: 32px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.klever-convert-title-history {
				border-radius: 4px;
				border: 1px solid @white-contrast;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: -5px;
				font-size: 12px;

				&:hover,
				&:active,
				&:visited,
				&:focus {
					border: 1px solid @primary-color;
					color: @primary-color;
					background-color: transparent;
				}
			}
		}

		.klever-convert-cards {
			display: flex;
			justify-content: space-between;

			.klever-convert-card {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				background: #2c2c2c99;
				border-radius: 8px;
				padding: 10px;
				flex: 2 1;
				border: 2px solid @white-contrast;

				@media screen and (max-width: 1024px) {
					margin-top: -10px;
				}

				.klever-convert-card-input,
				.klever-convert-card-second {
					width: 100%;
					padding: 14px;
					background: @body-background;
					border-radius: 8px;
					border: 2px solid @white-contrast;

					.klever-convert-card-input-header {
						display: flex;
						align-items: center;
						justify-content: space-between;

						span {
							font-size: 12px;
							font-weight: bold;
							color: @white-contrast;
						}

						@media screen and (max-width: 520px) {
							flex-direction: column;
							align-items: flex-start;

							span {
								margin-bottom: 8px;
							}
						}
					}

					.klever-convert-card-input-footer {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 8px;

						input {
							padding: 0;
							margin: 12px 0 0 0;
							width: 50%;
							border: none;
							font-family: 'Rubik';
							font-size: 24px;
							background-color: @body-background;
							color: @white;
							text-align: right;

							.ant-input-number-input {
								padding: 0;
							}

							&:focus {
								border: none;
								outline: none;
								box-shadow: none;
							}

							&::placeholder {
								font-family: 'Rubik';
								font-size: 24px;
							}

							@media screen and (max-width: 1024px) {
								width: 50%;
								align-self: flex-end;
							}

							@media screen and (max-width: 521px) {
								width: 100%;
								align-self: flex-start;
								text-align: left;
								margin: 0;
							}
						}

						.ant-select {
							margin-right: -10px;
							margin-top: 15px;

							.ant-select-selector {
								border: none;
								display: flex;
								align-items: center;

								.ant-select-selection-placeholder {
									color: @white;
									font-size: 14px;
									font-weight: bold;
								}

								.ant-select-item-option {
									display: flex;
									align-items: center;
								}
							}
						}

						.klever-convert-select-modal-btn {
							background: transparent;
							border-radius: 8px;
							width: 100%;
							height: 42px;
							padding: 0;

							p {
								text-align: left;
								color: @white-contrast;
								margin-bottom: 0;
							}

							svg {
								fill: @white-contrast;
							}

							@media screen and (max-width: 520px) {
								margin: 8px 0;
							}
						}

						.klever-coin-option {
							display: flex;
							row-gap: 0px;
							flex-direction: row;
							width: 100%;
							align-items: center;
							margin: 10px 0;
							transition: all 0.3s ease-in;

							b {
								padding: 2px 5px 0 5px;
							}

							div {
								&:last-child {
									display: flex;
									flex-direction: column;
									margin-left: 5px;
								}
							}

							&:hover {
								opacity: 0.8;
								transition: all 0.3s ease-in;
							}
						}

						@media screen and (max-width: 620px) {
							align-items: flex-start;
						}

						@media screen and (max-width: 521px) {
							flex-direction: column-reverse;
						}
					}

					.klever-convert-name-and-currency {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						small {
							font-size: 12px;
							font-weight: bold;
							color: @white-contrast;

							&:last-child {
								@media screen and (max-width: 521px) {
									display: none;
								}
							}
						}
					}

					.klever-convert-card-available-footer {
						display: flex;
						align-items: flex-end;
						margin: 3px 0;

						span:first-child {
							color: @white-contrast;
							font-weight: 700;
							margin-right: 5px;
						}

						span:last-child {
							font-size: 12px;
							color: @white;
						}

						@media screen and (max-width: 521px) {
							align-items: baseline;
						}
					}

					.klever-convert-select-coins-wrapper {
						display: flex;
						align-items: center;

						span {
							&:last-child {
								margin-left: 5px;
								margin-top: 1px;
								font-weight: bold;
							}
						}
					}

					.klever-convert-warning-text {
						margin: 2px 0;
						color: @red;
						font-size: 12px;
					}
				}

				.klever-convert-card-second {
					background: #2c2c2c99;

					@media screen and (max-width: 522px) {
						height: 100%;
					}

					.klever-convert-card-input-footer {
						.ant-input-number-input {
							background: #2c2c2c10;
						}
					}
				}

				.klever-convert-card-middle {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 15px 0;
					position: relative;

					.ant-divider-horizontal {
						position: absolute;
						left: 0;
						border: 1px solid @white-contrast;
					}

					> span {
						cursor: pointer;
						background: @secondary-color;
						border-radius: 40px;
						border: 2px solid @white-contrast;
						width: 36px;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
						z-index: 1;

						span {
							svg {
								width: 20px;
								height: 20px;
							}
						}
					}

					.klever-convert-card-middle-btns {
						display: flex;
						align-items: center;
						justify-content: center;

						.klever-convert-card-middle-btn {
							color: @white;
							background: @secondary-color;
							border-radius: 4px;
							padding: 10px 0;
							width: 60px;
							font-size: 0.7rem;
							font-weight: bold;
							border: 2px solid @white-contrast;
							margin-right: 10px;
							display: flex;
							align-items: center;
							justify-content: center;

							&:last-child {
								margin-right: 0;
							}

							&:hover {
								border: 2px solid @primary-color;
							}

							@media screen and (max-width: 520px) {
								margin: 8px 0;
							}
						}

						.klever-convert-card-middle-btn-active {
							border: 2px solid @primary-color;
							background-color: @body-background;
						}

						@media screen and (max-width: 520px) {
							gap: 5px;
						}
					}

					@media screen and (max-width: 520px) {
						flex-direction: column;
					}
				}

				.klever-convert-btn {
					width: 100%;
					background-color: @primary-color;
					border: none;
					padding: 24px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;

					@media screen and (max-width: 762px) {
						margin-top: 20px;
					}
				}
			}

			.klever-convert-content-box {
				display: flex;
				flex-direction: column;
				font-size: 0.9rem;
				text-align: center;

				span {
					gap: 1rem;
					display: flex;
					justify-content: space-between;

					h4 {
						color: @white-contrast;
						font-size: 0.85rem;
					}

					p {
						color: white;
						margin: 0;
						font-size: 0.85rem;
					}
				}

				.klever-convert-warning-text {
					color: @red;
					text-align: initial;
				}
			}

			.klever-convert-slippage {
				display: flex;
				align-items: center;
				justify-content: center;

				> span {
					margin-left: 5px;
					margin-top: -7px;
					cursor: pointer;
					width: 10px;
					height: 10px;
				}
			}

			.klever-convert-content-btn {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
		}

		@media screen and (max-width: 1024px) {
			margin: 20px auto 40px;
			padding: 0;
		}

		@media screen and (max-width: 610px) {
			padding: 0 20px;
		}
	}
}

.klever-mobile-pair-tab-my-orders {
	display: grid;
	// color: @white;
	gap: 24px;
}

.klever-mobile-pair-tab-title-my-orders {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	// color: @white;
	flex: none;
	// order: 0;
}
